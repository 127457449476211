import React from 'react';
import ActivityPane from './ActivityPane';

const ActivityList = ({
    activities,
    activityKeys,
    activityTypeList,
    onDelete,
    deleteNewPromoActivity,
    deletePromoActivity,
    saveNewPromoActivity,
    savingActivity,
    generatePreview,
    setPromoActivityAttr,
    setPromoActivityType,
    updatePromoActivity,
    promotion,
}) => (
    Object.entries(activities).map(([activityId, activity], index) => (
    <div key={activityId}>
        <ActivityPane
            activityId={parseInt(activityId, 10)}
            activityParams={activity.activityParams}
            activitySetterMethod={setPromoActivityAttr}
            activityTypeId={activity.activityTypeId}
            activityTypeList={activityTypeList}
            completeBackgroundImage={activity.completeBackgroundImage}
            completeCss={activity.completeCss}
            completeHtml={activity.completeHtml}
            enabled={Boolean(activity.enabled)}
            frequency={Number.parseFloat(activity.activityFreq) === Number.isNaN() ? 0 : activity.activityFreq * 100}
            globalBackgroundImage={promotion.globalBackgroundImage}
            name={activity.name}
            onDelete={onDelete}
            onSave={() => { if(activityId === 'new') { saveNewPromoActivity(activity); } else { updatePromoActivity(activity); } } }
            promoId={activity.promoId}
            generatePreview={generatePreview}
            savingActivity={savingActivity}
            setPromoActivityType={setPromoActivityType}
            termsBackgroundImage={activity.termsBackgroundImage}
            termsCss={activity.termsCss}
            termsHtml={activity.termsHtml}
            termsRequired={activity.termsRequired === 1}
            webshareText={promotion.webshareText}
            webshareUrl={promotion.webshareUrl}
        />
        {activityKeys.length > 1 && index !== activityKeys.length - 1 ?
        <hr style={{ borderColor: '#dcdcdc' }} />
        : null}
    </div>))
);

export default ActivityList;