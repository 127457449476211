import { getBlockchainIdentifiers } from 'lib/blockchain';

// TODO The reducer schema could be a lot more generic...
export const initialState = {
    autxToUsdRateError: '',
    autxToUsdRate: 0.0,
    autxToUsdTimestamp: null,
    fetchingAutxToUsdRate: false,
    fetchingUsdToAutxRate: false,
    usdToAutxRateError: '',
    usdToAutxRate: 0.0,
    usdToAutxRateTimestamp: null,

    usdcToUsdRateError: '',
    usdcToUsdRate: 0.0,
    usdcToUsdTimestamp: null,
    fetchingUsdcToUsdRate: false,
    fetchingUsdToUsdcRate: false,
    usdToUsdcRateError: '',
    usdToUsdcRate: 0.0,
    usdToUsdcRateTimestamp: null,
};

export default (state=initialState, action) => {
    const {
        coinType
    } = action;

    const {
        cryptoToUsdRateError,
        cryptoToUsdRate,
        cryptoToUsdTimestamp,
        fetchingCryptoToUsdRate,
        fetchingUsdToCryptoRate,
        usdToCryptoRateError,
        usdToCryptoRate,
        usdToCryptoRateTimestamp,
    } = getBlockchainIdentifiers(coinType);

    switch(action.type) {
        case 'FETCH_CRYPTO_TO_USD_RATE':
            return {
                ...state,
                [fetchingCryptoToUsdRate]: true,
                [cryptoToUsdRateError]: '',
            };
        case 'FETCH_CRYPTO_TO_USD_RATE_ERROR':
            return {
                ...state,
                [fetchingCryptoToUsdRate]: false,
                [cryptoToUsdRateError]: action.error,
            };
        case 'FETCH_CRYPTO_TO_USD_RATE_SUCCESS':
            return {
                ...state,
                [fetchingCryptoToUsdRate]: false,
                [cryptoToUsdRate]: action.usdAmount,
                [cryptoToUsdTimestamp]: action.timestamp,
            };
        case 'FETCH_USD_TO_CRYPTO_RATE':
            return {
                ...state,
                [fetchingUsdToCryptoRate]: true,
                [usdToCryptoRateError]: '',
            };
        case 'FETCH_USD_TO_CRYPTO_RATE_ERROR':
            return {
                ...state,
                [fetchingUsdToCryptoRate]: false,
                [usdToCryptoRateError]: action.error,
            };
        case 'FETCH_USD_TO_CRYPTO_RATE_SUCCESS':
            return {
                ...state,
                [fetchingUsdToCryptoRate]: false,
                [usdToCryptoRate]: action.cryptoAmount,
                [usdToCryptoRateTimestamp]: action.timestamp,
            };
        default:
            return state;
    }
};