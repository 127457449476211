import {
    CLEAR_PREVIEW,
    REQUEST_PREVIEW
} from 'constants/actions/preview';

const clearPreview = () => ({
    type: CLEAR_PREVIEW,
});

const previewPromo = ({
    activityId,
    page,
    promo,
    state,
}) => ({
    activityId,
    page,
    promo,
    state,
    type: REQUEST_PREVIEW,
});

export {
    clearPreview,
    previewPromo,
};