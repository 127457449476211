import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CrudListPage from 'components/Crud/CrudListPage';
import { initCompensationListPage } from 'actions/pages/init';
import { requeueTicket } from 'actions/compensation';
import { RefreshCw } from 'react-feather';
import { GtButton } from 'components/Form';

const CompensationListPage = ({
    fetchingList,
    list,
    page,
    perPage,
    initCompensationListPage,
    requeueTicket
}) => {
    useEffect(() => {
        initCompensationListPage();
    }, []);

    return (
        <CrudListPage
            title="Compensation Status"
            fields={{
                ticketId: 'ticket',
                brand: 'brand',
                coinType: 'coin type',
                redemptionStatus: 'redemption status',
                redemptionTries: 'redemption tries',
                requeue: 're-queue',
            }}
            formatter={(field, row) => {
                switch(field) {
                case 'ticketId':
                case 'redemptionTries':
                case 'errorNotes':
                case 'coinType':
                    return row[field];
                case 'redemptionStatus':
                    return row[field].trim() === 'error'
                        ? <span title={row.redemptionErrorNotes} style={{cursor: 'default'}}>{row[field]}</span>
                        : row[field];
                case 'brand':
                    return <Link to={`/brand/${row.brandId}`}>{row.brandName}</Link>;
                case 'requeue':
                    return <GtButton
                            color="success"
                            icon={RefreshCw}
                            label="requeue"
                            onClick={() => {
                                requeueTicket(row.ticketId, row.brandId);
                            }}
                        />;
                default:
                    return false;
                }
            }}
            rows={list}
        />
    );
};

const mapStateToProps = ({ compensation }) => ({
    fetchingList: compensation.fetchingList,
    list: compensation.list,
    page: compensation.page,
    perPage: compensation.perPage,
});

const mapDispatchToProps = dispatch => ({
    initCompensationListPage: () => dispatch(initCompensationListPage()),
    requeueTicket: (ticketId, brandId) => dispatch(requeueTicket(ticketId, brandId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompensationListPage);