/* eslint-disable no-mixed-operators */
import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import fetch, { isStatusCodeAnError } from 'lib/fetch';
import { API_SERVER_URL } from 'config/config';

const modelTableMap = {
    ACTIVITY_TYPE: 'promotion_activity_types',
    BRAND: 'brands',
    LOCATION: 'locations',
    PARTNER: 'partners',
    PROMO: 'promotions',
    PROMO_ACTIVITY: 'promotion_activities',
    REFERRER: 'referrers',
    REFERRER_PROMO: 'referrer_qr_codes',
    WALLET: 'wallets',
};

/**
 * Fetch an individual CRUD record,
 * assumed to be joined to relevant relations.
 */
export function *fetchDetail({
    errorAction,
    id,
    successAction,
    model,
}) {
    try {
        const res = yield fetch(`${API_SERVER_URL}/admin/crud/detail/${modelTableMap[model]}/${id}`);
        if(isStatusCodeAnError(res.status)) {
            yield put({
                type: errorAction,
                error: res.data.message,
            });
        } else {
           yield put({
               type: successAction,
               item: res.data,
           });
        }
    } catch(e) {
        yield put({
            type: errorAction,
            error: e.message || 'Unknown failure fetching model',
        });
    }
}

/**
 * Generic mechanism to fetch paginated result set from
 * the database for a given model.
 */
export function *fetchList({
    errorAction,
    page,
    perPage,
    successAction,
    model,
}) {
    try {
        let url = `${API_SERVER_URL}/admin/crud/list/${modelTableMap[model]}/${page}`;
        if(typeof perPage == 'string' && perPage.length > 0 || typeof perPage === 'number') {
            url += `/${perPage}`;
        }
        const res = yield fetch(url);
        if(isStatusCodeAnError(res.status)) {
            yield put({
                type: errorAction,
                error: res.data.message,
            });
        } else {
           yield put({
               type: successAction,
               list: res.data,
           });
        }
    } catch(e) {
        yield put({
            type: errorAction,
            error: e.message || 'Unknown failure fetching CRUD list',
        });
    }
}

function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

/**
 * Insert or update a model on the server.
 * If the id param is null, invoke an insert,
 * otherwise invoke and update.
 */
export function *syncModelToServer({
    errorAction,
    id=null,
    isNew,
    model,
    successAction,
    newModel=null,
    updateModel=null,
    redirect=true,
    sync=true,
}) {
    // ignoreSync allows the update in redux on the client to occur,
    // but tells the effect not to bother hitting the server.
    // this allows us to be judicious in the amount of update requests
    // sent to the server for text inputs.
    if(sync !== true) {
        return;
    }

    try {
        const actionKey = isNew ? 'create' : 'update';
        const deltaModel = isNew ? newModel : updateModel;
        let url = `${API_SERVER_URL}/admin/crud/${actionKey}/${modelTableMap[model]}`;
        if(!isNew) {
            url += `/${id}`;
        }
        const res = yield fetch(
            url,
            {
                method: isNew ? 'PUT' : 'POST',
                body: JSON.stringify({
                    [actionKey]: modelTableMap[model],
                    delta: deltaModel,
                }),
            },
        );
        if(isStatusCodeAnError(res.status)) {
            // TODO Genericize this,
            //      better yet, figure out how to get the server to always send back JSON
            //      https://github.com/goldenticket-webapp/server/issues/22
            let errorMsg = res.data.message;
            if(errorMsg.indexOf('<html') > -1) {
                const htmlError = errorMsg.substring(
                    errorMsg.indexOf('<body>') + 5,
                    errorMsg.indexOf('</body>')
                )
               .replace(/<h1>/g, "\n")
               .replace(/<\/h1>/g, "\n")
               .replace(/<h2>/g, "\n")
               .replace(/<\/h2>/g, "\n")
               .replace(/<pre>/g, "\n")
               .replace(/<\/pre>/g, "\n")
               .replace(/<br>/g, "\n");
                errorMsg  = decodeHtml(htmlError);
            }
            yield put({
                type: errorAction,
                errors: [errorMsg],
            });
        } else {
            const successKey = isNew ? 'newModel' : 'updateModel';
            const successActionPayload = {
                type: successAction,
                [successKey]: res.data,
            };
            if(isNew) {
                successActionPayload.tempId = id;
            }
            yield put(successActionPayload);
            const uri = window.location.pathname;
            if(redirect && isNew) {
                yield put(push(uri.replace('new', res.data.id)));
            }
        }
    } catch(e) {
        const actionType = isNew ? 'inserting' : 'updating';
        yield put({
            type: errorAction,
            errors: [e.message || `Unknown failure ${actionType} remote attr`],
        });
    }
}

export function *deleteRemoteModel({
    errorAction,
    id,
    model,
    successAction,
}) {
    try {
        const res = yield fetch(
            `${API_SERVER_URL}/admin/crud/${modelTableMap[model]}/${id}`,
            {
                method: 'DELETE',
            },
        );
        if(isStatusCodeAnError(res.status)) {
            yield put({
                type: errorAction,
                errors: [res.data.message],
            });
        } else {
           yield put({
               type: successAction,
               id,
           });
        }
    } catch(e) {
        yield put({
            type: errorAction,
            errors: [e.message || 'Unknown failure deleting remote attr'],
        });
    }
}