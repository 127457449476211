import React, {Component} from 'react';
import {func, number, object, oneOfType, string} from 'prop-types';

class InputMode extends Component {
    static propTypes = {
        className: string,
        value: oneOfType([
            string, number,
        ]),
        onChange: func.isRequired,
        style: object,
    }

    static defaultProps = {
        className: '',
        style: null,
    }

    constructor(props) {
        super(props);

        this.inputRef = null;
        this.state = {
            value: props.value,
        };
    }

    /**
     * Focus once mounted, so our keyboard handlers work as expected.
     */
    componentDidMount = () => {
        this.inputRef.focus();
    }
    
    handleBlur = (e) => {
        e.preventDefault();
        this.props.onChange('cancel');
    }

    handleKeyUp = (e) => {
        switch(e.keyCode) {
            // Esc key reverts to display mode
            case 27:
                this.handleBlur(e);
                break;
            // Enter key invokes save callback
            // XXX Assume success
            case 13:
                e.preventDefault();
                this.props.onChange('save', this.state.value);
                break;
            default:
                break;
        }
    }

    handleInput = (e) => {
        this.setState({
            value: e.target.value,
        });
    }

    render = () => (
        <span>
            <input
                onBlur={this.handleBlur}
                onChange={this.handleInput}
                onKeyUp={this.handleKeyUp}
                ref={(input) => this.inputRef = input}
                style={typeof this.props['style'] === 'object' ? this.props.style : null}
                type="text"
                value={this.state.value === null ? '' : this.state.value}
                className={this.props.className}
            />
        </span>
    )
}

export default InputMode;