import React, { useState } from 'react';
import { func, number, object, string } from 'prop-types';
import {
    Card,
    CardBody,
    CardHeader,
    FormGroup,
    Label,
} from 'reactstrap';
import { HelpCircle } from 'react-feather';
import { Loader3 } from 'components/Loader';
import ApproveAllowanceAutx from './ApproveAllowanceAutx';
import ApproveAllowanceUsdc from './ApproveAllowanceUsdc';
import ConvertedPrice from 'components/ConvertedPrice';
import { getBlockchainIdentifiers } from 'lib/blockchain';

const ApproveAllowanceCard = ({
    brand,
    brandId,
    coinType,
    fetchBrandBalanceAndAllowance,
    usdToCryptoRate,
}) => {
    const [approvingProxy, setApprovingProxy] = useState(false);

    const {
        allowanceCrypto,
        allowanceUsd,
        balanceCrypto,
        balanceUsd,
    } = getBlockchainIdentifiers(coinType);

    const ApproveAllowanceComponent =
        coinType.toLowerCase() === 'autx'
        ? ApproveAllowanceAutx
        : ApproveAllowanceUsdc;

    return (
    <Card className="shadow" style={{position: 'relative'}}>
        <CardHeader>Promotion Funding - <span className="text-uppercase">{coinType}</span></CardHeader>
        {approvingProxy === true ?
        <div className="proxy-approval-spinner">
            <Loader3
                x={20}
                y={20}
                height={100}
                width={100}
            />
        </div> : null}
        <CardBody>
            <FormGroup>
                <Label for="balance">Balance</Label>
                <span
                    className="ml-2 pointer"
                    title={`The current balance in ${coinType} in the provided wallet address`}
                >
                    <HelpCircle size={12}/>
                    <ConvertedPrice
                        coinType={coinType}
                        cryptoValue={brand[balanceCrypto]}
                        id="balance"
                        justifyContent="justify-content-start"
                        usdValue={brand[balanceUsd]}
                    />
                </span>
                <br />
                <Label for="allowance">Allowance</Label>
                <span
                    className="ml-2 pointer"
                    title={`The maximum amount in ${coinType} goldenticket may dispense on your behalf as promotion activities are completed`}
                >
                    <HelpCircle size={12}/>
                    <ConvertedPrice
                        coinType={coinType}
                        cryptoValue={brand[allowanceCrypto]}
                        id="allowance"
                        justifyContent="justify-content-start"
                        usdValue={brand[allowanceUsd]}
                    />
                </span>
                <br />
                {typeof brand['walletAddress'] === 'undefined' || brand.walletAddress === '' || brand.walletAddress === null  ?
                <p>Please enter a wallet address to fund your campaigns</p> : null}
                {typeof brand[allowanceCrypto] !== 'undefined' &&
                 typeof brand[allowanceUsd] !== 'undefined' &&
                 typeof brand[allowanceCrypto] !== 'undefined' &&
                 typeof brand[balanceUsd] !== 'undefined' &&
                 typeof brand['walletAddress'] === 'string' &&
                 brand.walletAddress.length > 0 ?
                <ApproveAllowanceComponent
                    approvingProxy={approvingProxy}
                    brandAddr={brand.walletAddress}
                    coinType={coinType}
                    existingAllowance={brand[allowanceCrypto]}
                    existingBalance={brand[balanceCrypto]}
                    getUsdToCryptoRate={(cb) => { cb(usdToCryptoRate); }}
                    onSubmit={() => {
                        setApprovingProxy(true);
                    }}
                    onApprove={(success) => {
                        if(success === true) {
                            console.log('Fetching updated balance and allowance for brandId: ', brandId);
                            fetchBrandBalanceAndAllowance(brandId, coinType);
                        }
                        setApprovingProxy(false);
                    }}
                /> : null}
            </FormGroup>
        </CardBody>
    </Card>);
};

ApproveAllowanceCard.propTypes = {
    brand: object.isRequired,
    brandId: number,
    coinType: string.isRequired,
    fetchBrandBalanceAndAllowance: func.isRequired,
    usdToCryptoRate: number.isRequired,
};

ApproveAllowanceCard.defaultProps = {
    brandId: 0,
};

export default ApproveAllowanceCard;