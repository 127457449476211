import React from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    FormGroup,
    Label,
} from 'reactstrap';
import { HelpCircle } from 'react-feather';
import { number } from 'prop-types';
import ConvertedPrice from 'components/ConvertedPrice';

/**
 * A simple read-only component to show us what the ETH balance of the brand is.
 * Decent amount of duplication with ApproveAllowanceCard.
 */
const GasFundingCard = ({
    ethBalance,
    ethBalanceUsd,
}) => {
    return (
    <Card className="shadow" style={{position: 'relative'}}>
        <CardHeader>Gas Funding - <span className="text-uppercase">ETH</span></CardHeader>
        <CardBody>
            <FormGroup>
                <Label for="balance">Balance</Label>
                <span
                    className="ml-2 pointer"
                    title={"The current balance in ETH in the provided wallet address"}
                >
                    <HelpCircle size={12}/>
                    <ConvertedPrice
                        coinType="ETH"
                        cryptoValue={ethBalance}
                        id="balance"
                        justifyContent="justify-content-start"
                        usdValue={ethBalanceUsd}
                    />
                </span>
            </FormGroup>
        </CardBody>
    </Card>);
};

GasFundingCard.propTypes = {
    ethBalance: number,
    ethBalanceUsd: number,
};

GasFundingCard.defaultProps = {
    ethBalance: 0.0,
    ethBalanceUsd: 0.0,
};

export default GasFundingCard;