export const fetchBrandBalanceAndAllowance = ({brandId, coinType}) => ({
    type: 'FETCH_BRAND_BALANCE_AND_ALLOWANCE',
    id: brandId,
    coinType,
});

export const fetchLocationBalance = ({coinType, locationId}) => ({
    coinType,
    id: locationId,
    type: 'FETCH_LOCATION_BALANCE',
});

export const fetchCryptoToUsdRate = ({coinType, cryptoAmount}) => ({
    coinType,
    cryptoAmount,
    type: 'FETCH_CRYPTO_TO_USD_RATE',
});

export const fetchUsdToCryptoRate = ({coinType, usdAmount}) => ({
    coinType,
    type: 'FETCH_USD_TO_CRYPTO_RATE',
    usdAmount,
});