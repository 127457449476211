import React, { memo, useState } from 'react';
import {
    Col,
    Collapse,
    Row,
} from 'reactstrap';
import {
    ChevronDown, ChevronUp,
} from 'react-feather';

const getHeaderClass = expanded =>
    expanded
    ? 'flex-row justify-content-between header expanded'
    : 'flex-row justify-content-between header';

const AccordionRow = ({
    title,
    children
}) => {
    const [expanded, setExpanded] = useState(false);
//console.log('rendering AccordionRow')
    return (
    <Row className="gt-row">
        <Row className={getHeaderClass(expanded)} onClick={() => setExpanded(!expanded)}>
            <Col>{title}</Col>
            <Col className="chevron">{expanded ? <ChevronUp/> : <ChevronDown/>}</Col>
        </Row>
        <Row>
            <Col>
                <Collapse isOpen={expanded}>
                    {children}
                </Collapse>
            </Col>
        </Row>
    </Row>
    );
};

AccordionRow.propTypes = {
    
};

AccordionRow.defaultProps = {
    
};

export default AccordionRow;
/* TODO Think harder about how we could memoize this, if at all..
export default memo(AccordionRow, (prevProps, nextProps) => {
    const r = prevProps.title === nextProps.title;

    return r;
});
*/