import React from 'react';
import { bool, func, number, object, string } from 'prop-types';
import { Button } from 'reactstrap';

const GtButton = ({
    className,
    color,
    disabled,
    icon,
    iconSize,
    label,
    onClick,
    small,
}) => {
    const Icon = icon;
    const textSize = small ? 'h6' : 'h4';
    return (
    <Button
        className={`${textSize} ${className}`}
        color={color}
        disabled={disabled}
        onClick={onClick}
        style={!small ? {width: '100%'} : null}
    >
            <span className="d-flex justify-content-center">
                <span className="align-self-center pr-1"><Icon size={iconSize} /></span>
                <span className="pt-1">{label}</span>
            </span>
    </Button>);
};

const gtButtonPropTypes = {
    className: string,
    color: string,
    disabled: bool,
    icon: object.isRequired,
    iconSize: number,
    label: string.isRequired,
    onClick: func.isRequired,
    small: bool,
};

const gtButtonDefaultProps = {
    className: '',
    color: 'primary',
    disabled: false,
    iconSize: 16,
    small: false,
};

GtButton.propTypes = {
    ...gtButtonPropTypes
};

GtButton.defaultProps = {
    ...gtButtonDefaultProps
};

export {
    gtButtonPropTypes,
    gtButtonDefaultProps,
};

export default GtButton;
