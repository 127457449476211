/**
 * Factory methods to generate generic CRUD actions
 * for a given model.
 */
import { PAGINATION_PER_PAGE } from 'config/config';

export const clearErrorsAction = () => ({
    type: 'CLEAR_ERRORS',
});

export const createFetchModelAction = (model) => {
    return ({id}) => ({
        errorAction: `FETCH_${model}_ERROR`,
        id,
        successAction: `FETCH_${model}_SUCCESS`,
        model,
        type: `FETCH_${model}`,
    });
};

export const createFetchModelListAction = (
    model,
) => {
    return ({
        page=1,
        perPage=PAGINATION_PER_PAGE,
    }) => ({
        errorAction: `FETCH_${model}_LIST_ERROR`,
        page,
        perPage,
        successAction: `FETCH_${model}_LIST_SUCCESS`,
        model,
        type: `FETCH_${model}_LIST`,
    });
};

export const createInsertModelAction = (
    model,
) => {
    return ({
        id,
        newModel,
        sync=true,
    }) => ({
        errorAction: `CREATE_${model}_ERROR`,
        id,
        isNew: true,
        newModel,
        model,
        successAction: `CREATE_${model}_SUCCESS`,
        sync,
        type: `CREATE_${model}`,
    });
};

export const createUpdateModelAction = (
    model,
) => {
    return ({
        id,
        updateModel,
        sync=true,
    }) => ({
        errorAction: `UPDATE_${model}_ERROR`,
        id,
        isNew: false,
        updateModel,
        model,
        successAction: `UPDATE_${model}_SUCCESS`,
        sync,
        type: `UPDATE_${model}`,
    });
};

export const createDeleteModelAction = (
    model,
) => {
    return ({
        id,
    }) => ({
        errorAction: `DELETE_${model}_ERROR`,
        id,
        model,
        successAction: `DELETE_${model}_SUCCESS`,
        type: `DELETE_${model}`,
    });
};

/**
 * When creating a new model, we need to be able to
 * update values in redux, but we don't want to trigger
 * an insert call on the server until we have the bare minimum
 * in the model proper.
 * This action will be used to trigger an update in the "newModel" field,
 * which will not invoke a "create" method on the server.
 */
export const createSetNewModelAttrAction = (
    model,
) => {
    return ({
        field,
        value,
    }) => ({
        errorAction: `SET_NEW_${model}_ATTR_ERROR`,
        field,
        successAction: `SET_NEW_${model}_ATTR_SUCCESS`,
        type: `SET_NEW_${model}_ATTR`,
        value,
    });
};