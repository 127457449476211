import { put } from 'redux-saga/effects';
import { PAGINATION_PER_PAGE } from 'config/config';

export function *initBrandListPage() {
    yield put({
        type: 'FETCH_BRAND_LIST',
        successAction: 'FETCH_BRAND_LIST_SUCCESS',
        errorAction: 'FETCH_BRAND_LIST_ERROR',
        model: 'BRAND',
        page: 1,
        perPage: PAGINATION_PER_PAGE,
    });
}