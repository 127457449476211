import {
    FETCH_PROMO_STATS_BY_PROMO_ID,
} from 'constants/actions/promo-stats';

const fetchPromoStatsByPromoId = ({promoId}) => ({
    type: FETCH_PROMO_STATS_BY_PROMO_ID,
    promoId,
});

export {
    fetchPromoStatsByPromoId,
};
