import {
    CLEAR_PREVIEW,
    REQUEST_PREVIEW,
    PREVIEW_ERROR,
    PREVIEW_SUCCESS,
} from 'constants/actions/preview';

// XXX Change html to previewData or something more apt
const initialState = {
    html: '',
    previewing: false,
};

export default (state=initialState, action) => {
    switch(action.type) {
        case CLEAR_PREVIEW:
            return initialState;
        case REQUEST_PREVIEW:
            return {
                html: '',
                previewing: true,
            };
        case PREVIEW_ERROR:
            return {
                html: '',
                previewing: false,
            };
        case PREVIEW_SUCCESS:
            return {
                html: action.html,
                previewing: false,
            };
        default:
            return state;
    }
};