import React from 'react';
import { bool, func, number, object, string } from 'prop-types';
import { Link } from 'react-router-dom';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    FormGroup,
    Label,
} from 'reactstrap';
import haveValue from 'lib/have-value';
import { DollarValue, LocaleValue } from 'components/Formatter';
import { formatLocaleValue } from 'lib/formatters';
import { ApproveAllowanceCard, GasFundingCard } from 'components/Blockchain';
import {
    CloneButton,
    DeleteButton,
    GtDatePicker,
    SwitchInput,
    SaveButton,
    TextInput,
} from 'components/Form';
import { FrontendPreview } from 'components/Promotion';
import { GtExternalLink } from 'components/Link';
import QrImg from 'components/QrImg';
import { APP_BASE_URL } from 'config/config';

const PromoSidebar = ({
    activities,
    blockchain,
    blockchainIds,
    brandList,
    isClone,
    isNew,
    clonePromo,
    coinType,
    deletePromo,
    effectivePromo,
    extTicketUrl,
    fetchBrandBalanceAndAllowance,
    partnerList,
    previewConfig,
    promoId,
    promoSetterMethod,
    redirect,
    saveClonedPromo,
    saveNewPromo,
    shortUrls,
    showPreview,
    theBrand,
    updatePromo,
    updatePromoActivity,
}) => (
    <Col md={4}>
        {!isNew ?
        <DeleteButton
            className="mb-2"
            onClick={() => {deletePromo(promoId); redirect('/promotions');}}
        />
        : null}
        {isNew === true ?
        <SaveButton
            disabled={
                isNew && effectivePromo !== null && (
                !haveValue(effectivePromo.name)                       ||
                !haveValue(effectivePromo.brandId)                    ||
                !Array.isArray(effectivePromo.brandPartnerIds)        ||
                effectivePromo.brandPartnerIds.length === 0           ||
                !haveValue(effectivePromo.startDate)                  ||
                !haveValue(effectivePromo.maxUses, false)             ||
                !haveValue(effectivePromo.partnerReward, false)       ||
                !haveValue(effectivePromo.centerpointLat, true, true) ||
                !haveValue(effectivePromo.centerpointLong, true, true)
            )}
            onClick={() => {
                if(isClone) {
                    saveClonedPromo();
                } else {
                    saveNewPromo(effectivePromo);
                }
            }}
        />
        : null}
        {!isNew === true ?
        <SaveButton
            onClick={() => {
                updatePromo(effectivePromo);
                /* Update all promo activities -
                   this feels gratuitous...
                Object.values(activities).forEach(activity => {
                    updatePromoActivity(activity);
                });
                */
        }}
        />
        : null}
        {!isNew ? // TODO This should be disabled until all the relevant data needed for cloning has been loaded
        <CloneButton
            className="mb-3"
            onClick={() => {clonePromo(promoId); redirect('/promotion/clone');}}
        />
        : null}
        {!isNew ?
        <Card className="shadow">
            <CardHeader>Promotion Code</CardHeader>
            <CardBody>
                <QrImg qrData={`https://${APP_BASE_URL}/ticket/${effectivePromo.cryptCode}`} />
            </CardBody>
        </Card> : null}
        {!isNew &&
         typeof theBrand !== 'undefined' &&
         theBrand !== null &&
         typeof effectivePromo !== 'undefined' &&
         typeof effectivePromo.coinType === 'string'
         ? <ApproveAllowanceCard
            brand={theBrand}
            brandId={effectivePromo.brandId}
            coinType={effectivePromo.coinType}
            fetchBrandBalanceAndAllowance={fetchBrandBalanceAndAllowance}
            usdToCryptoRate={typeof blockchain === 'object' && blockchain !== null && blockchainIds === null ? 0.0 : blockchain[blockchainIds.usdToCryptoRate]}
         /> : null}
        {!isNew &&
         typeof theBrand !== 'undefined' &&
         theBrand !== null &&
         typeof effectivePromo !== 'undefined' &&
         typeof effectivePromo.coinType === 'string'
         ? <GasFundingCard
            brand={theBrand}
            ethBalance={theBrand.ethBalance}
            ethBalanceUsd={theBrand.ethBalanceUsd}
         /> : null}
        {!isNew &&
         typeof brandList !== 'undefined' &&
         brandList !== null &&
         typeof partnerList !== 'undefined' &&
         partnerList !== null &&
         typeof brandList[effectivePromo.brandId] === 'object' &&
         typeof brandList[effectivePromo.brandId].name === 'string' &&
         typeof effectivePromo === 'object' &&
         effectivePromo !== null &&
         Array.isArray(effectivePromo.brandPartnerIds)
         ?
        <Card className="shadow">
            <CardHeader>Participants</CardHeader>
            <CardBody>
                <FormGroup>
                {/* XXX Let's use ExternalLink here ... */}
                    <Label for="brand-name">Brand</Label>
                    <div id="brand-name"><Link to={`/brand/${effectivePromo.brandId}`}>{brandList[effectivePromo.brandId].name}</Link></div>
                    <br />
                    <Label for="partner-name">Location(s)</Label>
                    {effectivePromo.brandPartnerIds.map((brandPartnerId) => (
                    typeof partnerList[brandPartnerId] === 'object' &&
                    typeof partnerList[brandPartnerId].name === 'string' ?
                    <div id="partner-name" key={brandPartnerId}><Link to={`/partner/${brandPartnerId}`}>{partnerList[brandPartnerId].name}</Link></div> :
                    null
                    ))}
                </FormGroup>
            </CardBody>
        </Card> : null}
        {!isNew ?
        <Card className="shadow">
            <CardHeader>External Links</CardHeader>
            <CardBody>
                {typeof effectivePromo['promoCode'] !== 'undefined' ?
                <GtExternalLink
                    href={extTicketUrl}
                    label="Ticket Page"
                    shortUrl={typeof shortUrls.list[extTicketUrl] !== 'undefined' && shortUrls.list[extTicketUrl] !== null ? shortUrls.list[extTicketUrl] : ''}
                    title="Direct link to the start of the promotion as if you had scanned the promo code manually"
                /> : null}
            </CardBody>
        </Card>
        : null}
        <Card className="shadow">
            <CardHeader>Promotion Settings</CardHeader>
            <CardBody>
                <FormGroup tag="fieldset">
                    {!isNew ?
                    <SwitchInput
                        className="mb-2"
                        color="dark"
                        enabled={effectivePromo.enabled == true}
                        help="Ability to completely disable the promotion in real time"
                        label="Enabled"
                        onClick={enabled => { promoSetterMethod(promoId, 'enabled', enabled); }}
                    />
                    : null}
                    <SwitchInput
                        className="mb-2"
                        color="dark"
                        enabled={effectivePromo.expires == true}
                        help="Does this promotion have a hard expiration date"
                        label="Expires"
                        onClick={enabled => { promoSetterMethod(promoId, 'expires', enabled); }}
                    />
                    <SwitchInput
                        color="dark"
                        enabled={effectivePromo.useOttScanner}
                        help="Show scan button instead of QR code on ticket page"
                        label="OTT Scanner"
                        onClick={(enabled) => { promoSetterMethod(promoId, 'useOttScanner', enabled); }}
                    />
                    <SwitchInput
                        color="dark"
                        enabled={effectivePromo.hideFromHomepage}
                        help="Exclude promo from homepage"
                        label="Hidden"
                        onClick={(enabled) => { promoSetterMethod(promoId, 'hideFromHomepage', enabled); }}
                    />
                    <SwitchInput
                        color="dark"
                        enabled={effectivePromo.termsRequired}
                        help="Adds a terms page that must be acknowledged before proceeding"
                        label="Terms Required"
                        onClick={(enabled) => { promoSetterMethod(promoId, 'termsRequired', enabled); }}
                    />
                    <SwitchInput
                        color="dark"
                        enabled={effectivePromo.isVendingPromotion}
                        help="Adds storage for a Vending Coupon Code"
                        label="Vending Promo"
                        onClick={(enabled) => { promoSetterMethod(promoId, 'isVendingPromotion', enabled); }}
                    />
                    <GtDatePicker
                        className="mb-2"
                        id="promo-start-date"
                        label="Start Date"
                        onChange={value => { promoSetterMethod(promoId, 'startDate', value); }}
                        required
                        value={effectivePromo.startDate}
                    />
                    {effectivePromo.expires == true ?
                    <GtDatePicker
                        className="mb-3 mt-3"
                        id="promo-end-date"
                        label="End Date"
                        onChange={value => { promoSetterMethod(promoId, 'endDate', value); }}
                        value={effectivePromo.endDate}
                    /> : null}
                   {effectivePromo.isVendingPromotion == true ?
                    <TextInput
                        id="promo-vending-coupon-code"
                        help="Coupon Code that corresponds to this promotion in the Vending system"
                        label="Vending Coupon Code"
                        onInput={value => { promoSetterMethod(promoId, 'vendingCouponCode', value); }}
                        value={effectivePromo.vendingCouponCode}
                    /> : null}
                    <TextInput
                        id="promo-max-uses"
                        help="Max number of tickets that can be redeemed"
                        label="Max Uses"
                        onInput={value => { promoSetterMethod(promoId, 'maxUses', value); }}
                        value={effectivePromo.maxUses}
                    />
                    <TextInput
                        id="promo-warning-threshold"
                        help="Number of redeemed tickets that causes warning on frontend"
                        label="Warning Threshold"
                        onInput={value => { promoSetterMethod(promoId, 'warningThreshold', value); }}
                        value={effectivePromo.warningThreshold}
                    />
                    <TextInput
                        id="promo-partner-payout"
                        help="Location reward for each redeemed ticket"
                        label={`Location Reward ${coinType.toUpperCase()}`}
                        onInput={value => { promoSetterMethod(promoId, 'partnerReward', value); }}
                        value={effectivePromo.partnerReward}
                    />
                    <TextInput
                        id="promo-gt-fee"
                        help="Golden Ticket fee for each redeemed ticket"
                        label={`Golden Ticket fee ${coinType.toUpperCase()}`}
                        onInput={value => { promoSetterMethod(promoId, 'gtFee', value); }}
                        value={effectivePromo.gtFee}
                    />
                    <TextInput
                        id="promo-referrer-fee"
                        help="Referrer reward for each redeemed ticket that was referred"
                        label={`Referrer reward ${coinType.toUpperCase()}`}
                        onInput={value => { promoSetterMethod(promoId, 'referrerFee', value); }}
                        value={effectivePromo.referrerFee}
                    />
                    {/* NOTE User Reward will always be in AUTX no matter what the crypto type is for the promo...*/}
                    <TextInput
                        id="promo-user-reward"
                        help="User reward for completing the promo (separate from the smart contract)"
                        label="User reward AUTX"
                        onInput={value => { promoSetterMethod(promoId, 'userReward', value); }}
                        value={effectivePromo.userReward}
                    />
                </FormGroup>
            </CardBody>
        </Card>
        <Card className="shadow">
            <CardHeader>Security Settings</CardHeader>
            <CardBody>
                <FormGroup tag="fieldset">
                    <SwitchInput
                        className="mb-2"
                        color="dark"
                        enabled={effectivePromo.authRequired == true}
                        help="User can't engage the promotion unless they are logged in"
                        label="Require Authenticated User"
                        onClick={authRequired => { promoSetterMethod(promoId, 'authRequired', authRequired); }}
                    />
                </FormGroup>
            </CardBody>
        </Card>
        {showPreview === true ? <FrontendPreview
            {...previewConfig}
        /> : null}
    </Col>
);

PromoSidebar.propTypes = {
    activities: object,
    blockchain: object,
    blockchainIds: object,
    brandList: object,
    isClone: bool,
    isNew: bool,
    clonePromo: func,
    coinType: string,
    deletePromo: func,
    effectivePromo: object,
    extTicketUrl: string,
    fetchBrandBalanceAndAllowance: func,
    partnerList: object,
    previewConfig: object,
    promoId: number,
    promoSetterMethod: func,
    redirect: func,
    saveNewPromo: func,
    shortUrls: object,
    showPreview: bool,
    theBrand: object,
    updatePromo: func,
    updatePromoActivity: func,
};

PromoSidebar.defaultProps = {
    showPreview: false,
};

export default PromoSidebar;