/* eslint-disable eqeqeq */
/*global ethereum*/
/*global ethers*/
import React, { useEffect, useRef, useState } from 'react';
import { array, bool, func, number, string } from 'prop-types';
import {
    Alert,
    Button,
    Label,
} from 'reactstrap';
import { ethers } from 'ethers';
import { HelpCircle, Key } from 'react-feather';
import { formatDollarValue } from 'lib/formatters';
import { GOERLI_CHAIN_ID } from 'config/config';
import { USDC_CONTRACT_ADDR } from 'config/config';

const ApproveAllowance = ({
    approvingProxy,
    approvalType,
    brandAddr,
    coinType,
    contractAddress,
    contractABI,
    existingAllowance,
    existingBalance,
    getUsdToCryptoRate,
    ignoreSameBalance,
    onApprove,
    onSubmit,
    proxyAddress,
}) => {
    const allowanceInput = useRef(null);
    const [errorText, setErrorText] = useState('');
    const [warningText, setWarningText] = useState('');
    const [metamaskMissing, setMetamaskMissing] = useState(false);
    //const [approvingProxy, setApprovingProxy] = useState(false);
    let currentChainId = null;
    let currentAccount = null;

    useEffect(() => {
        if(approvalType === 'wrapper') {
            setWarningText('You must approve the same amount again in order for the setting to take effect');
        }
    }, [approvalType]);

    const ethEnabled = async() => {
    	if (window.ethereum) {
    		await window.ethereum.request({method: 'eth_requestAccounts'});
    		return true;
    	}
    	return false;
    };

    const handleChainChanged = (chainId) => {
    	if(currentChainId != chainId) {
    		currentChainId = chainId;
    		if(chainId.result != GOERLI_CHAIN_ID) {
    		    setWarningText('Please connect to the zksync network');
    		}
    	}
    };

    const getTransaction = (txHash, seconds, callback) => {
console.log('>>> getTransaction')
    	if (seconds <= 0) {
    		console.error('getTransaction timed out..');
    		callback(-1, null);
    	}
    	ethereum
    	  .request({ method: 'eth_getTransactionReceipt', params: [ txHash ], })
    	  .then(receipt => {
                if (receipt) {
                    console.log("Transaction:", txHash, "succeeded:", receipt);
                    callback(0, receipt);
                } else {
                    console.log("Transaction:", txHash, "receipt is null");
                    setTimeout(function() {
                        getTransaction(txHash, seconds--, callback);
                    }, 1000);
                }
        	})
        	.catch(e => {
        	    console.error('Failed to get the receipt', e);
        	    setErrorText('Approval Failed');
        	});
    };

    const handleAccountsChanged = (accounts) => {
console.log('>>> handleAccountChanged', accounts)
    	if(accounts.length == 0) {
		    setWarningText('Please connect to MetaMask');
    	} else if(accounts[0] && accounts[0] != currentAccount) {
    		currentAccount = accounts[0];
    		if(currentAccount != brandAddr.toLowerCase()) {
    			setWarningText('Account not found:', brandAddr);
    		}
    	}
    };

    const callApprove = (brand, allowance, cb) => {
    	var iface = new ethers.utils.Interface(contractABI);
    	const data = iface.encodeFunctionData("approve", [ proxyAddress, allowance ]);
    
    	ethereum
    	  .request({
    		method: 'eth_sendTransaction',
    		params: [
    		  {
    			from: brand,
    			to: contractAddress,
    			data: data,
    		  },
    		],
    	  })
    	  .then(txHash => {
    		console.log("Tx hash:", txHash);
    		if (txHash) {
console.log('trying to get transaction...') 
    			getTransaction(txHash, 40, function(err, receipt) {
    				if (!err) {
    					console.log("Transaction committed:", receipt);
        		        if(allowanceInput && allowanceInput.current && allowanceInput.current.value) {
            		        allowanceInput.current.value = '';
        		        }
    					return cb(true);
    				} else {
    					console.log("Transaction reverted");
    					return cb(false);
    				}
    			});
    		}
    	  })
    	  .catch(function(error) {
            setErrorText('Approval failed: ' + error.message);
            console.error(error);
            return cb(false, error);
    	  });
    };

    const process = (addlAllowance) => {
        console.log('addlAllowance: ', addlAllowance)
        const denomination
            = contractAddress === USDC_CONTRACT_ADDR
            ? 'mwei'
            : 'ether';
    	const allowance = ethers.utils.parseUnits(addlAllowance.toString(), denomination)
 
 console.log('requesting acounts')
    	ethereum.request({method: 'eth_requestAccounts'})
    		.then(handleAccountsChanged)
    		.catch(err => {
    			if(err.code == 4001) {
    			    setErrorText('Please connect to MetaMask.');
    				return false;
    			} else {
    			    console.error('Failed to request accounts due to unknown error', err)
    			}
    		});

console.log('approving...')
    	callApprove(brandAddr, allowance, (success, e) => {
console.log('Approve callback ', success, e)
    	    onApprove(success);
    	    if(success === false) {
    	        setErrorText(e.message);
    	    }
    	});
    };

    // Initialize ethereum
    useEffect(() => {
        async function onPageLoad() {
            const _ethEnabled = await ethEnabled();
            if (!_ethEnabled || !ethereum.isMetaMask) {
            	setMetamaskMissing(true);
            }
            else {
                ethereum.send('eth_chainId')
                	.then(handleChainChanged)
                	.catch(err => {
                	    setErrorText(err.message);
                	});
    
                ethereum.on('chainChanged', handleChainChanged);
    
                ethereum.send('eth_accounts')
                	.then(handleAccountsChanged)
                	.catch(err => {
                		if(err.code == 4100) {
                			setErrorText('Please connect to MetaMask');
                		} else {
                    	    setErrorText(err.message);
                		}
                	});
    
                ethereum.on('accountsChanged', handleAccountsChanged);
            }
        }
        onPageLoad();
    }, []);

    const onApproveClick = () => {
        setWarningText('');
        setErrorText('');

        const addlAllowance = Number.parseFloat(allowanceInput.current.value);

        // Validation
        if(Number.isNaN(addlAllowance)) {
            setErrorText('Enter a valid value in USD');
            allowanceInput.current.focus();
            return;
        }

        if(!ignoreSameBalance && addlAllowance == existingAllowance) {
            setWarningText('You can only approve an amount different than the exiting allowance');
            allowanceInput.current.focus();
            return;
        }

        const maxAddlAllowance = existingBalance - existingAllowance;
        const maxBalanceS = formatDollarValue(existingBalance);
        if(maxAddlAllowance > 0 && addlAllowance > existingBalance) {
            setErrorText(`Allowance cannot be more than existing balance, ${maxBalanceS}`);
            allowanceInput.current.focus();
            return;
        }

        /* note: we now take the underlying crypto amount directly,
           but may turn back at some point pending feedback */
        process(addlAllowance);
        /* TODO Marked for removal..
        getUsdToCryptoRate((usdRate) => {
            process(addlAllowance * usdRate);
        });
        */
    };

    return (
        <div className="approve-allowance-form">
            <Label for="approve">Set allowance</Label>
            {approvalType !== null ? <small>({approvalType})</small> : null}
            <span
                className="ml-2 pointer"
                title={`The maximum amount in ${coinType.toUpperCase()} goldenticket may dispense on your behalf as promotion activities are completed (overwrites current value)`}
            >
                <HelpCircle size={12}/>
            </span>
            {errorText !== '' ?
            <Alert color="danger">{errorText}</Alert>
            : null}
            {warningText !== '' ?
            <Alert color="warning">{warningText}</Alert>
            : null}
            {metamaskMissing === true ?
            <Alert color="danger">
                <p>MetaMask browser extension must be installed or enabled before continuing. Please install <a href="https://metamask.io/">here</a>.</p>
            </Alert>
            : null}
            <div id="approve">
                <div style={{display: 'flex'}}>
                    <span className="mr-2" style={{alignSelf: 'center'}}>{coinType.toUpperCase()}</span>
                    <input disabled={approvingProxy === true} ref={allowanceInput} style={{flexGrow: 1}} type="text" />
                    {/* <span className="ml-2" style={{alignSelf: 'center'}}>AUTX</span> */}
                </div>
                <Button className="mt-2" color="success" disabled={approvingProxy === true} onClick={onApproveClick} style={{width: '100%'}}>
                    <Key size={14}/>
                    <span> Approve</span>
                </Button>
            </div>
        </div>
    );
};

const approveAllowancePropTypes = {
    approvingProxy: bool.isRequired,
    approvalType: string,
    brandAddr: string.isRequired,
    coinType: string.isRequired,
    contractAddress: string.isRequired,
    contractABI: array.isRequired,
    existingAllowance: number.isRequired,
    existingBalance: number.isRequired,
    getUsdToCryptoRate: func.isRequired,
    ignoreSameBalance: bool,
    onApprove: func.isRequired,
    onSubmit: func.isRequired,
    proxyAddress: string.isRequired,
};

const approveAllowanceDefaultProps = {
    approvalType: null,
    ignoreSameBalance: false,
};

ApproveAllowance.propTypes = {
    ...approveAllowancePropTypes
};

ApproveAllowance.defaultProps = {
    ...approveAllowanceDefaultProps
};

export {
    approveAllowancePropTypes,
    approveAllowanceDefaultProps,
};

export default ApproveAllowance;