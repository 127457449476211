import { call, put, select } from 'redux-saga/effects';
import { PAGINATION_PER_PAGE } from 'config/config';
import { fetchDetail } from '../atomic/crud';

export function *initBrandDetailPage({
    id=null,
}) {
    if(id === null) {
        return;
    }

    const walletList = yield select(state => state.wallet.list);
    if(Object.keys(walletList).length === 0) {
        yield put({
            type: 'FETCH_WALLET_LIST',
            errorAction: 'FETCH_WALLET_LIST_ERROR',
            model: 'WALLET',
            page: 1,
            perPage: PAGINATION_PER_PAGE,
            successAction: 'FETCH_WALLET_LIST_SUCCESS',
        });
    }

    // if we have an id, then load up the brand
    if(id !== 'new') {
        // bail if the brand is already loaded
        // TODO - Smarter cache busting logic here..
        /*
        const brandList = yield select(state => state.brand.list);
        if(typeof brandList[id] === 'object' && brandList[id] !== null) {
            return;
        }
        */

        // @note We use call here instead of put on purpose
        //       If we use put we see the action happen in redux,
        //       but lose the ability to serialize the fetch and select
        yield call(fetchDetail, {
            errorAction: 'FETCH_BRAND_ERROR',
            id,
            model: 'BRAND',
            successAction: 'FETCH_BRAND_SUCCESS',
        });

        // XXX Server needs to be able to actually handle bogus wallet addresses
        yield put({
            type: 'FETCH_BRAND_BALANCE_AND_ALLOWANCE',
            id,
            coinType: 'autx',
        });
        yield put({
            type: 'FETCH_BRAND_BALANCE_AND_ALLOWANCE',
            id,
            coinType: 'usdc',
        });

        yield put({
            type: 'FETCH_USD_TO_CRYPTO_RATE',
            coinType: 'autx',
            usdAmount: 1.0,
        });
        yield put({
            type: 'FETCH_USD_TO_CRYPTO_RATE',
            coinType: 'usdc',
            usdAmount: 1.0,
        });
    }
    // otherwise, if this is a new brand, stub it out in redux
    else {
        yield put({
            type: 'CREATE_NEW_BRAND',
        });
    }
}