import React, { memo, useRef } from 'react';
import { bool, func, node, string } from 'prop-types';
import {
    Input,
    Label,
} from 'reactstrap';
import haveValue from 'lib/have-value';
import Help from './Help';
import { debounce } from 'debounce';

/**
 * A dead simple (unmanaged) component to indicate required input
 * TODO: Support for a requirement method for more complex logic
 */
const TextInput = ({
    caption,
    children,
    help,
    id,
    label,
    noBr,
    onInput,
    required,
    requirement,
    className,
    value,
}) => {
    const _haveValue = requirement(value);
    const inputEl = useRef(null);
//console.log(`rendering ${id} TextInput`)
    const debouncedOnInput = debounce(e => { onInput(inputEl.current.value) }, 200);

    return (
    <div>
        <Label for={id}>{label}{children}</Label>
        <Help message={help} />
        <Input
            className={className}
            defaultValue={value}
            id={id}
            invalid={required && !_haveValue}
            onInput={debouncedOnInput}
            innerRef={inputEl}
            type="text"
        />
        {caption.length > 0 ? <p className="text-secondary pl-2 small">{caption}</p>: null}
        {noBr === true && _haveValue ? null : <br/>}
    </div>
    );
};

const textInputPropTypes = {
    caption: string,
    children: node,
    help: string,
    id: string.isRequired,
    label: string.isRequired,
    noBr: bool,
    onInput: func.isRequired,
    required: bool,
    requirement: func,
    className: string,
    value: string.isRequired,
};

const textInputDefaultProps = {
    caption: '',
    children: null,
    help: '',
    noBr: false,
    required: false,
    className: '',
    requirement: value => haveValue(value),
};

TextInput.propTypes = {
    ...textInputPropTypes
};

TextInput.defaultProps = {
    ...textInputDefaultProps
};

export {
    textInputPropTypes,
    textInputDefaultProps,
};

// Ignore changes to callback functions when considering rerender
export default memo(TextInput, (prevProps, nextProps) => {
    const r = prevProps.children === nextProps.children &&
        prevProps.help === nextProps.help &&
        prevProps.id === nextProps.id &&
        prevProps.caption === nextProps.caption &&
        prevProps.label === nextProps.label &&
        prevProps.noBr === nextProps.noBr &&
        prevProps.required === nextProps.required &&
        prevProps.className === nextProps.className &&
        prevProps.value === nextProps.value;

/*
if(prevProps.id == 'rq-input') {
    console.log(prevProps.onInput === nextProps.onInput)
console.log(`considering rerender for ${prevProps.id} TextInput`, prevProps.onInput === nextProps.onInput)
}
*/

    return r;
});

//export default TextInput;