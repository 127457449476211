import { getBlockchainIdentifiers } from 'lib/blockchain';
import createReducer from './crud';

const brandReducer = createReducer(
    'BRAND',
    (state, action) => ({
        'FETCH_BRAND_BALANCE_AND_ALLOWANCE': () => {
            const { coinType } = action;

            const {
                allowanceCrypto,
                allowanceUsd,
                balanceCrypto,
                balanceUsd,
                fetchingCryptoBalanceAndAllowance,
                fetchingCryptoBalanceAndAllowanceErrors,
            } = getBlockchainIdentifiers(coinType);

            return {
                ...state,
                list: {
                    ...state.list,
                    [action.id]: {
                        ...state.list[action.id],
                        [allowanceCrypto]: 0.0,
                        [allowanceUsd]: 0.0,
                        [balanceCrypto]: 0.0,
                        [balanceUsd]: 0.0,
                    }
                },
                [fetchingCryptoBalanceAndAllowance]: true,
                [fetchingCryptoBalanceAndAllowanceErrors]: [],
            };
        },
        'FETCH_BRAND_BALANCE_AND_ALLOWANCE_SUCCESS': () => {
            const { coinType } = action;

            const {
                allowanceCrypto,
                allowanceUsd,
                balanceCrypto,
                balanceUsd,
                fetchingCryptoBalanceAndAllowance,
            } = getBlockchainIdentifiers(coinType);

            return {
                ...state,
                list: {
                    ...state.list,
                    [action.id]: {
                        ...state.list[action.id],
                        [allowanceCrypto]: Number.parseFloat(action.allowanceCrypto),
                        [allowanceUsd]: Number.parseFloat(action.allowanceUsd),
                        [balanceCrypto]: Number.parseFloat(action.balanceCrypto),
                        [balanceUsd]: Number.parseFloat(action.balanceUsd),
                        ethBalance: action.ethBalance,
                        ethBalanceUsd: action.ethBalanceUsd,
                    }
                },
                [fetchingCryptoBalanceAndAllowance]: false,
            };
        },
        'FETCH_BRAND_BALANCE_AND_ALLOWANCE_ERROR': () => {
            const { coinType } = action;
            const {
                fetchingCryptoBalanceAndAllowance,
                fetchingCryptoBalanceAndAllowanceErrors,
            } = getBlockchainIdentifiers(coinType);

            return {
                ...state,
                [fetchingCryptoBalanceAndAllowance]: false,
                [fetchingCryptoBalanceAndAllowanceErrors]: action.errors,
            };
        },
    }),
    { // new model
        name: '',
        logo: '',
        url: '',
        contactName: '',
        contactEmail: '',
        walletId: 0,
    }
);

export default brandReducer;