import {
    FETCH_SHORT_URL,
} from 'constants/actions/short-urls';

const fetchShortUrl = ({urlToShorten}) => ({
    type: FETCH_SHORT_URL,
    urlToShorten,
});

export {
    fetchShortUrl,
};