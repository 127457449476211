const simulateSave = ({
    errorMessage='Something blew up',
    serverTimeMs=2000,
    successMessage='Saved',
    willBeSuccessful=true,
}) => ({
    id: 'fake-id',
    errorMessage,
    updateModel: {
        name: 'George', // todo accept value from text field
    },
    serverTimeMs,
    successMessage,
    sync: true,
    willBeSuccessful,
    type: 'UPDATE_UI_COMPS',
});

export {
    simulateSave,
};
