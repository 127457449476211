/* eslint-disable react/forbid-foreign-prop-types */
import React, { memo } from 'react';
import GtSelect, { gtSelectPropTypes, gtSelectDefaultProps } from '../core/GtSelect';

/**
 * Typed coin-type selection so the user can't enter arbitrary values
 *
 * XXX Hardcoded coin type list; DB does not have a coin type table
 */
const CoinTypeSelect = ({
    value,
    ...gtSelectProps
}) => {
    
    //console.log('rendering CoinTypeSelect')
    return (
    <div className="d-flex coin-type-select">
        <div>
            <GtSelect
                {...gtSelectProps}
                displayField="name"
                options={{
                    "autx": {
                        "id": 1,
                        "name": "autx"
                    },
                    "usdc": {
                        "id": 2,
                        "name": "usdc"
                    },
                }}
                value={value}
            />
        </div>
    </div>
);
};

const coinTypeSelectPropTypes = {
    ...gtSelectPropTypes
};

delete coinTypeSelectPropTypes.displayField;
delete coinTypeSelectPropTypes.displayFieldOverride;

const coinTypeSelectDefaultProps = {
    ...gtSelectDefaultProps
};

delete coinTypeSelectDefaultProps.displayField;
delete coinTypeSelectDefaultProps.displayFieldOverride;
delete coinTypeSelectDefaultProps.defaultText;
delete coinTypeSelectDefaultProps.options;

CoinTypeSelect.propTypes = {
    ...coinTypeSelectPropTypes,
};

CoinTypeSelect.defaultProps = {
    ...coinTypeSelectDefaultProps,
    defaultText: "Choose a Coin Type",
};

//export default CoinTypeSelect;
export default memo(CoinTypeSelect, (prevProps, nextProps) => {
    const r = prevProps.className === nextProps.className &&
        prevProps.defaultText === nextProps.defaultText &&
        prevProps.id === nextProps.id &&
        prevProps.label === nextProps.label &&
        prevProps.multiple === nextProps.multiple &&
        prevProps.value === nextProps.value;
    return r;
});