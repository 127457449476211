import React, { useEffect } from 'react';
import { connect }  from 'react-redux';
import { Link } from 'react-router-dom';
import CrudListPage from 'components/Crud/CrudListPage';
import { IMG_SERVER_URL } from 'config/config';
import { initPartnerListPage } from 'actions/pages/init';

const PartnerListPage = ({
    fetchingList,
    list,
    page,
    perPage,
    initPartnerListPage,
}) => {
    useEffect(() => {
        initPartnerListPage();
    }, []);

    return (
        <CrudListPage
            addNewPath="/partner/new"
            title="Locations"
            fields={{
                name: 'name',
                logo: 'logo',
                url: 'website',
            }}
            formatter={(field, row) => {
                switch(field) {
                case 'name':
                    return <Link to={`/partner/${row.id}`}>{row[field]}</Link>;
                case 'logo':
                    return <Link to={`/partner/${row.id}`}><img alt="logo" className="logo" src={`${IMG_SERVER_URL}${row[field]}`} /></Link>;
                case 'url':
                    return <a href={row[field]} target="_blank" rel="noopener noreferrer">{row[field]}</a>;
                default:
                    return false;
                }
            }}
            rows={list}
        />
    );
};

const mapStateToProps = ({ partner }) => ({
    fetchingList: partner.fetchingList,
    list: partner.list,
    page: partner.page,
    perPage: partner.perPage,
});

const mapDispatchToProps = dispatch => ({
    initPartnerListPage: () => dispatch(initPartnerListPage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnerListPage);