import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
const qr = require('qr-image');

const QrImage = ({
    qrData,
}) => {
    const [qrImage, setQrImage] = useState('');
    useEffect(() => {
        const qrSvg = qr.imageSync(`${qrData}`, {type: 'svg'});
        setQrImage(qrSvg);
    }, []);

    return (
        qrImage === '' ? null :
        <div className="qr-code" dangerouslySetInnerHTML={ {__html: qrImage } }></div>
    );
};

QrImage.propTypes = {
    qrData: string,
};

export default QrImage;