import { call, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import moment from 'moment';
import { PAGINATION_PER_PAGE } from 'config/config';
import { FETCH_PROMO_ACTIVITIES_BY_PROMO_CODE } from 'constants/actions/promo-activity';
import { FETCH_PROMO_STATS_BY_PROMO_ID } from 'constants/actions/promo-stats';
import { fetchDetail, syncModelToServer } from '../atomic/crud';

/**
 * When a cloned promo is saved the promotion proper
 * needs to be saved to get a promo id. Once we have that,
 * we can save the related promo activity.
 * Lastly, we can redirect to the newly created promo page.
 */
export function *saveClonedPromo({
}) {
    const newPromo = yield select(state => state.promotion.newInstance);

    // Save the cloned promo, which will get an id assigned
    yield syncModelToServer({
        errorAction: 'CREATE_PROMO_ERROR',
        isNew: true,
        model: 'PROMO',
        successAction: 'CREATE_PROMO_SUCCESS',
        newModel: newPromo,
        redirect: false,
    });

    // Now save the related activity using the new promo id
    const promoList   = yield select(state => state.promotion.list);
    const rawPromoIds = Object.keys(promoList);

    // Highest value should be the new promo id
    const newPromoId  = Math.max.apply(null, rawPromoIds.map(k => Number.parseInt(k, 10)));
    const newActivity = yield select(state => state.promoActivity.newInstance);

    yield syncModelToServer({
        errorAction: 'CREATE_PROMO_ACTIVITY_ERROR',
        isNew: true,
        model: 'PROMO_ACTIVITY',
        successAction: 'CREATE_PROMO_ACTIVITY_SUCCESS',
        newModel: {
            ...newActivity,
            promoId: newPromoId,
        },
        redirect: false,
    });

    // Reirect to the new promo
    const uri = window.location.pathname;
    yield put(push(uri.replace('clone', newPromoId)));
}

export function *initPromoDetailPage({
    id=null,
}) {
    // if we have an id, then load up the promotion
    if(id === null) {
        return;
    }

    const brandList        = yield select(state => state.brand.list);
    const partnerList      = yield select(state => state.partner.list);
    const activityTypeList = yield select(state => state.activityType.list);

    if(Object.keys(brandList).length === 0) {
        yield put({
            type: 'FETCH_BRAND_LIST',
            errorAction: 'FETCH_BRAND_LIST_ERROR',
            model: 'BRAND',
            page: 1,
            perPage: PAGINATION_PER_PAGE,
            successAction: 'FETCH_BRAND_LIST_SUCCESS',
        });
    }

    if(Object.keys(partnerList).length === 0) {
        yield put({
            type: 'FETCH_PARTNER_LIST',
            errorAction: 'FETCH_PARTNER_LIST_ERROR',
            model: 'PARTNER',
            page: 1,
            perPage: PAGINATION_PER_PAGE,
            successAction: 'FETCH_PARTNER_LIST_SUCCESS',
        });
    }

    if(Object.keys(activityTypeList).length === 0) {
        yield put({
            type: 'FETCH_ACTIVITY_TYPE_LIST',
            errorAction: 'FETCH_ACTIVITY_TYPE_LIST_ERROR',
            model: 'ACTIVITY_TYPE',
            page: 1,
            perPage: PAGINATION_PER_PAGE,
            successAction: 'FETCH_ACTIVITY_TYPE_LIST_SUCCESS',
        });
    }

    if(id !== 'new' && id !== 'clone') {
        // bail if the promo is already loaded
        const promoList = yield select(state => state.promotion.list);
        const promoIsObject = typeof promoList[id] === 'object' && promoList[id] !== null;
        if(promoIsObject &&
           typeof promoList[id]['promoCode'] !== 'undefined'
        ) {
            return;
        }

        // @note We use call here instead of put on purpose
        //       If we use put we see the action happen in redux,
        //       but lose the ability to serialize the fetch and select
        yield call(fetchDetail, {
            errorAction: 'FETCH_PROMO_ERROR',
            id,
            model: 'PROMO',
            successAction: 'FETCH_PROMO_SUCCESS',
        });

        yield put({
            type: 'FETCH_USD_TO_AUTX_RATE',
            usd: 1.0,
        });

        // Once we have the promotion loaded, go ahead and fetch the related brand and activities
        let { brandId, coinType, promoCode } = yield select(state => state.promotion.list[id]);

        // TODO Be smarter - only fetch if we don't have loaded already...
        yield call(fetchDetail, {
            errorAction: 'FETCH_BRAND_ERROR',
            id: brandId,
            model: 'BRAND',
            successAction: 'FETCH_BRAND_SUCCESS',
        });

        // XXX Server needs to be able to actually handle bogus wallet addresses
        yield put({
            coinType,
            id: brandId,
            type: 'FETCH_BRAND_BALANCE_AND_ALLOWANCE',
        });

        yield put({
            type: 'FETCH_USD_TO_CRYPTO_RATE',
            coinType: 'autx',
            usdAmount: 1.0,
        });
        yield put({
            type: 'FETCH_USD_TO_CRYPTO_RATE',
            coinType: 'usdc',
            usdAmount: 1.0,
        });
        
        // XXX Server needs to be able to actually handle bogus wallet addresses
        // XXX Gratuitous asking for both, should only ask for one depending on the configuration of the individual promo..
        yield put({
            type: 'FETCH_BRAND_BALANCE_AND_ALLOWANCE',
            id: brandId,
            coinType: 'autx',
        });
        yield put({
            type: 'FETCH_BRAND_BALANCE_AND_ALLOWANCE',
            id: brandId,
            coinType: 'usdc',
        });

        // Fetch the promo activities if they aren't already loaded
        // @note since this is the admin, there's not much point to loading these by promo code,
        //       we could safely load by id...
        const activityList = yield select(state => state.promoActivity.list);
        const activityListKeys = Object.keys(activityList);
        if(activityListKeys.length === 0 ||
           String(activityListKeys[0].promoId) !== String(id)
        ) {
            yield put({
                type: FETCH_PROMO_ACTIVITIES_BY_PROMO_CODE,
                promoCode,
            });
        }

        // Fetch the promo stats every time, b/c the are likely being updated if the promo is in active use
        yield put({
            type: FETCH_PROMO_STATS_BY_PROMO_ID,
            promoId: id,
        });

    } else if (id === 'new') {
        yield put({
            type: 'CREATE_NEW_PROMO',
            newModel: {
                startDate: moment().format('YYYY-MM-DD'),
                endDate: moment().add(7, 'd').format('YYYY-MM-DD'),
            }
        });
    }
}