import React from 'react';
import { string } from 'prop-types';
import { HelpCircle } from 'react-feather';

const Help = ({
    className,
    message,
}) => (
    message.length === 0 ? null :
    <span
        className={className}
        title={message}
    >
        <HelpCircle size={12}/>
    </span>
);

Help.propTypes = {
    className: string,
    message: string
};

Help.defaultProps = {
    className: 'ml-2 pointer',
    message: '',
};

export default Help;