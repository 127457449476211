import React from 'react';
import { func, object, string } from 'prop-types';
import {
    Container,
    Row,
    Table,
} from 'reactstrap';
import { Plus } from 'react-feather';
import { withRouter } from 'react-router-dom';
import { GtButton } from 'components/Form';

const renderHeader = (fields) => (
    <thead>
        <tr>
        {Object.keys(fields).map((field) => (
            <th key={field}>{fields[field]}</th>
        ))}
        </tr>
    </thead>
);

/**
 * Output the value for a cell, optionally creating a link via the 'linker' function.
 */
const renderInnerCell = ({
    field,
    primary=false,
    rowIndex,
    children,
}) => {
    const key = `${field}-${rowIndex}`;
    if(primary) {
        return <th key={key}>{children}</th>;
    }

    return <td key={key}>{children}</td>;
};

const CrudListPage = ({
    history,
    title,
    fields,
    formatter,
    rows,
    addNewPath,
    onAddNew,
    onDisableRow,
    onEnableRow,
    onPaginationNext,
    onPaginationPrev,
    onPaginationPage,
}) => {
    return (
        <Container className="listing-page">
            <Row className="d-flex align-items-center" md={12}>
                <div><h1 className="d-inline-block pt-2">{title}</h1></div>
                <div>
                    {typeof addNewPath === 'string' ?
                    <GtButton
                        className="ml-3"
                        color="success"
                        icon={Plus}
                        iconSize={14}
                        label="Add New"
                        onClick={() => history.push(addNewPath) }
                        small
                    /> : null}
                </div>
            </Row>
            {/* Consider bringing this in sometime...
            <Row className="d-flex justify-content-between filters h4" md={12}>
                <div>
                <strong>All</strong><span> (35)</span>
                </div>
                <div>|</div>
                <div>
                <a href="#">Active</a><span> (5)</span>
                </div>
                <div>|</div>
                <div>
                <a href="#">Inactive</a><span> (35)</span>
                </div>
            </Row>
            <Row className="d-flex justify-content-end">
                <Pagination aria-label="Page navigation example">
                    <PaginationItem disabled>
                    <PaginationLink previous href="#" />
                    </PaginationItem>
                    <PaginationItem active>
                    <PaginationLink href="#">1</PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                    <PaginationLink href="#">2</PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                    <PaginationLink href="#">3</PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                    <PaginationLink href="#">4</PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                    <PaginationLink href="#">5</PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                    <PaginationLink next href="#" />
                    </PaginationItem>
                </Pagination>
            </Row>
            */}
            <Row>
                <Table hover>
                    {renderHeader(fields)}
                    <tbody>
                    {rows === null ? null : Object.values(rows).map((row, rowIndex) => (
                        <tr key={rowIndex}>
                        {Object.keys(fields).map((field, colIndex) => (
                            renderInnerCell({
                                children: formatter(field, row),
                                field,
                                primary: colIndex === 0,
                                row,
                                rowIndex,
                            })
                        ))}
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </Row>
        </Container>
    );
};

CrudListPage.propTypes = {
    addNewPath: string,
    title : string.isRequired,
    fields : object.isRequired,
    formatter: func.isRequired,
    rows : object.isRequired,
    /*
    onAddNew : func.isRequired,
    onDisableRow : func.isRequired,
    onEnableRow : func.isRequired,
    onPaginationNext : func.isRequired,
    onPaginationPrev : func.isRequired,
    onPaginationPage : func.isRequired,
    */
};

CrudListPage.defaultProps = {
    addNewPath: null,
};

export default withRouter(CrudListPage);