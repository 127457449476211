import { put } from 'redux-saga/effects';
import { PAGINATION_PER_PAGE } from 'config/config';

export function *initPartnerListPage() {
    yield put({
        type: 'FETCH_PARTNER_LIST',
        successAction: 'FETCH_PARTNER_LIST_SUCCESS',
        errorAction: 'FETCH_PARTNER_LIST_ERROR',
        model: 'PARTNER',
        page: 1,
        perPage: PAGINATION_PER_PAGE,
    });
}