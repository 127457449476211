import React from 'react';
import { connect }  from 'react-redux';
import {
    Alert,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row
} from 'reactstrap';
import { loginUser, setUserError } from 'actions/user';
import { IMG_SERVER_URL } from 'config/config';
import { GoogleLogin } from '@react-oauth/google';

const LoginPage = ({
    logginIn,
    loggedIn,
    theUser,
    error,
    message,
    loginUser,
    setUserError,
}) =>  {
    const handleGoogleAuthSuccess = (googleUser) => {
        const { credential } = googleUser;

        // Hit the server to verify the token and get relevant user information
        loginUser({
            token: credential,
        });
    };

    const handleGoogleAuthError = (msg) => {
        setUserError(msg);
    };

    const strError = String(error);
    return (
    <div className="login-page flex-column">
        <Container fluid>
            <Col md="12">
                <Row className="justify-content-center">
                    <div className="login-form">
                        <Card className="p-4 border border-primary border-dark">
                            <CardHeader><img className="logo" src={`${IMG_SERVER_URL}/assets/gt-logo.png`} alt="goldenticket logo"/></CardHeader>
                            <CardBody
                                className="d-flex flex-column justify-content-around"
                            >
                                <Row>
                                    {strError.length > 0 ?
                                    <Alert color="danger">{strError}</Alert> : null}
                                </Row>
                                <Row>
                                    <p className="h3 pb-3" md="12">Sign in with your goldenticket.app account</p>
                                    <Col className="p-0" md="12">
                                        <GoogleLogin
                                            buttonText="Login"
                                            onSuccess={handleGoogleAuthSuccess}
                                            onFailure={handleGoogleAuthError}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </Col>
        </Container>
    </div>);
};

const mapStateToProps = ({ user }) => ({
    logginIn: user.loggingIn,
    loggedIn: user.loggedIn,
    theUser: user.theUser,
    error: user.error,
    message: user.message,
});

const mapDispatchToProps = dispatch => ({
    loginUser: (login) => dispatch(loginUser(login)),
    setUserError: (msg) => dispatch(setUserError(msg)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);