// XXX This file is largely duplicated from the "next" project,
// XXX Now duplicated from server project; let's get a shared repo!!!
//     Maybe some day we can have a shared goldenticket npm module for such things...
const template = require('lodash.template');

const cachedTemplates = {};
const templateCode = ({ cache, code, key, options }) => {
    let compiled = '';

    try {
        if(cache === true) {
            if(typeof cachedTemplates[key] === 'undefined') {
                compiled = template(code);
                cachedTemplates[key] = compiled;
            } else {
                compiled = cachedTemplates[key];
            }
        } else {
            compiled = template(code);
        }

        return compiled(options);
    } catch(e) {
        console.error('Failed to template code: ', String(e));
        return '';
    }
};

/**
 * Template engine for custom content blocks on various pages.
 */
const getCustomContent = ({ content, options, cache=false, key='' }) => (
    templateCode({
        cache,
        key,
        options,
        code: content,
    })
);

export {
    getCustomContent,
};