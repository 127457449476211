import {
    FETCH_SHORT_URL,
    FETCH_SHORT_URL_SUCCESS,
    FETCH_SHORT_URL_ERROR,
} from 'constants/actions/short-urls';

const initialState = {
    list: {},
};

export default (state=initialState, action) => {
    switch(action.type) {
        case FETCH_SHORT_URL:
            return {
                list: {
                    ...state.list,
                    [action.urlToShorten]: null,
                }
            };
        case FETCH_SHORT_URL_SUCCESS:
            return {
                list: {
                    ...state.list,
                    [action.result.urlToShorten]: action.result.shortUrl,
                }
            };
        case FETCH_SHORT_URL_ERROR:
            return state;
        default:
            return state;
    }
};