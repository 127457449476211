import React from 'react';
import { arrayOf, bool, func, node, number, oneOfType, string } from 'prop-types';
import {
    FormGroup,
    Row,
} from 'reactstrap';
import {
    RolloverInput,
    SwitchInput,
    DeleteButton,
    SaveButton,
} from 'components/Form';

const ActivityType = ({
    activityId,
    activitySetterMethod,
    activityTypeId,
    children,
    description,
    enabled,
    frequency,
    isClone,
    isNew,
    onDelete,
    onSave,
    savingActivity,
}) => (
    <FormGroup className="pl-4">
        <p><small>{description}</small></p>
        <FormGroup>
            <div className="w-100 text-left" style={{height: '30px'}}>
                <div className="d-flex flex-row-reverse">
                    <DeleteButton
                        className=""
                        iconSize={12}
                        onClick={() => onDelete(activityId)}
                        small
                    />
                    {/* TODO Shouldn't we have some validation here to ensure all fields are entered ??? */}
                    {isNew && !isClone || !isNew ?
                    <SaveButton
                        className="float-right mr-2"
                        disabled={savingActivity || activityTypeId == 0}
                        iconSize={12}
                        onClick={() => onSave()}
                        small
                    />
                    : null}
                </div>
            </div>
        </FormGroup>
        <Row>
            <FormGroup check className="gt-switch">
                <SwitchInput
                    color="dark"
                    enabled={enabled}
                    label="Enabled"
                    onClick={(enabled) => { activitySetterMethod(activityId, 'enabled', enabled); }}
                />
            </FormGroup>
        </Row>
        <FormGroup>
            <RolloverInput
                id={`activity-frequency-${activityId}`}
                label="Frequency"
                onSave={e => { activitySetterMethod(activityId, 'activityFreq', e.target.value / 100); }}
                style={{
                    maxWidth: '50%',
                }}
                saveFieldValue={activityId}
                value={frequency}
            />
        </FormGroup>
        {children}
    </FormGroup>
);


ActivityType.propTypes = {
    activityId: number.isRequired,
    activitySetterMethod: func.isRequired,
    children: oneOfType([
        arrayOf(node),
        node,
    ]),
    description: string.isRequired,
    enabled: bool,
    frequency: number.isRequired,
    isClone: bool,
    isNew: bool,
    onDelete: func.isRequired,
    onSave: func,
};

ActivityType.defaultProps = {
    enabled: false,
    isClone: false,
    isNew: false,
};

export default ActivityType;