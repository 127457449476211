import React, { memo, useState } from 'react';
import { bool, func, string } from 'prop-types';
import { Eye, HelpCircle, Minus, Plus } from 'react-feather';
import {
    Col,
    Collapse,
    FormGroup,
    Label,
    Row,
} from 'reactstrap';
import Editor from './Editor';

/**
 * TODO Provide a meanginful comment...
 */
const MultiEditor = ({
    css,
    initiallyExpanded,
    help,
    html,
    name,
    onCssChange,
    onHtmlChange,
    onPreview,
    withoutPreview,
}) => {
    const [isExpanded, setIsExpanded] = useState(initiallyExpanded);

    const cssId = 'editor-' + name.toLowerCase().replace(/ /g, '-') + '-css';
    const htmlId = 'editor-' + name.toLowerCase().replace(/ /g, '-') + '-html';

    const renderMenu = () => (
        <div>
            <Label className="pointer" onClick={() => setIsExpanded(!isExpanded)}>{name}</Label>
            <span className="ml-2 pb-1 pointer" onClick={() => setIsExpanded(!isExpanded)} title={isExpanded ? 'collapse' : 'expand'}>
                {isExpanded ?  <Minus size={12} /> : <Plus size={12} />}
            </span>
            <span
                className="ml-2 pointer"
                onClick={() => setIsExpanded(!isExpanded)}
                title={help}
            >
                <HelpCircle size={12}/>
            </span>
            {!withoutPreview ?
            <span className="ml-2 pointer" onClick={() => onPreview(`${css}\n${html}`)} title="preview">
                <Eye size={12}/>
            </span> : null}
        </div>
    );

    return (
        <div className="multi-code-editor">
            <style>{`
            #${cssId}, #${htmlId} {
                width: 100% !important;
                height: 250px !important;
            }
            .pointer { cursor: pointer; }
            `}</style>
            <FormGroup>
                {renderMenu()}
                <Collapse isOpen={isExpanded}>
                    <Row>
                        <Col>
                            <Editor
                                mode="css"
                                onChange={value => { if(value !== css) onCssChange(value)}}
                                value={css}
                            />
                        </Col>
                        <Col>
                            <Editor
                                mode="html"
                                onChange={value => { if(value !== html) onHtmlChange(value)}}
                                value={html}
                            />
                        </Col>
                    </Row>
                </Collapse>
            </FormGroup>
        </div>
    );
};

MultiEditor.propTypes = {
    css: string.isRequired,
    expanded: bool,
    help: string,
    html: string.isRequired,
    name: string.isRequired,
    onCssChange: func.isRequired,
    onHtmlChange: func.isRequired,
    onPreview: func,
    withoutPreview: bool,
};

MultiEditor.defaultProps = {
    expanded: false,
    name: '',
    value: '',
    withoutPreview: false,
};

//export default MultiEditor;
export default memo(MultiEditor, (prevProps, nextProps) => {
    const r = prevProps.css === nextProps.css &&
        prevProps.expanded === nextProps.expanded &&
        prevProps.help === nextProps.help &&
        prevProps.html === nextProps.html &&
        prevProps.name === nextProps.name &&
        prevProps.withoutPreview === nextProps.withoutPreview;

    return r;
});