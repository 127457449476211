import { put } from 'redux-saga/effects';
import fetch, { isStatusCodeAnError } from 'lib/fetch';
import { API_SERVER_URL } from 'config/config';
import moment from 'moment';

export function *fetchBrandBalanceAndAllowance({
   id, 
   coinType,
}) {
    try {
        const res = yield fetch(`${API_SERVER_URL}/bc/brand/${id}/balance-and-allowance/${coinType}`);
        if(isStatusCodeAnError(res.status)) {
            yield put({
                coinType,
                type: 'FETCH_BRAND_BALANCE_AND_ALLOWANCE_ERROR',
                error: res.data.message,
            });
        } else {
           yield put({
               coinType,
               id,
               type: 'FETCH_BRAND_BALANCE_AND_ALLOWANCE_SUCCESS',
               ...res.data,
           });
        }
    } catch(e) {
        yield put({
            coinType,
            type: 'FETCH_BRAND_BALANCE_AND_ALLOWANCE_ERROR',
            error: e.message || 'Unknown failure fetching brand balance and allowance',
        });
    }
}

export function *fetchLocationBalance({
   id,
   coinType,
}) {
    try {
        const res = yield fetch(`${API_SERVER_URL}/bc/location/${id}/balance/${coinType}`);
        if(isStatusCodeAnError(res.status)) {
            yield put({
                type: 'FETCH_LOCATION_BALANCE_ERROR',
                coinType,
                error: res.data.message,
            });
        } else {
           yield put({
               coinType,
               id,
               type: 'FETCH_LOCATION_BALANCE_SUCCESS',
               ...res.data,
           });
        }
    } catch(e) {
        yield put({
            coinType,
            error: e.message || 'Unknown failure fetching location balance and allowance',
            type: 'FETCH_LOCATION_BALANCE_ERROR',
        });
    }
}

export function *fetchCryptoToUsdRate({
    coinType,
    cryptoAmount,
}) {
    try {
        const res = yield fetch(`${API_SERVER_URL}/bc/crypto-to-usd/${coinType}/${cryptoAmount}`);
        if(isStatusCodeAnError(res.status)) {
            yield put({
                coinType,
                error: res.data.message,
                type: 'FETCH_CRYPTO_TO_USD_RATE_ERROR',
            });
        } else {
            yield put({
                coinType,
                cryptoAmount,
                timestamp: moment().unix(),
                type: 'FETCH_CRYPTO_TO_USD_RATE_SUCCESS',
                ...res.data,
            });
        }
    } catch(e) {
        yield put({
            coinType,
            error: e.message || 'Unknown failure fetching autx to usd conversion rate',
            type: 'FETCH_CRYPTO_TO_USD_RATE_ERROR',
        });
    }
}

export function *fetchUsdToCryptoRate({
    coinType,
    usdAmount,
}) {
    try {
        const res = yield fetch(`${API_SERVER_URL}/bc/usd-to-crypto/${coinType}/${usdAmount}`);
        if(isStatusCodeAnError(res.status)) {
            yield put({
                coinType,
                error: res.data.message,
                type: 'FETCH_USD_TO_CRYPTO_RATE_ERROR',
            });
        } else {
            yield put({
                coinType,
                timestamp: moment().unix(),
                type: 'FETCH_USD_TO_CRYPTO_RATE_SUCCESS',
                usdAmount,
                ...res.data,
            });
        }
    } catch(e) {
        yield put({
            coinType,
            error: e.message || 'Unknown failure fetching usd to autx conversion rate',
            type: 'FETCH_CRYPTO_TO_USD_RATE_ERROR',
        });
    }
}