import {
    CLEAR_USER_ERROR,
    HYDRATE_SESSION_USER,
    LOGIN_USER,
    LOGOUT_USER,
    SET_USER_ERROR,
} from 'constants/actions/user';

export const loginUser = ({token, email, firstName, lastName}) => ({
    type: LOGIN_USER,
    token,
});

export const logoutUser = () => ({
    type: LOGOUT_USER,
});

export const setUserError = (msg) => ({
    type: SET_USER_ERROR,
    error: msg,
});

export const clearUserError = () => ({
    type: CLEAR_USER_ERROR,
});

export const hydrateSessionUser = (sessionUser) => ({
    type: HYDRATE_SESSION_USER,
    sessionUser,
});