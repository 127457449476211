import {
    CREATE_NEW_LOCATION,
    DELETE_NEW_LOCATION,
} from 'constants/actions/location';

import {
    createFetchModelAction,
    createFetchModelListAction,
    createInsertModelAction,
    createUpdateModelAction,
    createDeleteModelAction,
    createSetNewModelAttrAction,
} from './crud';

const fetchAction      = createFetchModelAction('LOCATION');
const fetchListAction  = createFetchModelListAction('LOCATION');
const insertAction     = createInsertModelAction('LOCATION');
const updateAction     = createUpdateModelAction('LOCATION');
const deleteAction     = createDeleteModelAction('LOCATION');
const setNewAttrAction = createSetNewModelAttrAction('LOCATION');

const createNewLocation = ({partnerId}) => ({
    type: CREATE_NEW_LOCATION,
    newModel: {
        parentId: partnerId,
    },
});

const deleteNewLocation = () => ({
    type: DELETE_NEW_LOCATION,
});

export {
    fetchAction,
    fetchListAction,
    insertAction,
    updateAction,
    deleteAction,
    setNewAttrAction,
    createNewLocation,
    deleteNewLocation,
};