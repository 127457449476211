import React from 'react';
import { array, object } from 'prop-types';
import { Link } from 'react-router-dom';
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from 'reactstrap';
import { Clock, DollarSign, MapPin, Rss, Tag, Users } from 'react-feather';

const HeaderHistory = ({
    brand,
    gtHistory,
    partner,
    promotion,
    referrer,
    wallet,
}) => {
    const iconMap = {
        brand: Tag,
        partner: MapPin,
        promotion: Rss,
        referrer: Users,
        wallet: DollarSign,
    };

    const reducers = {
        brand,
        partner,
        promotion,
        referrer,
        wallet,
    };

    const getWalletName = (wallet) =>
        typeof wallet['gtAlias'] === 'string' && wallet.gtAlias.length > 0
        ? wallet.gtAlias
        : wallet.address;

    const getName = (type, id) => {
        if(typeof reducers[type].list[id] === 'undefined') {
            return null;
        }
        return type === 'wallet'
        ? getWalletName(reducers[type].list[id])
        : reducers[type].list[id].name;
    };

    const parseUri = (uri) => {
        const split = uri.split('/');
        const type  = split[1];
        const id    = split[2];
        const name  = getName(type, id);
        if(name === null) {
            return null;
        }
        return {
            icon: iconMap[type],
            label: name,
            title: `${name} ${type}`,
            type,
        };
    };

    return (
    <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>
            <Clock size={12}/> History
        </DropdownToggle>
        <DropdownMenu right>{
            gtHistory.map((uri, key) => {
                const parsedUri = parseUri(uri);
                if(parsedUri === null) {
                    return null;
                }
                const {icon, label, title} = parsedUri;
                const Icon = icon;
                return <DropdownItem key={key}>
                    <Link title={title} to={uri}>
                        <span className="mr-1"><Icon size={14}/></span>
                        <span>{label}</span>
                    </Link>
                </DropdownItem>;
            })}
        </DropdownMenu>
    </UncontrolledDropdown>);
};

HeaderHistory.propTypes = {
    brand: object.isRequired,
    gtHistory: array.isRequired,
    partner: object.isRequired,
    promotion: object.isRequired,
    referrer: object.isRequired,
    wallet: object.isRequired,
};

HeaderHistory.defaultProps = {
    
};

export default HeaderHistory;
