import React, { memo, useEffect, useState } from 'react';
import {bool, func, number, object, oneOfType, string} from 'prop-types';
import { Label } from 'reactstrap';
import InputMode from './InputMode';
import { DollarValue, TextValue } from 'components/Formatter';
import { colorDanger, offBlack } from 'config/colors';
import Help from './Help';
import ConvertedPrice from 'components/ConvertedPrice';


/**
 * The RolloverInput looks like a read-only element,
 * however on hover the pointer icon is displayed.
 * Then on click, it transforms into an input element.
 * Once in input mode, esc will cancel any changes and enter will trigger a save.
 */
const RolloverInput = ({
    cryptoConversionRate,
    caster, // By default pass through, can specify formatDollarValue, formatLocalValue, formatPercentValue
    coinType,
    className,
    defaultText,
    defaultTextColor,
    displayModeComponent,
    displayModeNodeProps,
    help,
    hoverText,
    id,
    keepExistingValue,
    label,
    onSave,
    required,
    saveFieldValue,
    style,
    usdValue,
    textColor,
    value,
    zeroNotAllowed,
}) => {
    const [mode, setMode] = useState('display');
    const [internalValue, setInternalValue] = useState(value);

    /**
     * When clicking in display mode, we'll switch to input mode.
     */
    const onDisplayModeClick = () => {
        setMode('input');
    };

    const onInputModeChange = (changeType, newValue) => {
        if(changeType === 'save' && newValue !== internalValue) {
            // Update the value locally
            setInternalValue(newValue);

            // Tell the parent component to update (presumably on the server)
            onSave({target: { value: caster(newValue) } }, saveFieldValue);
        }
        setMode('display');
    };

    const useDefaultText = () => internalValue === '' || internalValue === null;

    // XXX Why are we doing this,
    //     isn't it effectively achieved by useState(value) above ???
    useEffect(() => {
        setInternalValue(value);
    }, [value]);

    /**
     * Allow input mode is a way of letting client code say
     * If there's a pre-existing value, don't let the user change it.
     */
    const allowInputMode = () => (
        keepExistingValue === false || 
        (keepExistingValue === true && (value === '' || value === null)));

    const missingRequired = () =>
        required && internalValue === '' ||
        required && internalValue === 0 && zeroNotAllowed;

console.log('missingRequired: ', missingRequired())
console.log('internalValue: ', internalValue)

    const getTextColor = () =>
        missingRequired()
        ? colorDanger
        : useDefaultText() ? defaultTextColor : textColor;

    const getConversion = () =>
        missingRequired() || internalValue === ''
        ? ''
        : <ConvertedPrice
            coinType={coinType}
            cryptoConversionRate={cryptoConversionRate}
            usdValue={internalValue}
          />;

    const renderDisplayMode = () => {
        const CustomTag        = displayModeComponent;
        const _missingRequired = missingRequired();
        return (
        <span
            id={id}
            style={{
                cursor:  allowInputMode() ? 'pointer' : 'default',
                ...style,
            }}
            onClick={allowInputMode() ? onDisplayModeClick : null}
            title={hoverText === '' || !allowInputMode() ? null : hoverText}
        >
            {CustomTag === null || useDefaultText() === true ? (
            <span>
                <span
                    className={_missingRequired ? 'form-control is-invalid' : ''}
                    style={{
                        color: getTextColor(),
                        display: 'inline-block',
                        minHeight: '20px',
                        minWidth: '20px',
                    }}
                >{useDefaultText() ? defaultText : internalValue}</span>
            </span>) : (
                CustomTag === DollarValue && cryptoConversionRate !== 0.0 && !_missingRequired
                ? getConversion()
                : <CustomTag {...displayModeNodeProps}>{internalValue}</CustomTag>
            )}
        </span>);
    };

    const renderInputMode = () =>
        <InputMode
            className={missingRequired() ? `form-control is-invalid` : 'form-control'}
            onChange={onInputModeChange}
            style={style}
            value={internalValue}
        />;

    const getLabelClass = () =>
        help.length > 0
        ? 'm-0 p-1 align-self-center'
        : 'm-0 align-self-center';

//console.log(`Rendering ${id} RolloverInput`)
    return (
        <div className={`d-flex ${className}`}>
            <Label className={getLabelClass()} for={id} >{label}</Label>
            <Help className="mr-2 pointer" message={help} />
            {mode === 'display' ? renderDisplayMode() : renderInputMode()}
        </div>
    );
};

RolloverInput.propTypes = {
    cryptoConversionRate: number,
    caster: func,
    coinType: string,
    className: string,
    defaultText: string,
    defaultTextColor: string,
    displayModeComponent: func,
    displayModeNodeProps: object,
    help: string,
    hoverText: string,
    id: string.isRequired,
    keepExistingValue: bool,
    label: string.isRequired,
    onSave: func.isRequired,
    required: bool,
    saveFieldValue: oneOfType([
        string, number,
    ]).isRequired,
    style: object,
    textColor: string,
    usdValue: number,
    value: oneOfType([
        string, number,
    ]),
    zeroNotAllowed: bool,
};

RolloverInput.defaultProps = {
    cryptoConversionRate: 0.0,
    caster: value => value,
    className: '',
    defaultText: 'Click to set value',
    defaultTextColor: offBlack,
    displayModeComponent: TextValue,
    displayModeNodeProps: {},
    help: '',
    hoverText: '',
    keepExistingValue: false,
    required: false,
    style: null,
    textColor: offBlack,
    value: null,
    zeroNotAllowed: false
};

//export default RolloverInput;
const objToStr = (obj) => {
    if(obj === null) return '';
    const k = Object.keys(obj).join(',');
    const v = Object.values(obj).join(',');
    return `${k};${v}`;
}
export default memo(RolloverInput, (prevProps, nextProps) => {
//console.log(`Considering rerender of RolloverInput ${prevProps.id}`, prevProps, nextProps)
    const r = prevProps.cryptoConversionRate === nextProps.cryptoConversionRate &&
        prevProps.coinType === nextProps.coinType &&
        prevProps.className === nextProps.className &&
        prevProps.defaultTextColor === nextProps.defaultTextColor &&
        objToStr(prevProps.displayModeNodeProps) === objToStr(nextProps.displayModeNodeProps) && // XXX may need deeper comparison
        prevProps.help === nextProps.help &&
        prevProps.hoverText === nextProps.hoverText &&
        prevProps.keepExistingValue === nextProps.keepExistingValue &&
        prevProps.label === nextProps.label &&
        prevProps.required === nextProps.required &&
        prevProps.saveFieldValue === nextProps.saveFieldValue &&
        objToStr(prevProps.style) === objToStr(nextProps.style) && // XXX may need deeper comparison
        prevProps.textColor === nextProps.textColor &&
        prevProps.usdValue === nextProps.usdValue &&
        prevProps.value === nextProps.value &&
        prevProps.zeroNotAllowed === nextProps.zeroNotAllowed;

    return r;
});