import { call, put, select } from 'redux-saga/effects';
import { PAGINATION_PER_PAGE } from 'config/config';
import { fetchDetail } from '../atomic/crud';

export function *initPartnerDetailPage({
    id=null,
}) {
    if(id === null) {
        return;
    }

    const walletList = yield select(state => state.wallet.list);
    if(Object.keys(walletList).length === 0) {
        yield put({
            type: 'FETCH_WALLET_LIST',
            errorAction: 'FETCH_WALLET_LIST_ERROR',
            model: 'WALLET',
            page: 1,
            perPage: PAGINATION_PER_PAGE,
            successAction: 'FETCH_WALLET_LIST_SUCCESS',
        });
    }

    if(id !== 'new') {
        // bail if the partner is already loaded
        const partnerList = yield select(state => state.partner.list);
        if(typeof partnerList['id'] === 'object' && partnerList.id !== null) {
            return;
        }

        // @note We use call here instead of put on purpose
        //       If we use put we see the action happen in redux,
        //       but lose the ability to serialize the fetch and select
        yield call(fetchDetail, {
            errorAction: 'FETCH_PARTNER_ERROR',
            id,
            model: 'PARTNER',
            successAction: 'FETCH_PARTNER_SUCCESS',
        });

        // Once we have the partner loaded, go ahead and fetch the related locations
        const locationList = yield select(state => state.dbLocation.list);
        const locationListKeys = Object.keys(locationList);
        if(locationListKeys.length === 0 ||
           String(locationListKeys[0].parentId) !== String(id)
        ) {
            yield put({
                type: 'FETCH_LOCATION_LIST',
                errorAction: 'FETCH_LOCATION_LIST_ERROR',
                model: 'LOCATION',
                page: id, // XXX Abuse of the page field....; we're telling the server to fetch the physical locations for this partner..
                perPage: PAGINATION_PER_PAGE,
                successAction: 'FETCH_LOCATION_LIST_SUCCESS',
            });
        }
    }
    else {
        yield put({
            type: 'CREATE_NEW_PARTNER',
        });
    }
}