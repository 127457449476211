import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { hydrateSessionUser, logoutUser } from 'actions/user';
import { clearErrorsAction } from 'actions/crud';
import { addHistory } from 'actions/history';
import { DashboardLayout } from './components';

const DashboardLayoutWrapped = ({
    activityType,
    addHistory,
    brand,
    clearErrors,
    dbLocation,
    gtHistory,
    history,
    hydrateSessionUser,
    location,
    logoutUser,
    partner,
    promotion,
    promoActivity,
    promoStats,
    redirect,
    referrer,
    referrerPromo,
    theUser,
    uiComps,
    wallet,
}) => (
    <DashboardLayout
        activityType={activityType}
        addHistory={addHistory}
        brand={brand}
        clearErrors={clearErrors}
        dbLocation={dbLocation}
        gtHistory={gtHistory}
        history={history}
        hydrateSessionUser={hydrateSessionUser}
        location={location}
        logoutUser={logoutUser}
        partner={partner}
        promotion={promotion}
        promoActivity={promoActivity}
        promoStats={promoStats}
        redirect={redirect}
        referrer={referrer}
        referrerPromo={referrerPromo}
        theUser={theUser}
        uiComps={uiComps}
        wallet={wallet}
    />
);

const mapStateToProps = ({
    activityType,
    brand,
    gtHistory,
    partner,
    dbLocation,
    promotion,
    promoActivity,
    promoStats,
    referrer,
    referrerPromo,
    shortUrl,
    uiComps,
    user,
    wallet,
}) => ({
    activityType,
    brand,
    gtHistory: gtHistory.history,
    partner,
    dbLocation,
    promotion,
    promoActivity,
    promoStats,
    referrer,
    referrerPromo,
    theUser: user.theUser,
    uiComps,
    wallet,
});

const mapDispatchToProps = dispatch => ({
    addHistory: (uri) => dispatch(addHistory({ uri })),
    clearErrors: () => dispatch(clearErrorsAction()),
    hydrateSessionUser: (sessionUser) => dispatch(hydrateSessionUser(sessionUser)),
    logoutUser: () => dispatch(logoutUser()),
    redirect: (path) => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DashboardLayoutWrapped));