/**
 * Format a value as US dollars
 * https://stackoverflow.com/a/16233919/680920
 */
const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    // the default value for minimumFractionDigits depends on the currency
    // and is usually already 2
});

/**
 * Format a number with commas.
 */
export const formatDollarValue = (number, removeSymbol=false) => {
    const fNum = Number.parseFloat(number);
    const rawFormatted = moneyFormatter.format(Number.isNaN(fNum) ? 0 : fNum);
    
    return removeSymbol === true ? rawFormatted.replace('$', '') : rawFormatted;
};

export const formatLocaleValue = (number) => {
    const type = typeof number;
    if(type === 'string') {
        const _number = Number.parseInt(number, 10);
        if(Number.isNaN(_number)) {
            return 0;
        }
        return _number.toLocaleString();
    }
    else if(type === 'number') {
        return number.toLocaleString();
    }
    else {
        return 0;
    }
};

/**
 * Given a decimal value that represents a percentage,
 * format it as such.
 */
export const formatPercentValue = (number) => {
    const fNum = Number.parseFloat(number) * 100;
    return Number.isNaN(fNum) ? 0 : fNum.toFixed(2) + '%';
};