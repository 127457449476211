import Dashboard from './pages/Dashboard';
//import ErrorPage from './pages/404';

//================================================================================
// Custom Pages
//================================================================================
import BrandPage from './pages/BrandPage';
import BrandListPage from './pages/BrandListPage';
import CompensationListPage from './pages/CompensationListPage';
import LoginPage from 'views/pages/LoginPage';
import PartnerPage from './pages/PartnerPage';
import PartnerListPage from './pages/PartnerListPage';
import PromoPage from './pages/PromoPage';
import PromoListPage from './pages/PromoListPage';
import WalletListPage from './pages/WalletListPage';
import WalletPage from './pages/WalletPage';

import GtComponents from './elements/GtComponents';
import Buttons from './elements/Buttons';
import Alerts from './elements/Alerts';
import Grid from './elements/Grid';
import Typography from './elements/Typography';
import Cards from './elements/Cards';
import Tabs from './elements/Tabs';
import Tables from './elements/Tables';
import ProgressBars from './elements/ProgressBars';
import Modals from './elements/Modals';
import Breadcrumbs from './elements/Breadcrumbs';
import Forms from './elements/Forms';
import Loaders from './elements/Loaders';
import Avatars from './elements/Avatars';

/*
import PaginationPage from './elements/PaginationPage';
import BlankPage from './elements/BlankPage';
import SubNav from './elements/SubNav';
import Analytics from './elements/Analytics';
import Feed from './elements/Feed';
import Invoice from './elements/Invoice';
import CmsPage from './elements/CmsPage';
import Widgets from './elements/Widgets';
*/

// See React Router documentation for details: https://reacttraining.com/react-router/web/api/Route
const pageList = [
  {
    name: 'Dashboard',
    path: '/home',
    component: Dashboard,
  },
  {
    name: 'GT Components',
    path: '/elements/gt-components',
    component: GtComponents,
  },
  {
    name: 'Buttons',
    path: '/elements/buttons',
    component: Buttons,
  },
  {
    name: 'Alerts',
    path: '/elements/alerts',
    component: Alerts,
  },
  {
    name: 'Grid',
    path: '/elements/grid',
    component: Grid,
  },
  {
    name: 'Typography',
    path: '/elements/typography',
    component: Typography,
  },
  {
    name: 'Cards',
    path: '/elements/cards',
    component: Cards,
  },
  {
    name: 'Tabs',
    path: '/elements/tabs',
    component: Tabs,
  },
  {
    name: 'Tables',
    path: '/elements/tables',
    component: Tables,
  },
  {
    name: 'Progress Bars',
    path: '/elements/progressbars',
    component: ProgressBars,
  },
  {
    name: 'Modals',
    path: '/elements/modals',
    component: Modals,
  },
  {
    name: 'Breadcrumbs',
    path: '/elements/breadcrumbs',
    component: Breadcrumbs,
  },
  {
    name: 'Forms',
    path: '/elements/forms',
    component: Forms,
  },
  {
    name: 'Loaders',
    path: '/elements/loaders',
    component: Loaders,
  },
  {
    name: 'Avatars',
    path: '/elements/avatars',
    component: Avatars,
  },
  /*
  {
    name: 'Pagination',
    path: '/elements/pagination',
    component: PaginationPage,
  },
  {
    name: 'Blank',
    path: '/pages/blank',
    component: BlankPage,
  },
  {
    name: 'Sub Navigation',
    path: '/pages/subnav',
    component: SubNav,
  },
  {
    name: '404',
    path: '/pages/404',
    component: ErrorPage,
  },
  {
    name: 'Analytics',
    path: '/apps/analytics',
    component: Analytics,
  },
  {
    name: 'Activity Feed',
    path: '/apps/feed',
    component: Feed,
  },
  {
    name: 'Invoice',
    path: '/apps/invoice',
    component: Invoice,
  },
  {
    name: 'CMS',
    path: '/apps/cms',
    component: CmsPage,
  },
  {
    name: 'Widgets',
    path: '/widgets',
    component: Widgets,
  },
  */
  
//================================================================================
// Custom Pages
//================================================================================
  {
    name: 'Brand Detail',
    path: /\/brand\/\d+/,
    component: BrandPage,
  },
  {
    name: 'Brand Detail',
    path: '/brand/new',
    component: BrandPage,
  },
  {
    name: 'Brand Listing',
    path: '/brands',
    component: BrandListPage,
  },
  {
    name: 'Partner Detail',
    path: /\/partner\/\d+/,
    component: PartnerPage,
  },
  {
    name: 'Partner Detail',
    path: '/partner/new',
    component: PartnerPage,
  },
  {
    name: 'Partner Listing',
    path: '/partners',
    component: PartnerListPage,
  },
  {
    name: 'Promotion Detail',
    path: /\/promotion\/\d+/,
    component: PromoPage,
  },
  {
    name: 'Promotion Detail',
    path: '/promotion/clone',
    component: PromoPage,
  },
  {
    name: 'Promotion Detail',
    path: '/promotion/new',
    component: PromoPage,
  },
  {
    name: 'Promotion Listing',
    path: '/promotions',
    component: PromoListPage,
  },
  {
    name: 'Wallet Listing',
    path: '/wallets',
    component: WalletListPage,
  },
  {
    name: 'Wallet Detail',
    path: /\/wallet\/\d+/,
    component: WalletPage,
  },
  {
    name: 'Wallet Detail',
    path: '/wallet',
    component: WalletPage,
  },
  {
    name: 'Login',
    path: '/login',
    component: LoginPage,
  },
  {
    name: 'Compensation',
    path: '/compensation',
    component: CompensationListPage,
  }
];

export default pageList;
