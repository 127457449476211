import React from "react";
import { arrayOf, node, number, oneOfType, string, } from 'prop-types';

const Svg = ({x, y, width, height, viewBox, enableBackground, children}) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x={`${x}px`}
        y={`${y}px`}
        width={`${width}px`}
        height={`${height}px`}
        viewBox={`${viewBox}`}
        enableBackground={`${enableBackground}`}
        xmlSpace="preserve"
    >
        {children}
    </svg>
);

Svg.propTypes = {
    x: number,
    y: number,
    viewBox: string.isRequired,
    enableBackground: string.isRequired,
    width: number,
    height: number,
    children: oneOfType([
        arrayOf(node),
        node
    ]).isRequired,
};

Svg.defaultProps = {
    x: 0,
    y: 0,
    width: 40,
    height: 40,
};

export default Svg;