import {
    CLEAR_USER_ERROR,
    CLEAR_USER_MESSAGE,
    HYDRATE_SESSION_USER,
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    LOGOUT_USER,
    SET_USER_ERROR,
} from 'constants/actions/user';

export const initialState = {
    loggedIn: false,
    loggingIn: false,
    theUser: null,
    error: '',
    message: '',
};

export default (state = initialState, action) => {
    switch(action.type) {
        case CLEAR_USER_ERROR:
            return {
                ...state,
                error: '',
            };
        case CLEAR_USER_MESSAGE:
            return {
                ...state,
                message: '',
            };
        case HYDRATE_SESSION_USER:
            return {
                ...state,
                loggedIn: true,
                theUser: action.sessionUser,
            };
        case LOGIN_USER_SUCCESS:
            return {
                ...state,
                loggedIn: true,
                loggingIn: false,
                theUser: action.user,
            };
        case LOGIN_USER_FAILURE:
            return {
                ...state,
                loggedIn: false,
                loggingIn: false,
                error: action.error,
            };
        case LOGIN_USER:
            return {
                ...state,
                loggingIn: true,
                error: '',
            };
        case LOGOUT_USER:
            return initialState;
        case SET_USER_ERROR:
            return {
                ...state,
                error: action.error,
            };
         default:
             return state;
    } 
};