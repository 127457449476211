import React from 'react';
import { bool, number, string } from 'prop-types';
import {
    Alert,
    Card,
    CardBody,
    CardHeader,
    Progress,
    Row,
} from 'reactstrap';
import PerformanceStat from './PerformanceStat';

const PromoPerformanceCard = ({
    avgTicketSpendCrypto,
    avgTicketSpendUsd,
    brandAllowanceCrypto,
    brandAllowanceUsd,
    brandBalanceCrypto,
    brandBalanceUsd,
    brandSpendCrypto,
    brandSpendUsd,
    coinType,
    gtFeesCrypto,
    gtFeesUsd,
    haveStats,
    locationRewardCrypto,
    locationRewardUsd,
    maxUses,
    maxUsesExceeded,
    numTicketsActivated,
    numTicketsCompensated,
    numTicketsCompleted,
    numTicketsCreated,
    numTicketsRedeemed,
    referrerRewardCrypto,
    referrerRewardUsd,
}) => {
    let maxTickets = 0;
    let redemptionsRemaining = 0;
    let approxSpendNeededUsd = 0;
    //let approxSpendNeededCrypto = 0;
    let allowanceDiscrepancyUsd = 0;
    let balanceDiscrepancyUsd = 0;
    /*
    let allowanceDiscrepancyCrypto = 0;
    let balanceDiscrepancyCrypto = 0;
    */
    redemptionsRemaining = maxUses - numTicketsRedeemed;
    approxSpendNeededUsd = avgTicketSpendUsd * redemptionsRemaining;
    //approxSpendNeededCrypto = avgTicketSpendCrypto * redemptionsRemaining;

    // Determine if there is potentially insufficient budget or allowance to finish the promo
    if(typeof brandAllowanceUsd === 'number') {
        allowanceDiscrepancyUsd = brandAllowanceUsd - approxSpendNeededUsd;
    }
    if(typeof brandBalanceUsd === 'number') {
        balanceDiscrepancyUsd = brandBalanceUsd - approxSpendNeededUsd;
    }
    /*
    if(typeof brandAllowanceCrypto === 'number') {
        allowanceDiscrepancyCrypto = brandAllowanceCrypto - approxSpendNeededCrypto;
    }
    if(typeof brandBalanceCrypto === 'number') {
        balanceDiscrepancyCrypto = brandBalanceCrypto - approxSpendNeededCrypto;
    }
    */

    return <Card className="shadow ticket-stats">
        <CardHeader>Promotion Performance</CardHeader>
        {!haveStats ? null :
        <CardBody>
            <Row style={{minHeight: '100px'}}>
                <PerformanceStat coinType={coinType} title="Brand Spend" usdValue={brandSpendUsd} cryptoValue={brandSpendCrypto} />
                <PerformanceStat coinType={coinType} title="Avg Ticket Spend" usdValue={avgTicketSpendUsd} cryptoValue={avgTicketSpendCrypto} />
                <PerformanceStat coinType={coinType} title="Goldenticket Fees" usdValue={gtFeesUsd} cryptoValue={gtFeesCrypto} />
                <PerformanceStat coinType={coinType} title="Location Rewards" usdValue={locationRewardUsd} cryptoValue={locationRewardCrypto} />
                <PerformanceStat coinType={coinType} title="Referrer Rewards" usdValue={referrerRewardUsd} cryptoValue={referrerRewardCrypto} />
            </Row>
            {maxUsesExceeded ? <Alert
                className="text-center"
                color={maxUsesExceeded ? 'danger' : 'secondary'}
            >Max uses {maxUsesExceeded ? 'have been' : `have not been exceeded - ${redemptionsRemaining} tickets remaining`}</Alert> : null}
            {balanceDiscrepancyUsd < 0 ? <Alert
                className="text-center"
                color={balanceDiscrepancyUsd < 0 ? 'danger' : 'secondary'}
            >{balanceDiscrepancyUsd < 0 ? 'Insufficient' : 'Sufficient' } balance ${brandBalanceUsd} remaining to complete the promo (${approxSpendNeededUsd})</Alert> : null}
            {allowanceDiscrepancyUsd < 0 ? <Alert
                className="text-center"
                color={allowanceDiscrepancyUsd < 0 ? 'danger' : 'secondary'}
            >{allowanceDiscrepancyUsd < 0 ? 'Insufficient' : 'Sufficient' } allowance ${brandAllowanceUsd} remaining to complete the promo (${approxSpendNeededUsd})</Alert> : null}
            <div className="p-1">
                <Progress
                    bar
                    color="warning"
                    max={maxTickets}
                    value={numTicketsCreated}
                >{numTicketsCreated} tickets created</Progress>
            </div>
            <div className="p-1">
                <Progress
                    bar
                    color="warning"
                    max={maxTickets}
                    value={numTicketsActivated}
                >{numTicketsActivated} tickets activated</Progress>
            </div>
            <div className="p-1">
                <Progress
                    bar
                    color="warning"
                    max={maxTickets}
                    value={numTicketsCompleted}
                >{numTicketsCompleted} tickets completed</Progress>
            </div>
            <div className="p-1">
                <Progress
                    bar
                    color="warning"
                    value={numTicketsRedeemed}
                >{numTicketsRedeemed} tickets redeemed</Progress>
            </div>
            <div className="p-1">
                <Progress
                    bar
                    color="warning"
                    max={numTicketsRedeemed}
                    value={numTicketsCompensated}
                >{numTicketsCompensated} tickets compensated</Progress>
            </div>
        </CardBody>}
    </Card>;
};

PromoPerformanceCard.propTypes = {
    avgTicketSpendCrypto: number,
    avgTicketSpendUsd: number,
    brandAllowanceCrypto: number,
    brandAllowanceUsd: number,
    brandBalanceCrypto: number,
    brandBalanceUsd: number,
    brandSpendCrypto: number,
    brandSpendUsd: number,
    coinType: string.isRequired,
    gtFeesCrypto: number,
    gtFeesUsd: number,
    haveStats: bool,
    locationRewardCrypto: number,
    locationRewardUsd: number,
    maxUses: number.isRequired,
    maxUsesExceeded: bool.isRequired,
    numTicketsActivated: number.isRequired,
    numTicketsCompensated: number.isRequired,
    numTicketsCompleted: number.isRequired,
    numTicketsCreated: number.isRequired,
    numTicketsRedeemed: number.isRequired,
    referrerRewardCrypto: number,
    referrerRewardUsd: number,
};

PromoPerformanceCard.defaultProps = {
    avgTicketSpendCrypto: 0.0,
    avgTicketSpendUsd: 0.0,
    brandAllowanceCrypto: 0.0,
    brandAllowanceUsd: 0.0,
    brandBalanceCrypto: 0.0,
    brandBalanceUsd: 0.0,
    brandSpendCrypto: 0.0,
    brandSpendUsd: 0.0,
    gtFeesCrypto: 0.0,
    gtFeesUsd: 0.0,
    haveStats: false,
    locationRewardCrypto: 0.0,
    locationRewardUsd: 0.0,
    referrerRewardCrypto: 0.0,
    referrerRewardUsd: 0.0,
};

export default PromoPerformanceCard;