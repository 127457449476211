import { put } from 'redux-saga/effects';
import fetch, { isStatusCodeAnError } from 'lib/fetch';
import { API_SERVER_URL } from 'config/config';
import {
    FETCH_PROMO_ACTIVITIES_BY_PROMO_CODE_ERROR,
    FETCH_PROMO_ACTIVITIES_BY_PROMO_CODE_SUCCESS,
} from 'constants/actions/promo-activity';

/**
 * Fetch all the activities for a given promotion.
 * @note We don't paginate, because it's anticipated this number will always be very low.
 */
export function *fetchPromoActivitiesByPromoCode({
    promoCode,    
}) {
    try {
        const res = yield fetch(`${API_SERVER_URL}/promo-activity/promo-code/${promoCode}`);
        if(isStatusCodeAnError(res.status)) {
            yield put({
                type: FETCH_PROMO_ACTIVITIES_BY_PROMO_CODE_ERROR,
                error: res.data.message,
            });
        } else {
           yield put({
               type: FETCH_PROMO_ACTIVITIES_BY_PROMO_CODE_SUCCESS,
               list: res.data,
           });
        }
    } catch(e) {
        yield put({
            type: FETCH_PROMO_ACTIVITIES_BY_PROMO_CODE_SUCCESS,
            error: e.message || 'Unknown failure fetching promo activities by promo code',
        });
    }
}