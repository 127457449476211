import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ConnectedRouter } from 'connected-react-router';
import { hot } from 'react-hot-loader';
import DashboardLayoutWrapped from './layouts/DashboardLayoutWrapped';
import LoggedOutLayout from './layouts/LoggedOutLayout';
import { GOOGLE_SIGNIN_CLIENT_ID } from 'config/config';
import './vibe/scss/styles.scss';

const App = ({
    store,
    history,
}) => {
    return (
        <Provider store={store}>
            <GoogleOAuthProvider clientId={GOOGLE_SIGNIN_CLIENT_ID}>
                <ConnectedRouter history={history}>
                    <Switch>
                        <Route exact path="/login" component={LoggedOutLayout} />
                        <Route component={DashboardLayoutWrapped} />
                    </Switch>
                </ConnectedRouter>
            </GoogleOAuthProvider>
        </Provider>
    );
};

export default hot(module)(App);