import {
    createFetchModelAction,
    createFetchModelListAction,
    createInsertModelAction,
    createUpdateModelAction,
    createDeleteModelAction,
    createSetNewModelAttrAction,
} from './crud';

const fetchAction      = createFetchModelAction('WALLET');
const fetchListAction  = createFetchModelListAction('WALLET');
const insertAction     = createInsertModelAction('WALLET');
const updateAction     = createUpdateModelAction('WALLET');
const deleteAction     = createDeleteModelAction('WALLET');
const setNewAttrAction = createSetNewModelAttrAction('WALLET');

export {
    fetchAction,
    fetchListAction,
    insertAction,
    updateAction,
    deleteAction,
    setNewAttrAction,
};
