import React from 'react';
import ActivityType from './ActivityType';

const Passthrough = ({
    ...activityTypeProps
}) => (
    <ActivityType
        {...activityTypeProps}
    />
);

Passthrough.defaultProps = ActivityType.defaultProps;
Passthrough.propTypes    = ActivityType.propTypes;
Passthrough.dbName       = 'passthrough';

export default Passthrough;