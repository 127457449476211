import React, { useEffect, useState } from 'react';
import { connect }  from 'react-redux';
import { push } from 'connected-react-router';
import {
    Row
} from 'reactstrap';
import { fetchBrandBalanceAndAllowance } from 'actions/blockchain';
import * as promoActions from 'actions/promotion';
import * as promoActivityActions from 'actions/promo-activity';
import { fetchPromoStatsByPromoId }from 'actions/promo-stats';
import { fetchShortUrl } from 'actions/short-urls';
import { APP_BASE_URL } from 'config/config';
import 'react-datepicker/dist/react-datepicker.css';
import { initPromoDetailPage, saveClonedPromo } from 'actions/pages/init';
import { PromoMain, PromoSidebar } from 'components/Promotion';
import { getBlockchainIdentifiers } from 'lib/blockchain';
import { clearPreview, previewPromo } from 'actions/preview';

const PromoPage = ({
    activities,
    savingActivity,
    newActivity,
    activityTypeList,
    createNewPromoActivity,
    blockchain,
    brandList,
    clonePromo,
    deletePromo,
    deleteNewPromoActivity,
    fetchBrandBalanceAndAllowance,
    newPromoList,
    partnerList,
    promoList,
    promoStatsList,
    errors,
    initPromoDetailPage,
    clearPreview,
    generatePreview,
    previewing,
    previewHtml,
    newPromoInstance,
    router,
    setPromoAttr,
    saveClonedPromo,
    saveNewPromo,
    saveNewPromoActivity,
    updatePromo,
    setNewPromoAttr,
    setPromoActivityAttr,
    setPromoActivityType,
    setNewPromoActivityAttr,
    deletePromoActivity,
    updatePromoActivity,
    redirect,
    fetchShortUrl,
    shortUrls,
}) => {
    // Grab the promo id from the URL
    let promoId = router.location.pathname.split('/').pop();

    // Effective model is determined by new vs edit
    const isNew   = promoId === 'new' || promoId === 'clone';
    const isClone = promoId === 'clone';

    if(!isNew && !isClone) {
        promoId = Number.parseInt(promoId, 10);
    }

    const promoSetterMethod = isNew ? setNewPromoAttr : setPromoAttr;

    let effectivePromo = isNew ? newPromoInstance : promoList[promoId];

    let theBrand = isNew || typeof effectivePromo === 'undefined' || typeof brandList === 'undefined' ? null : brandList[effectivePromo.brandId];

    // Wrangle external URLs
    let extTicketUrl = '';
    if(typeof effectivePromo !== 'undefined' &&
       effectivePromo !== null &&
       typeof effectivePromo['promoCode'] === 'string'
    ) {
        extTicketUrl = `${APP_BASE_URL}/ticket/${effectivePromo.promoCode}`;
    }

    // Clear the preview when we leave this page
    useEffect(() => {
        return () => {
            clearPreview();
        };
    }, []);

    useEffect(() => {
        if(typeof shortUrls.list[extTicketUrl] === 'undefined' && shortUrls.list[extTicketUrl] !== null && extTicketUrl !== '') {
            fetchShortUrl(extTicketUrl);
        }
    }, [effectivePromo]);

    // Carve out the stats of interest
    let promoStats = null;
    if(typeof promoStatsList[promoId] !== 'undefined' && promoStatsList[promoId] !== null) {
        promoStats = promoStatsList[promoId];
    }

    const [blockchainIds, setBlockchainIds] = useState(null);
    if(typeof effectivePromo !== 'undefined' &&
       effectivePromo !== null &&
       typeof effectivePromo.coinType === 'string' &&
       typeof blockchain !== 'undefined' &&
       blockchainIds === null
    ) {
        setBlockchainIds(getBlockchainIdentifiers(effectivePromo.coinType));
    }

    const coinType
        = typeof effectivePromo !== 'undefined' &&
          effectivePromo !== null &&
          typeof effectivePromo.coinType === 'string'
        ? effectivePromo.coinType
        : 'autx';

    // Fetch the promotion detail and dependent models on page load
    useEffect(() => {
        initPromoDetailPage(promoId);
    }, [router.location.pathname]);
    
    // Bail if we have no promo info
    if(typeof effectivePromo === 'undefined' || effectivePromo === null) {
        return null;
    }

    // Find the passthrough activity type id
    let passthroughActivityTypeId = 0;
    if(Object.keys(activityTypeList).length > 0) {
        for(let activityTypeId in activityTypeList) {
            if(activityTypeList[activityTypeId].name === 'passthrough') {
                passthroughActivityTypeId = activityTypeId;
                break;
            }
        }
    }

    const activityKeys = Object.keys(activities);

    const getBrandCryptoVal = key =>
        typeof theBrand === 'object' && theBrand !== null && blockchainIds === null ? 0.0 : theBrand[blockchainIds[key]];

    return (
    <div className="detail-page">
        <h3 className="m-b">{isNew ? 'New' : 'Edit'} Promotion</h3>
        <Row>
            <PromoMain
                activities={activities}
                activityKeys={activityKeys}
                activityTypeList={activityTypeList}
                newActivity={newActivity}
                createNewPromoActivity={createNewPromoActivity}
                brandList={brandList}
                coinType={coinType}
                deleteNewPromoActivity={deleteNewPromoActivity}
                deletePromoActivity={deletePromoActivity}
                effectivePromo={effectivePromo}
                getBrandCryptoVal={getBrandCryptoVal}
                generatePreview={({activityId, page, state}) => {
                    generatePreview(
                        typeof activityId !== 'undefined' ? activityId : 0,
                        effectivePromo,
                        page,
                        typeof state === 'undefined' ? null : state);
                }}
                isClone={isClone}
                isNew={isNew}
                partnerList={partnerList}
                passthroughActivityTypeId={passthroughActivityTypeId}
                promoId={promoId}
                promoSetterMethod={promoSetterMethod}
                promoStats={promoStats}
                saveNewPromoActivity={saveNewPromoActivity}
                savingActivity={savingActivity}
                setNewPromoActivityAttr={setNewPromoActivityAttr}
                setPromoActivityAttr={setPromoActivityAttr}
                setPromoActivityType={setPromoActivityType}
                shortUrls={shortUrls}
                theBrand={theBrand}
                updatePromoActivity={updatePromoActivity}
            />
            <PromoSidebar
                activities={activities}
                blockchain={blockchain}
                blockchainIds={blockchainIds}
                brandList={brandList}
                isClone={isClone}
                isNew={isNew}
                clonePromo={clonePromo}
                coinType={coinType}
                deletePromo={deletePromo}
                effectivePromo={effectivePromo}
                extTicketUrl={extTicketUrl}
                fetchBrandBalanceAndAllowance={fetchBrandBalanceAndAllowance}
                partnerList={partnerList}
                previewConfig={{
                    previewId: previewHtml.previewId,
                }}
                promoId={promoId}
                promoSetterMethod={promoSetterMethod}
                redirect={redirect}
                saveClonedPromo={saveClonedPromo}
                saveNewPromo={saveNewPromo}
                shortUrls={shortUrls}
                showPreview={previewing === false && typeof previewHtml.previewId === 'number'}
                theBrand={theBrand}
                updatePromo={updatePromo}
                updatePromoActivity={updatePromoActivity}
            />
        </Row>
    </div>);
};

const mapStateToProps = ({ blockchain, brand, preview, partner, promotion, promoActivity, promoStats, activityType, router, shortUrls, }) => ({
    activities: promoActivity.list,
    savingActivity: promoActivity.creating || promoActivity.updating,
    newActivity: promoActivity.newInstance,
    activityTypeList: activityType.list,
    blockchain,
    brandList: brand.list,
    errors: promotion.errors,
    previewing: preview.previewing,
    previewHtml: preview.html,
    newPromoInstance: promotion.newInstance,
    partnerList: partner.list,
    promoList: promotion.list,
    promoStatsList: promoStats.list,
    router,
    shortUrls,
});

const mapDispatchToProps = dispatch => ({
    // blockchain
    fetchBrandBalanceAndAllowance: (brandId, coinType) => dispatch(fetchBrandBalanceAndAllowance({brandId, coinType})),

    // promo activity
    createNewPromoActivity: ({promoId, activityTypeId}) => dispatch(promoActivityActions.createNewPromoActivity({promoId, activityTypeId})),
    deletePromoActivity: (id, sync=false) => dispatch(promoActivityActions.deleteAction({id, sync})),
    deleteNewPromoActivity: (activityId) => dispatch(promoActivityActions.deleteNewPromoActivity(activityId)),
    saveNewPromoActivity: (newModel) => dispatch(promoActivityActions.insertAction({ newModel, sync: true})),
    setNewPromoActivityAttr: (id, field, value, sync=false) => dispatch(promoActivityActions.insertAction({ newModel: {[field]: value}, sync})),
    setPromoActivityAttr: (id, field, value, sync=false) => dispatch(promoActivityActions.updateAction({id, updateModel: {[field]: value}, sync})),
    setPromoActivityType: (activityId, activityTypeId) => {
        console.log({
            activityId, activityTypeId
        })
        dispatch(promoActivityActions.setPromoActivityType(activityId, activityTypeId))
    },

    // promo stats
    fetchPromoStatsByPromoId: ({promoId}) => dispatch(fetchPromoStatsByPromoId({promoId})),

    // page
    initPromoDetailPage: (id) => dispatch(initPromoDetailPage({id})),
    saveClonedPromo: () => dispatch(saveClonedPromo()),

    // promo
    clonePromo: (id) => dispatch(promoActions.cloneAction(id)),
    deletePromo: (id) => dispatch(promoActions.deleteAction({id})),
    /* Push new model to the server */
    saveNewPromo: (newModel) => dispatch(promoActions.insertAction({ newModel, sync: true })),
    /* Set attr on new model */
    setNewPromoAttr: (id, field, value, sync=false) => dispatch(promoActions.insertAction({ newModel: {[field]: value}, sync})),
    /* Set existing model attr in redux, push to server if sync=true */
    setPromoAttr: (id, field, value, sync=false) => dispatch(promoActions.updateAction({id, updateModel: {[field]: value}, sync})),

    /* Save entire promo model */
    updatePromo: (updateModel) => dispatch(promoActions.updateAction({id: updateModel.id, updateModel, sync: true})),

    /* Save entire promo activity model */
    updatePromoActivity: (updateModel) => dispatch(promoActivityActions.updateAction({id: updateModel.id, updateModel, sync: true})),

    // url shortener
    fetchShortUrl : (urlToShorten) => dispatch(fetchShortUrl({urlToShorten})),

    // preview
    clearPreview: () => dispatch(clearPreview()),
    generatePreview: (activityId, promo, page, state) => dispatch(previewPromo({activityId, promo, page, state})),

    // router
    redirect: (path) => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PromoPage);
