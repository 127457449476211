import React, { memo } from 'react';
import { bool } from 'prop-types';
import GtSelect, { gtSelectPropTypes, gtSelectDefaultProps } from '../core/GtSelect';

/**
 * Typed coin-type selection so the user can't enter arbitrary values
 *
 * XXX Hardcoded coin type list; DB does not have a coin type table
 */
const StateSelect = ({
    noBr,
    value,
    ...gtSelectProps
}) => {
    
    //console.log('rendering StateSelect')
    return (
    <div className="d-flex state-select">
        <div>
            <GtSelect
                {...gtSelectProps}
                displayField="name"
                options={{
                    "AL": {
                        "id": 1,
                        "name": "Alabama"
                    },
                    "AK": {
                        "id": 2,
                        "name": "Alaska"
                    },
                    "AZ": {
                        "id": 3,
                        "name": "Arizona"
                    },
                    "AR": {
                        "id": 4,
                        "name": "Arkansas"
                    },
                    "CA": {
                        "id": 5,
                        "name": "California"
                    },
                    "CO": {
                        "id": 6,
                        "name": "Colorado"
                    },
                    "CT": {
                        "id": 7,
                        "name": "Connecticut"
                    },
                    "DE": {
                        "id": 8,
                        "name": "Delaware"
                    },
                    "FL": {
                        "id": 9,
                        "name": "Florida"
                    },
                    "GA": {
                        "id": 10,
                        "name": "Georgia"
                    },
                    "HI": {
                        "id": 11,
                        "name": "Hawaii"
                    },
                    "ID": {
                        "id": 12,
                        "name": "Idaho"
                    },
                    "IL": {
                        "id": 13,
                        "name": "Illinois"
                    },
                    "IN": {
                        "id": 14,
                        "name": "Indiana"
                    },
                    "IA": {
                        "id": 15,
                        "name": "Iowa"
                    },
                    "KS": {
                        "id": 16,
                        "name": "Kansas"
                    },
                    "KY": {
                        "id": 17,
                        "name": "Kentucky"
                    },
                    "LA": {
                        "id": 18,
                        "name": "Louisiana"
                    },
                    "ME": {
                        "id": 19,
                        "name": "Maine"
                    },
                    "MD": {
                        "id": 20,
                        "name": "Maryland"
                    },
                    "MA": {
                        "id": 21,
                        "name": "Massachusetts"
                    },
                    "MI": {
                        "id": 22,
                        "name": "Michigan"
                    },
                    "MN": {
                        "id": 23,
                        "name": "Minnesota"
                    },
                    "MS": {
                        "id": 24,
                        "name": "Mississippi"
                    },
                    "MO": {
                        "id": 25,
                        "name": "Missouri"
                    },
                    "MT": {
                        "id": 26,
                        "name": "Montana"
                    },
                    "NE": {
                        "id": 27,
                        "name": "Nebraska"
                    },
                    "NV": {
                        "id": 28,
                        "name": "Nevada"
                    },
                    "NH": {
                        "id": 29,
                        "name": "New Hampshire"
                    },
                    "NJ": {
                        "id": 30,
                        "name": "New Jersey"
                    },
                    "NM": {
                        "id": 31,
                        "name": "New Mexico"
                    },
                    "NY": {
                        "id": 32,
                        "name": "New York"
                    },
                    "NC": {
                        "id": 33,
                        "name": "North Carolina"
                    },
                    "ND": {
                        "id": 33,
                        "name": "North Dakota"
                    },
                    "OH": {
                        "id": 34,
                        "name": "Ohio"
                    },
                    "OK": {
                        "id": 35,
                        "name": "Oklahoma"
                    },
                    "OR": {
                        "id": 36,
                        "name": "Oregon"
                    },
                    "PA": {
                        "id": 37,
                        "name": "Pennsylvania"
                    },
                    "RI": {
                        "id": 38,
                        "name": "Rhode Island"
                    },
                    "SC": {
                        "id": 39,
                        "name": "South Carolina"
                    },
                    "SD": {
                        "id": 40,
                        "name": "South Dakota"
                    },
                    "TN": {
                        "id": 41,
                        "name": "Tennessee"
                    },
                    "TX": {
                        "id": 42,
                        "name": "Texas"
                    },
                    "UT": {
                        "id": 43,
                        "name": "Utah"
                    },
                    "VT": {
                        "id": 44,
                        "name": "Vermont"
                    },
                    "VA": {
                        "id": 45,
                        "name": "Virginia"
                    },
                    "WA": {
                        "id": 46,
                        "name": "Washington"
                    },
                    "WV": {
                        "id": 47,
                        "name": "West Virginia"
                    },
                    "WI": {
                        "id": 49,
                        "name": "Wisconsin"
                    },
                    "WY": {
                        "id": 50,
                        "name": "Wyoming"
                    },
                }}
                value={value}
            />
        {noBr === true ? null : <br/>}
        </div>
    </div>
);
};

const stateSelectPropTypes = {
    ...gtSelectPropTypes
};

delete stateSelectPropTypes.displayField;
delete stateSelectPropTypes.displayFieldOverride;

const stateSelectDefaultProps = {
    ...gtSelectDefaultProps
};

delete stateSelectDefaultProps.displayField;
delete stateSelectDefaultProps.displayFieldOverride;
delete stateSelectDefaultProps.defaultText;
delete stateSelectDefaultProps.options;

StateSelect.propTypes = {
    ...stateSelectPropTypes,
    noBr: bool,
};

StateSelect.defaultProps = {
    ...stateSelectDefaultProps,
    noBr: false,
    defaultText: "Choose a State",
};

//export default StateSelect;
export default memo(StateSelect, (prevProps, nextProps) => {
    const r = prevProps.className === nextProps.className &&
        prevProps.defaultText === nextProps.defaultText &&
        prevProps.id === nextProps.id &&
        prevProps.label === nextProps.label &&
        prevProps.multiple === nextProps.multiple &&
        prevProps.value === nextProps.value;
    return r;
});
