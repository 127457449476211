import createReducer from './crud';

const promoReducer = createReducer(
    'PROMO',
    (state, action) => ({
        PROMO_CLONE: () => {
            return {
                ...state,
                newInstance: {
                    ...state.list[action.promoId],
                    cryptCode      : '',
                    promoCode      : '',
                    id             : 'new',
                    name           : state.list[action.promoId].name + ' - CLONED',
                    passkitOfferId : '',
                },
            };
        },
        SAVE_CLONED_PROMO: () => {
            return {
                ...state,
                creating: true,
            };
        },
    }),
    { // new model
        id: 'new',
        coinType: 'usdc',
        name: '',
        redeemedCss: '',
        webshareTitle: '',
        webshareText: '',
        webshareUrl: '',
        brandId: 0,
        brandPartnerIds: [],
        enabled: false,
        expires: true,
        startDate: null,
        endDate: null,
        maxUses: '', // Set to empty string to require value when creating new promo
        partnerReward: '', // Set to empty string to require value when creating new promo
        gtFee: 0.0,
        referrerFee: 0.0,
    }
);

export default promoReducer;