import React from 'react';
import ConvertedPrice from 'components/ConvertedPrice';

const PerformanceStat = ({
    coinType,
    cryptoValue,
    title,
    usdValue,
}) => (
    <div className="col-md text-center d-flex flex-column justify-content-center bg-info text-white rounded m-4 p-2">
        <div><h4>{title}</h4></div>
        <ConvertedPrice
            coinType={coinType}
            cryptoValue={cryptoValue}
            usdValue={usdValue}
        />
    </div>
);

export default PerformanceStat;