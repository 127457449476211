import React, { useState } from 'react';
import { connect }  from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Row,
} from 'reactstrap';
import {
    BucketPathInput,
    CoinTypeSelect,
    GtDatePicker,
    RolloverInput,
    SwitchInput,
    UrlInput,
    WalletSelect,
    TextInput,
    StateSelect,
} from 'components/Form';
import { simulateSave } from 'actions/ui-comps';
import { Accordion, AccordionRow } from 'components/Bootstrap';
import { MultiEditor } from 'components/Editor';

const GtComponents = ({
    simulateSave,
}) => {
    const [textInput, setTextInput] = useState('');
    const [rqInput, setRqInput] = useState('');
    const [sampleDate, setSampleDate] = useState('');
    const [rolloverInput, setRolloverInput] = useState('');
    const [switchEnabled, setSwitchEnabled] = useState(false);
    const [bktPath, setBktPath] = useState('/brands/fetzerbrau/logo.png');
    const [url, setUrl] = useState('https://fetzerbrau.com');
    const [sampleErrMsg, setSampleErrMsg] = useState('');
    const [cssContent, setCssContent] = useState('<style>div { color: green; }</style>');
    const [htmlContent, setHtmlContent] = useState('<div>Initial HTML...</div>');
    const [selectedWallet, setSelectedWallet] = useState(0);
    const [selectedCoinType, setSelectedCoinType] = useState(0);
    const [selectedState, setSelectedState] = useState(0);
    /*
    const coinTypeList = {
        "1": {
            "id": 1,
            "name": "autx"
        },
        "2": {
            "id": 2,
            "name": "usdc"
        },
    };
    */
    const sampleWalletList = {
           "1":{
              "id":1,
              "address":"fetzerbrau.goldenticket.app",
              "gtAlias":null
           },
           "2":{
              "id":2,
              "address":"bww.goldenticket.app",
              "gtAlias":null
           },
           "3":{
              "id":3,
              "address":"0x21aE2B4d2DD86ea5c1188e31EB4Fc0290aA86e89",
              "gtAlias":"Code Talent Wallet"
           },
           "21":{
              "id":21,
              "address":"0x21aE2B4d2DD86ea5c1188e31EB4Fc0290aA86e89",
              "gtAlias":"Nate Dogg Wallet"
           },
           "22":{
              "id":22,
              "address":"moxune wallet change",
              "gtAlias":null
           },
           "23":{
              "id":23,
              "address":"henryswallet",
              "gtAlias":null
           },
           "24":{
              "id":24,
              "address":"enzoswallet",
              "gtAlias":null
           },
           "25":{
              "id":25,
              "address":"crestwallet",
              "gtAlias":null
           }
        };

    return (
        <div>
            <Row>
                <h1>Form Elements</h1>
            </Row>
            <Row className="pb-4">
                <Col>
                    <Row>
                        <TextInput
                            id="simple-text-input"
                            label="'simple' Text Input"
                            help="normal text input..."
                            onInput={setTextInput}
                            value={textInput}
                        />
                    </Row>
                    <Row>
                        <TextInput
                            id="rq-input"
                            label="Required Text Input"
                            help="Features a red border until text has been entered"
                            required
                            onInput={setRqInput}
                            value={rqInput}
                        />
                    </Row>
                    <Row>
                        <GtDatePicker
                            id="date-picker"
                            label="Required Date picker"
                            value={sampleDate}
                            onChange={setSampleDate}
                            required
                        />
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <UrlInput
                            id="url-input"
                            help="Enter a valid URL"
                            label="URL Component"
                            value={url}
                            onInput={setUrl}
                        />
                    </Row>
                    <Row>
                        <SwitchInput
                            color="dark"
                            enabled={switchEnabled}
                            help="Change a boolean value in the DB.."
                            label="Switch"
                            onClick={setSwitchEnabled}
                        />
                    </Row>
                    <Row>
                        <RolloverInput
                            id="fake"
                            help="Input that magically hides and shows..."
                            label="Rollover Input"
                            onSave={e => { setRolloverInput(e.target.value); }}
                            required
                            saveFieldValue="fake"
                            value={rolloverInput}
                        />
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <BucketPathInput
                        help="For now, please manually upload images via Cloud Console. Enter the path to the image within the bucket in the input below."
                        id="brand-logo"
                        label="Bucket Path Input"
                        onInput={setBktPath}
                        noBr
                        value={bktPath}
                    />
                </Col>
                <Col>
                    <Card>
                        <CardHeader>Simulate Error</CardHeader>
                        <CardBody>
                            <TextInput
                                id="simulate-error-input"
                                label="Error Message"
                                help="Enter a message to show in a global alert"
                                onInput={setSampleErrMsg}
                                value={sampleErrMsg}
                            />
                        </CardBody>
                        <CardFooter>
                            <Button
                                onClick={() => { simulateSave({willBeSuccessful: false, errorMessage: sampleErrMsg}); }}
                            >Save</Button>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <CoinTypeSelect
                        id="coin-type-select"
                        label="Coin Type Selector"
                        onChange={setSelectedCoinType}
                        value={selectedCoinType}
                    />
                </Col>
                <Col>
                    <StateSelect
                        id="state-select"
                        label="State Selector"
                        onChange={setSelectedState}
                        value={selectedState}
                    />
                </Col>
                <Col>
                    <WalletSelect
                        id="sample-wallets"
                        label="Wallet Selector"
                        onChange={setSelectedWallet}
                        options={sampleWalletList}
                        value={selectedWallet}
                    />
                </Col>
            </Row>
            <Row className="mt-4">
                <h1>Custom Bootstrap Components</h1>
            </Row>
            <Row>
                <Container>
                    <Row><h2>Accordion</h2></Row>
                    <Accordion>
                        <AccordionRow
                            title="First Row"
                        >
                            <div>first row content</div>
                        </AccordionRow>
                        <AccordionRow
                            title="Second Row"
                        >
                            <div>second row content</div>
                        </AccordionRow>
                        <AccordionRow
                            title="Third Row"
                        >
                            <div>third row content</div>
                        </AccordionRow>
                    </Accordion>
                </Container>
            </Row>
            <Row className="mt-4">
                <h1>Code Editors</h1>
            </Row>
            <MultiEditor
                css={cssContent}
                initiallyExpanded
                html={htmlContent}
                help="Edit CSS & HTML for a particular page on the frontend"
                name="Sample Multi-Editor"
                onCssChange={setCssContent}
                onHtmlChange={setHtmlContent}
                withoutPreview
            />
        </div>
    );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
    simulateSave: ({...args}) => dispatch(simulateSave({...args}))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GtComponents));
