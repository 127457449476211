import { PAGINATION_PER_PAGE } from 'config/config';
import {
    LIST_UNCOMPENSATED_TICKETS,
    LIST_UNCOMPENSATED_TICKETS_ERROR,
    LIST_UNCOMPENSATED_TICKETS_SUCCESS,
} from 'constants/actions/compensation';

export const initialState = {
    fetchingList: false,
    list: {},
    page: 1,
    perPage: PAGINATION_PER_PAGE,
    listErrors: [],
};

export default(state=initialState, action) => {
    switch(action.type) {
        case LIST_UNCOMPENSATED_TICKETS:
            return {
                ...state,
                fetchingList: true,
                listErrors: [],
                list: {},
            };
        case LIST_UNCOMPENSATED_TICKETS_ERROR:
            return {
                ...state,
                fetchingList: false,
                listErrors: action.error,
            };
        case LIST_UNCOMPENSATED_TICKETS_SUCCESS:
            return {
                ...state,
                fetchingList: false,
                list: action.list,
            };
        default:
            return state;
    }
};