import { put } from 'redux-saga/effects';

const getSimulator = async (timeout) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve();
        }, timeout);
    });
};

/**
 * Simulate a save event on a particular model.
 * You can mock out success and failure as well as the imaginary server time.
 */
export function *simulateSave({
    errorMessage='Something blew up',
    serverTimeMs=2000,
    willBeSuccessful=true,
}) {
    yield getSimulator(serverTimeMs);

    if(willBeSuccessful) {
        yield put({
            type: 'UPDATE_UI_COMPS_SUCCESS',
        });
    } else {
        yield put({
            type: 'UPDATE_UI_COMPS_ERROR',
            errors: [errorMessage],
        });
    }
}