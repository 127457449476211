import React, { memo } from 'react';
import { bool, func, string } from 'prop-types';
import moment from 'moment';
import { Label } from 'reactstrap';
import DatePicker from 'react-datepicker';

/**
 * Bootstrap embellishments around a third party date picker.
 */
const GtDatePicker = ({
    className,
    value,
    id,
    label,
    onChange,
    required,
}) => {
    const haveValue = typeof value === 'string' && value.length > 0;

    const getClassName = () =>
        required && !haveValue
            ? 'form-control is-invalid border border-danger text-danger'
            : 'form-control border border-dark';

    return (
    <div className={`d-flex flex-row ${className}`}>
        <Label className="m-0 align-self-center" for={id}>{label}</Label>
        <span className="ml-2" id={id}>
            <DatePicker
                className={getClassName()}
                onChange={value => onChange(moment(value).format('YYYY-MM-DD').toString())}
                placeholderText={!haveValue ? 'Click to enter date' : ''}
                selected={!haveValue ? null : moment(value).toDate()}
            />
        </span>
    </div>);
};

GtDatePicker.propTypes = {
    className: string,
    id: string.isRequired,
    label: string.isRequired,
    onChange: func.isRequired,
    required: bool,
    value: string,
};

GtDatePicker.defaultProps = {
    className: '',
    required: false,
    value: '',
};

//export default GtDatePicker;

// Ignore changes to callback functions when considering rerender
export default memo(GtDatePicker, (prevProps, nextProps) => {
    return prevProps.className === nextProps.className &&
        prevProps.id === nextProps.id &&
        prevProps.label === nextProps.label &&
        prevProps.required === nextProps.required &&
        prevProps.value === nextProps.value;
});