import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    FormGroup,
    Row,
} from 'reactstrap';
import { fetchLocationBalance } from 'actions/blockchain';
import { deleteAction, insertAction, updateAction } from 'actions/partner';
import { fetchShortUrl } from 'actions/short-urls';
import { PlusCircle } from 'react-feather';
import {
    BucketPathInput,
    TextInput,
    DeleteButton,
    SaveButton,
    UrlInput,
} from 'components/Form';
import haveValue from 'lib/have-value';
import { initPartnerDetailPage } from 'actions/pages/init';
import LocationPane from 'components/Location';
import * as locationActions from 'actions/location';
import { Accordion, AccordionRow } from 'components/Bootstrap';

const PartnerPage = ({
    errors,
    locationList,
    partnerList,

    // blockchain
    fetchLocationBalance,

    // location
    createNewLocation,
    deleteLocation,
    deleteNewLocation,
    newLocationInstance,
    setLocationAttr,
    saveNewLocation,
    savingLocation,
    setNewLocationAttr,
    updateLocation,

    // page
    initPartnerDetailPage,

    // partner
    deletePartner,
    setModelAttr,
    setNewAttr,
    saveNewModel,
    updatePartner,
    newPartnerInstance,

    // short urls
    fetchShortUrl,
    shortUrls,

    // router
    redirect,
    router,

    // wallet
    walletList,
}) => {
    // Grab the promo id from the URL
    let partnerId = router.location.pathname.split('/').pop();

    const isNew = partnerId === 'new';

    if(!isNew) {
        partnerId = Number.parseInt(partnerId, 10);
    }

    const setterMethod = isNew ? setNewAttr : setModelAttr;
    let effectiveModel = isNew ? newPartnerInstance : partnerList[partnerId];


    // Fetch the promotion detail on page load
    useEffect(() => {
        initPartnerDetailPage(partnerId);
    }, [isNew]);

    if(typeof effectiveModel === 'undefined' || effectiveModel === null) {
        return null;
    }

    // Sort the location keys so we can keep the 'new' one on top visually
    const locationKeys = Object.keys(locationList);
    const sortedKeys = [];
    locationKeys.forEach((k) => {
        if(k !== 'new') {
            sortedKeys.push(k);
        }
    });
    if(locationKeys.includes('new')) {
        sortedKeys.unshift('new');
    }

    return (
    <div className="detail-page">
        <h3 className="m-b">{isNew ? 'New' : 'Edit'} Location</h3>
        <Row>
            <Col md={isNew === true ? 8 : 10}>
                <Card className="shadow">
                    <CardBody>
                        <FormGroup>
                            <TextInput
                                id="partner-name"
                                label="Name"
                                onInput={value => { setterMethod(partnerId, 'name', value); }}
                                value={effectiveModel.name}
                            />
                            <TextInput
                                id="partner-contact-name"
                                label="Contact Name"
                                onInput={value => { setterMethod(partnerId, 'contactName', value); }}
                                value={effectiveModel.contactName}
                            />
                            <TextInput
                                id="partner-contact-email"
                                label="Contact Email"
                                onInput={value => { setterMethod(partnerId, 'contactEmail', value); }}
                                value={effectiveModel.contactEmail}
                            />
                            <BucketPathInput
                                help="For now, please manually upload images via Cloud Console. Enter the path to the image within the bucket in the input below."
                                id="partner-logo"
                                label="Logo Bucket Path"
                                onInput={value => { setterMethod(partnerId, 'logo', value); }}
                                noBr
                                value={effectiveModel.logo}
                            />
                            <UrlInput
                                id="partner-url"
                                onInput={value => { setterMethod(partnerId, 'url', value); }}
                                value={effectiveModel.url}
                            />
                        </FormGroup>
                    </CardBody>
                </Card>
                {!isNew ?
                <Card className="shadow">
                    <CardHeader>Physical Locations <Button color="success" className="float-right" disabled={newLocationInstance !== null} onClick={() => createNewLocation({partnerId})}><PlusCircle size={12} /> Add</Button></CardHeader>
                    <CardBody>
                        <Accordion>
                            {newLocationInstance === null ? null :
                            <AccordionRow title={newLocationInstance.address} key="new-location">
                                <LocationPane
                                    address={newLocationInstance.address}
                                    autxBalanceUsd={typeof newLocationInstance.autxBalanceUsd === 'undefined' ? 0.0 : newLocationInstance.autxBalanceUsd}
                                    balanceAutx={typeof newLocationInstance.balanceAutx === 'undefined' ? 0.0 : newLocationInstance.balanceAutx}
                                    balanceUsdc={typeof newLocationInstance.balanceUsdc === 'undefined' ? 0.0 : newLocationInstance.balanceUsdc}
                                    city={newLocationInstance.city}
                                    cryptCode={newLocationInstance.cryptCode}
                                    usdcBalanceUsd={typeof newLocationInstance.usdcBalanceUsd === 'undefined' ? 0.0 : newLocationInstance.usdcBalanceUsd}
                                    fetchLocationBalance={fetchLocationBalance}
                                    isVendingLocation={newLocationInstance.isVendingLocation}
                                    lat={newLocationInstance.lat}
                                    locationCode={newLocationInstance.locationCode}
                                    locationId={'new'}
                                    long={newLocationInstance.long}
                                    onDelete={deleteNewLocation}
                                    phone={newLocationInstance.phone}
                                    saveNewModel={() => saveNewLocation(newLocationInstance)}
                                    setterMethod={setNewLocationAttr}
                                    state={newLocationInstance.state}
                                    walletId={newLocationInstance.walletId}
                                    walletList={walletList}
                                    website={newLocationInstance.website}
                                    zip={newLocationInstance.zip}
                                    fetchShortUrl={fetchShortUrl}
                                    shortUrls={shortUrls}
                                    vendingMachineCode={newLocationInstance.vendingMachineCode}
                                />
                            </AccordionRow>}
                            {sortedKeys.map((locationId, index) => {
                            const theLocation = locationList[locationId];
                            const locationBelongsToPartner = typeof theLocation.parentId === 'number' && theLocation.parentId === partnerId;
                            return (
                            !locationBelongsToPartner ? null :
                            <AccordionRow title={theLocation.address} key={locationId}>
                                <LocationPane
                                    address={theLocation.address}
                                    autxBalanceUsd={typeof theLocation.autxBalanceUsd === 'undefined' ? 0.0 : theLocation.autxBalanceUsd}
                                    balanceAutx={typeof theLocation.balanceAutx === 'undefined' ? 0.0 : theLocation.balanceAutx}
                                    balanceUsdc={typeof theLocation.balanceUsdc === 'undefined' ? 0.0 : theLocation.balanceUsdc}
                                    city={theLocation.city}
                                    cryptCode={theLocation.cryptCode}
                                    usdcBalanceUsd={typeof theLocation.usdcBalanceUsd === 'undefined' ? 0.0 : theLocation.usdcBalanceUsd}
                                    fetchLocationBalance={fetchLocationBalance}
                                    isVendingLocation={theLocation.isVendingLocation}
                                    lat={theLocation.lat}
                                    locationCode={theLocation.locationCode}
                                    locationId={locationId}
                                    long={theLocation.long}
                                    onDelete={locationId === 'new' ? deleteNewLocation : deleteLocation}
                                    phone={theLocation.phone}
                                    saveNewModel={() => saveNewLocation(theLocation)}
                                    savingLocation={savingLocation}
                                    setterMethod={setLocationAttr}
                                    state={theLocation.state}
                                    updateLocation={() => updateLocation(theLocation)}
                                    walletId={theLocation.walletId}
                                    walletList={walletList}
                                    website={theLocation.website}
                                    zip={theLocation.zip}
                                    fetchShortUrl={fetchShortUrl}
                                    shortUrls={shortUrls}
                                    vendingMachineCode={theLocation.vendingMachineCode}
                                />
                            </AccordionRow>
                            )})}
                        </Accordion>
                    </CardBody>
                </Card> : null }
            </Col>
            {isNew === true ? <Col md={4}>
            <SaveButton
                disabled={
                    isNew && effectiveModel !== null && (
                    !haveValue(effectiveModel.name)        ||
                    !haveValue(effectiveModel.logo)        ||
                    !haveValue(effectiveModel.url)         ||
                    !haveValue(effectiveModel.contactName) ||
                    !haveValue(effectiveModel.contactEmail)
                )}
                onClick={() => { saveNewModel(effectiveModel) }}
            />
            </Col> : null}
            {!isNew ?
            <Col md={2}>
                <SaveButton
                    className="mb-2"
                    onClick={() => {
                        updatePartner(effectiveModel);
                        /* XXX Update all locations - seems gratuitous
                        Object.values(locationList).forEach(location => {
                            updateLocation(location);
                        });
                        */
                    }}
                />
                <DeleteButton
                    className="mb-2"
                    onClick={() => {deletePartner(partnerId); redirect('/partners');}}
                />
            </Col> : null}
        </Row>
    </div>);
};

const mapStateToProps = ({ dbLocation, partner, shortUrls, router, wallet }) => ({
    errors: partner.errors,
    locationList: dbLocation.list,
    newLocationInstance: dbLocation.newInstance,
    partnerList: partner.list,
    newPartnerInstance: partner.newInstance,
    router,
    savingLocation: dbLocation.creating || dbLocation.updating,
    shortUrls,
    walletList: wallet.list,
});

const mapDispatchToProps = dispatch => ({
    // location
    createNewLocation: ({partnerId}) => dispatch(locationActions.createNewLocation({partnerId})),
    deleteLocation: (id, sync=false) => dispatch(locationActions.deleteAction({id, sync})),
    deleteNewLocation: () => dispatch(locationActions.deleteNewLocation()),
    setLocationAttr: (id, field, value, sync=false) => dispatch(locationActions.updateAction({id, updateModel: {[field]: value}, sync})),
    saveNewLocation: (newModel) => dispatch(locationActions.insertAction({newModel, sync: true})),
    setNewLocationAttr: (id, field, value, sync=false) => dispatch(locationActions.insertAction({newModel: {[field]: value}, sync})),

    // blockchain
    fetchLocationBalance: (coinType, locationId) => dispatch(fetchLocationBalance({coinType, locationId})),

    // page
    initPartnerDetailPage: (id) => dispatch(initPartnerDetailPage({id})),

    // partner
    deletePartner: (id) => dispatch(deleteAction({id})),
    /* Set existing model attr in redux, push to server if sync=true */
    setModelAttr: (id, field, value, sync=false) => dispatch(updateAction({id, updateModel: {[field]: value}, sync})),
    /* Set attr on new model */
    setNewAttr: (id, field, value, sync=false) => dispatch(insertAction({ newModel: {[field]: value}, sync})),
    /* Push new model to the server */
    saveNewModel: (newModel) => dispatch(insertAction({ newModel, sync: true })),

    /* Save entire models */
    updatePartner: (updateModel) => dispatch(updateAction({id: updateModel.id, updateModel, sync: true})),
    updateLocation: (updateModel) => dispatch(locationActions.updateAction({id: updateModel.id, updateModel, sync: true})),

    // url shortener
    fetchShortUrl : (urlToShorten) => dispatch(fetchShortUrl({urlToShorten})),

    // router
    redirect: (path) => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnerPage);