import React, { useEffect } from 'react';
import { array, func, number, object, string } from 'prop-types';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    FormGroup,
    Row,
} from 'reactstrap';
import { TextInput, StateSelect, SwitchInput, WalletSelect } from 'components/Form';
import { Save, Trash } from 'react-feather';
import { APP_BASE_URL } from 'config/config';
import haveValue from 'lib/have-value';
import { ExternalLink } from 'components/Link';
import ConvertedPrice from 'components/ConvertedPrice';
import QrImg from 'components/QrImg';

const LocationPane = ({
    address,
    autxBalanceUsd,
    balanceAutx,
    balanceUsdc,
    city,
    cryptCode,
    usdcBalanceUsd,
    fetchLocationBalance,
    isVendingLocation,
    lat,
    locationCode,
    locationId,
    long,
    onDelete,
    phone,
    saveNewModel,
    savingLocation,
    setterMethod,
    state,
    updateLocation,
    vendingMachineCode,
    walletId,
    walletList,
    website,
    zip,
    shortUrls,
    fetchShortUrl,
}) => {
    const isNew = locationId === 'new';

    // Wrangle external URLs
    const extLocationUrl
        = cryptCode !== ''
        ? `${APP_BASE_URL}/location/${cryptCode}`
        : '';

    // Fetch the balance once we're loaded up
    useEffect(() => {
        if(!isNew) {
            fetchLocationBalance('autx', locationId);
            fetchLocationBalance('usdc', locationId);
        }
    }, [isNew]);

    useEffect(() => {
        if(typeof shortUrls.list[extLocationUrl] === 'undefined' && shortUrls.list[extLocationUrl] !== null && extLocationUrl !== '') {
            fetchShortUrl(extLocationUrl);
        }
    });

    const locId = name => `${name}-${locationId}`;

    return (
    <FormGroup tag="fieldset">
        {/* TODO Make these collapsible, so easier to manage a group...
        <legend className="h5 pb-1">XXX What to put here ??</legend>*/}
        <Row>
            <Col md={8}>
                <FormGroup className="pl-4">
                    <FormGroup>
                        {!isNew ? <div>Location ID: {locationId}</div> : null}
                        <SwitchInput
                            color="dark"
                            enabled={isVendingLocation == 1}
                            help="Integrates this Location with a Vending Machine"
                            label="Veding Location"
                            onClick={(enabled) => { setterMethod(locationId, 'isVendingLocation', enabled); }}
                        />
                        {isVendingLocation == 0 ? null :
                        <TextInput
                            id={locId('vending-machine-code')}
                            label="Vending Machine Code"
                            onInput={value => { setterMethod(locationId, 'vendingMachineCode', value); }}
                            value={vendingMachineCode}
                        />}
                        <TextInput
                            id={locId('location-address')}
                            label="Address"
                            onInput={value => { setterMethod(locationId, 'address', value); }}
                            value={address}
                        />
                        <TextInput
                            id={locId('location-city')}
                            label="City"
                            onInput={value => { setterMethod(locationId, 'city', value); }}
                            value={city}
                        />
                        <StateSelect
                            id={locId('location-state')}
                            label="State"
                            onChange={value => { setterMethod(locationId, 'state', value); }}
                            value={state}
                        />
                        <TextInput
                            id={locId('location-zip')}
                            label="Zip"
                            onInput={value => { setterMethod(locationId, 'zip', value); }}
                            value={zip}
                        />
                        <TextInput
                            id={locId('location-lat')}
                            label="Lat"
                            onInput={value => { setterMethod(locationId, 'lat', value); }}
                            value={lat}
                        />
                        <TextInput
                            id={locId('location-long')}
                            label="Long"
                            onInput={value => { setterMethod(locationId, 'long', value); }}
                            value={long}
                        />
                        <TextInput
                            id={locId('location-phone')}
                            label="Phone"
                            onInput={value => { setterMethod(locationId, 'phone', value); }}
                            value={phone}
                        />
                        <TextInput
                            id={locId('location-website')}
                            label="Website"
                            onInput={value => { setterMethod(locationId, 'website', value); }}
                            value={website}
                        />
                    </FormGroup>
                    <FormGroup>
                        <WalletSelect
                            id={locId('location-wallet-address')}
                            label="Wallet Selection"
                            onChange={value => { setterMethod(locationId, 'walletId', value); }}
                            options={walletList}
                            value={walletId}
                        />
                    </FormGroup>
                </FormGroup>
            </Col>
            {/* ------ SIDEBAR ------ */}
            <Col md={4}>
                <Button
                    className="mb-2"
                    color="primary"
                    disabled={
                        !haveValue(address)        ||
                        !haveValue(city)           ||
                        !haveValue(state)          ||
                        !haveValue(zip)            ||
                        !haveValue(walletId)       ||
                        savingLocation
                    }
                    onClick={() => {
                        if(isNew === true) {
                            saveNewModel();
                        } else {
                            updateLocation();
                        }
                    }}
                    style={{width: '100%'}}
                >
                    <Save size={12} /> Save</Button>
                <Button className="d-inline-block mb-4" color="danger" onClick={() => onDelete(locationId)} style={{width: '100%'}}>
                    <Trash size={12} /> Delete
                </Button>
                {isNew === false &&
                 typeof balanceAutx    !== 'undefined' &&
                 typeof balanceUsdc     !== 'undefined' &&
                 typeof autxBalanceUsd !== 'undefined' &&
                 typeof usdcBalanceUsd  !== 'undefined'
                 ?
                <Card className="shadow" style={{position: 'relative'}}>
                    <CardHeader>Reward Balance</CardHeader>
                    <CardBody>
                        <FormGroup>
                            <ConvertedPrice
                                coinType="autx"
                                cryptoValue={balanceAutx}
                                id="balance-autx"
                                justifyContent="justify-content-start"
                                usdValue={autxBalanceUsd}
                            />
                            <ConvertedPrice
                                coinType="usdc"
                                cryptoValue={balanceUsdc}
                                id="balance-usdc"
                                justifyContent="justify-content-start"
                                usdValue={usdcBalanceUsd}
                            />
                        </FormGroup>
                    </CardBody>
                </Card> : null}
                {isNew === false ?
                <Card className="shadow">
                    <CardHeader>
                        <ExternalLink
                            asText
                            className="mr-2"
                            href={cryptCode}
                            label="Location Code"
                        />
                    </CardHeader>
                    <CardBody>
                        <QrImg qrData={cryptCode} />
                    </CardBody>
                </Card> : null}
            </Col>
        </Row>
    </FormGroup>);
};

LocationPane.propTypes = {
    address: string,
    autxBalanceUsd: number,
    balanceAutx: number,
    balanceUsdc: number,
    city: string,
    cryptCode: string,
    usdcBalanceUsd: number,
    fetchLocationBalance: func.isRequired,
    fetchShortUrl: func.isRequired,
    lat: number,
    locationCode: string,
    locationId: string.isRequired,
    long: number,
    onDelete: func.isRequired,
    saveNewModel: func.isRequired,
    setterMethod: func.isRequired,
    shortUrls: object.isRequired,
    state: string,
    updateLocation: func.isRequired,
    walletId: string,
    walletList: array,
    zip: string,
};

LocationPane.defaultProps = {
    address: '',
    autxBalanceUsd: 0.0,
    balanceAutx: 0.0,
    balanceUsdc: 0.0,
    city: '',
    usdcBalanceUsd: 0.0,
    lat: '',
    long: '',
    state: '',
    walletId: '',
    walletList: [],
    zip: '',
};

export default LocationPane;