import React from 'react';
import { func, number, object, string } from 'prop-types';
import ActivityType from './ActivityType';
import { MultiEditor } from 'components/Editor';
import { loadActivityTypeParam } from 'lib/activity-type-helpers';

const InterimPage = ({
    activityId,
    activityParams,
    activitySetterMethod,
    globalBackgroundImage,
    generatePreview,
    ...activityTypeProps
}) => {
    const intermediateStepCss =
        loadActivityTypeParam(activityParams, 'intermediateStepCss');

    const intermediateStepHtml =
        loadActivityTypeParam(activityParams, 'intermediateStepHtml');

    return (
    <ActivityType
        activityId={parseInt(activityId, 10)}
        activitySetterMethod={activitySetterMethod}
        {...activityTypeProps}
    >
        <div className="pt-2">
            <MultiEditor
                css={intermediateStepCss}
                html={intermediateStepHtml}
                help="Custom page to appear after terms and before the activity"
                name="Page Content"
                onCssChange={value => {activitySetterMethod(activityId, 'activityParams', {intermediateStepCss: value})}}
                onHtmlChange={value => {activitySetterMethod(activityId, 'activityParams', {intermediateStepHtml: value})}}
                onPreview={(customContent) => {
                    generatePreview({
                        activityId,
                        page: 'module',
                    });
                }}
            />
        </div>
    </ActivityType>);
};

InterimPage.propTypes = {
    ...ActivityType.propTypes,
    activityId: number.isRequired,
    activityParams: object,
    activitySetterMethod: func.isRequired,
    globalBackgroundImage: string,
};

InterimPage.defaultProps = {
    ...ActivityType.defaultProps,
    activityParams: null,
    globalBackgroundImage: '',
};

InterimPage.dbName = 'interim page';

export default InterimPage;
