/* eslint-disable react/forbid-foreign-prop-types */
import React from 'react';
import GtButton, { gtButtonPropTypes, gtButtonDefaultProps } from '../core/GtButton';
import { Copy } from 'react-feather';

const CopyButton = ({
    ...gtButtonProps
}) => (
    <GtButton
        {...gtButtonProps}
        icon={Copy}
        label="Clone"
    />
);

const copyButtonPropTypes = {
    ...gtButtonPropTypes
};

delete copyButtonPropTypes.color;
delete copyButtonPropTypes.icon;
delete copyButtonPropTypes.label;

CopyButton.propTypes = {
    ...copyButtonPropTypes
};

CopyButton.defaultProps = {
    ...gtButtonDefaultProps
};

export default CopyButton;