import React from 'react';
import { array, bool, func, number, object, string } from 'prop-types';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    FormGroup,
    Label,
} from 'reactstrap';
import { PlusCircle } from 'react-feather';
import { Editor, MultiEditor } from 'components/Editor';
import {
    BucketPathInput,
    CoinTypeSelect,
    GtSelect,
    TextInput,
    GtButton,
} from 'components/Form';
import { ActivityList, ActivityPane } from './Activity';
import PromoPerformanceCard from './PromoPerformanceCard';
import {getCustomContent} from 'lib/custom-content';

const PromoMain = ({
    activities,
    activityKeys,
    activityTypeList,
    newActivity,
    createNewPromoActivity,
    updatePromoActivity,
    brandList,
    coinType,
    deleteNewPromoActivity,
    deletePromoActivity,
    effectivePromo,
    getBrandCryptoVal,
    isClone,
    isNew,
    partnerList,
    passthroughActivityTypeId,
    promoId,
    promoSetterMethod,
    promoStats,
    saveNewPromoActivity,
    savingActivity,
    shortUrls,
    generatePreview,
    setNewPromoActivityAttr,
    setPromoActivityAttr,
    setPromoActivityType,
    theBrand,
}) => {
    return (
    <Col md={8}>
        {promoStats === null || theBrand === null || typeof coinType !== 'string' ? null :
        <PromoPerformanceCard
            avgTicketSpendCrypto={promoStats.avgTicketSpendCrypto}
            avgTicketSpendUsd={promoStats.avgTicketSpendUsd}
            brandAllowanceCrypto={getBrandCryptoVal('allowanceCrypto')}
            brandAllowanceUsd={getBrandCryptoVal('allowanceUsd')}
            brandBalanceCrypto={getBrandCryptoVal('balanceCrypto')}
            brandBalanceUsd={getBrandCryptoVal('balanceUsd')}
            brandSpendCrypto={promoStats.brandSpendCrypto}
            brandSpendUsd={promoStats.brandSpendUsd}
            coinType={coinType}
            gtFeesCrypto={promoStats.gtFeesCrypto}
            gtFeesUsd={promoStats.gtFeesUsd}
            haveStats={promoStats !== null}
            locationRewardCrypto={promoStats.locationRewardCrypto}
            locationRewardUsd={promoStats.locationRewardUsd}
            maxUses={promoStats.maxUses}
            maxUsesExceeded={promoStats.maxUsesExceeded}
            numTicketsActivated={promoStats.numTicketsActivated}
            numTicketsCompensated={promoStats.numTicketsCompensated}
            numTicketsCompleted={promoStats.numTicketsCompleted}
            numTicketsCreated={promoStats.numTicketsCreated}
            numTicketsRedeemed={promoStats.numTicketsRedeemed}
            referrerRewardCrypto={promoStats.referrerRewardCrypto}
            referrerRewardUsd={promoStats.referrerRewardUsd}
        />}
        <Card className="shadow">
            <CardHeader>General Settings</CardHeader>
            <CardBody>
                <FormGroup>
                    {isNew ?
                    <div className="mb-3">
                        <CoinTypeSelect
                            id="promo-coin-type"
                            label="Coin Type"
                            onChange={value => { promoSetterMethod(promoId, 'coinType', value); }}
                            value={coinType}
                        />
                    </div> :
                    <div>
                        <Label for="coin-type-info">Coin Type</Label>
                        <p className="text-uppercase">{coinType}</p>
                    </div>}
                    <TextInput
                        id="promo-name"
                        label="Name"
                        onInput={value => { promoSetterMethod(promoId, 'name', value); }}
                        required
                        value={effectivePromo.name}
                    />
                    <TextInput
                        id="ticket-blurb"
                        help="Instruct the customer where they can go and what they can redeem"
                        label="Ticket Blurb"
                        onInput={value => { promoSetterMethod(promoId, 'ticketBlurb', value); }}
                        value={effectivePromo.ticketBlurb}
                    />
                    <TextInput
                        id="share-pitch"
                        help="Give the user a reason to share the promo"
                        label="Share Pitch"
                        onInput={value => { promoSetterMethod(promoId, 'sharePitch', value); }}
                        value={effectivePromo.sharePitch}
                    />
                    <TextInput
                        id="redemption-instructions"
                        help="Instruct the customer how to redeem"
                        label="Redemption Instructions"
                        onInput={value => { promoSetterMethod(promoId, 'redeemInstructions', value); }}
                        value={effectivePromo.redeemInstructions}
                    />
                    <BucketPathInput
                        id="global-background-image"
                        label="Global Background Image"
                        onInput={value => { promoSetterMethod(promoId, 'globalBackgroundImage', value); }}
                        value={effectivePromo.globalBackgroundImage}
                    />
                    <BucketPathInput
                        id="custom-share-image"
                        label="Custom Share Image"
                        onInput={value => { promoSetterMethod(promoId, 'customShareImage', value); }}
                        value={effectivePromo.customShareImage}
                    />
                </FormGroup>
                {Boolean(effectivePromo.termsRequired) === true ?
                <div>
                    <h4>Terms Page</h4>
                    <BucketPathInput
                        id="terms-background-image"
                        label="Terms Background Image"
                        onInput={value => { promoSetterMethod(promoId, 'termsBackgroundImage', value); }}
                        value={effectivePromo.termsBackgroundImage}
                    />
                    <MultiEditor
                        css={effectivePromo.termsCss}
                        html={effectivePromo.termsHtml}
                        help="Custom content for the terms page of the promotion (if terms are required)"
                        name="Terms Page Content"
                        onCssChange={value => promoSetterMethod(promoId, 'termsCss', value)}
                        onHtmlChange={value => promoSetterMethod(promoId, 'termsHtml', value) }
                        onPreview={(customContent) => {
                            generatePreview({
                                page: 'terms',
                            });
                        }}
                    />
                </div> : null}
                <div className="pt-2">
                    <h4>Unlocked Page</h4>
                    <BucketPathInput
                        id="complete-background-image"
                        label="Unlocked Background Image"
                        onInput={value => { promoSetterMethod(promoId, 'completeBackgroundImage', value); }}
                        value={effectivePromo.completeBackgroundImage}
                    />
                    <MultiEditor
                        css={effectivePromo.completeCss}
                        html={effectivePromo.completeHtml}
                        help="Custom content to appear once the promotion has been unlocked"
                        name="Unlocked Page Content"
                        onCssChange={value => promoSetterMethod(promoId, 'completeCss', value)}
                        onHtmlChange={value => promoSetterMethod(promoId, 'completeHtml', value) }
                        onPreview={(customContent) => {
                            generatePreview({
                                page: 'unlocked',
                            });
                        }}
                    />
                </div>
                <h4>Redeemed Pages</h4>
                <MultiEditor
                    css={effectivePromo.immediateRedeemedCss}
                    html={effectivePromo.immediateRedeemedHtml}
                    help="Content that displays immediatly after the user has completed an active promotion"
                    name="Immediately Redeemed Page Content"
                    onCssChange={value => promoSetterMethod(promoId, 'immediateRedeemedCss', value)}
                    onHtmlChange={value => promoSetterMethod(promoId, 'immediateRedeemedHtml', value) }
                    onPreview={(customContent) => {
                        generatePreview({
                            page: 'finished',
                            state: {
                                type: 'immediate',
                            }
                        });
                    }}
                />
                <MultiEditor
                    css={effectivePromo.redeemedCss}
                    html={effectivePromo.redeemedHtml}
                    help="Content that displays once the user has completed an active promotion and visits redeemed page after initial view of the page"
                    name="Already Redeemed Page Content"
                    onCssChange={value => promoSetterMethod(promoId, 'redeemedCss', value)}
                    onHtmlChange={value => promoSetterMethod(promoId, 'redeemedHtml', value) }
                    onPreview={(customContent) => {
                        generatePreview({
                            page: 'finished',
                            state: {
                                type: 'already',
                            }
                        });
                    }}
                />
                <h4>Info modal</h4>
                <Editor
                    mode="html"
                    onChange={value => {promoSetterMethod(promoId, 'infoHtml', value)}}
                    value={effectivePromo.infoHtml}
                />
                <h4>Map Modal</h4>
                <TextInput
                    id="centerpoint-lat"
                    label="Lat"
                    onInput={value => { promoSetterMethod(promoId, 'centerpointLat', value); }}
                    required
                    requirement={value => !Number.isNaN(parseFloat(value))}
                    value={typeof effectivePromo['centerpointLat'] === 'undefined' ? '' : effectivePromo.centerpointLat}
                    />
                <TextInput
                    id="centerpoint-long"
                    label="Long"
                    onInput={value => { promoSetterMethod(promoId, 'centerpointLong', value); }}
                    required
                    requirement={value => !Number.isNaN(parseFloat(value))}
                    value={typeof effectivePromo['centerpointLong'] === 'undefined' ? '' : effectivePromo.centerpointLong}
                />
                <TextInput
                    id="mapbox-zoom"
                    help="Higher numbers are zoomed in closer, 13 is a good starting value"
                    label="Mapbox Zoom"
                    onInput={value => { promoSetterMethod(promoId, 'mapboxZoom', value); }}
                    value={typeof effectivePromo['mapboxZoom'] === 'undefined' ? '' : effectivePromo.mapboxZoom}
                />
            </CardBody>
        </Card>
        {!isNew ?
        <Card className="shadow">
            <CardHeader>Communication Settings</CardHeader>
            <CardBody>
                <h4>Save for later</h4>
                <h5>Email</h5>
                <TextInput
                    caption={
                    getCustomContent({
                        content: effectivePromo.saveForLaterEmailTitle,
                        options: {
                            blurb: effectivePromo.blurb,
                            brandName: effectivePromo.brandName,
                            promoName: effectivePromo.name,
                            expiresOn: effectivePromo.expiresOn,
                            webshareUrl: typeof shortUrls.list[effectivePromo.webshareUrl] !== 'undefined' && shortUrls.list[effectivePromo.webshareUrl] !== null ? shortUrls.list[effectivePromo.webshareUrl] : effectivePromo.webshareUrl,
                        }})}
                    id="save-for-later-sms"
                    help={`Email title for the "Save for later" email. Supported promo vars: blurb, brandName, promoName, expiresOn, webshareUrl`}
                    label="Save for Later email subject"
                    onInput={value => { promoSetterMethod(promoId, 'saveForLaterEmailTitle', value); }}
                    value={effectivePromo.saveForLaterEmailTitle}
                />
                <MultiEditor
                    css={effectivePromo.saveForLaterEmailCss}
                    html={effectivePromo.saveForLaterEmailHtml}
                    help={`Email body for the "Save for later" email. Supported promo vars: blurb, brandName, promoName, expiresOn, webshareUrl`}
                    name="Save for Later email content"
                    onCssChange={value => promoSetterMethod(promoId, 'saveForLaterEmailCss', value)}
                    onHtmlChange={value => promoSetterMethod(promoId, 'saveForLaterEmailHtml', value) }
                    withoutPreview
                />
                <h5>Text Message (SMS)</h5>
                <TextInput
                    caption={
                    getCustomContent({
                        content: effectivePromo.saveForLaterSms,
                        options: {
                            blurb: effectivePromo.blurb,
                            brandName: effectivePromo.brandName,
                            promoName: effectivePromo.name,
                            expiresOn: effectivePromo.expiresOn,
                            webshareUrl: typeof shortUrls.list[effectivePromo.webshareUrl] !== 'undefined' && shortUrls.list[effectivePromo.webshareUrl] !== null ? shortUrls.list[effectivePromo.webshareUrl] : effectivePromo.webshareUrl,
                        }})}
                    help={`Text for the "Save for later" SMS. Supported promo vars: blurb, brandName, promoName, expiresOn, webshareUrl`}
                    id="save-for-later-sms" 
                    label="Save for Later SMS content"
                    onInput={value => { promoSetterMethod(promoId, 'saveForLaterSms', value); }}
                    value={effectivePromo.saveForLaterSms}
                />
                <h4>Web share</h4>
                <TextInput
                    caption={
                    getCustomContent({
                        content: effectivePromo.webshareTitle,
                        options: {
                            blurb: effectivePromo.blurb,
                            brandName: effectivePromo.brandName,
                            promoName: effectivePromo.name,
                            expiresOn: effectivePromo.expiresOn,
                            webshareUrl: typeof shortUrls.list[effectivePromo.webshareUrl] !== 'undefined' && shortUrls.list[effectivePromo.webshareUrl] !== null ? shortUrls.list[effectivePromo.webshareUrl] : effectivePromo.webshareUrl,
                        }})}
                    help={`Title text for the native Web Share dialog. Supported promo vars: blurb, brandName, promoName, expiresOn, webshareUrl`}
                    id="web-share-title"
                    label="Web share title"
                    onInput={value => { promoSetterMethod(promoId, 'webshareTitle', value); }}
                    value={effectivePromo.webshareTitle}
                />
                <TextInput
                    caption={
                    getCustomContent({
                        content: effectivePromo.webshareText,
                        options: {
                            blurb: effectivePromo.blurb,
                            brandName: effectivePromo.brandName,
                            promoName: effectivePromo.name,
                            expiresOn: effectivePromo.expiresOn,
                            webshareUrl: typeof shortUrls.list[effectivePromo.webshareUrl] !== 'undefined' && shortUrls.list[effectivePromo.webshareUrl] !== null ? shortUrls.list[effectivePromo.webshareUrl] : effectivePromo.webshareUrl,
                        }})}
                    help={`Body text for the native Web Share dialog. Supported promo vars: blurb, brandName, promoName, expiresOn, webshareUrl`}
                    id="web-share-text"
                    label="Web share text"
                    onInput={value => { promoSetterMethod(promoId, 'webshareText', value); }}
                    value={effectivePromo.webshareText}
                />
            </CardBody>
        </Card> : null}
        {!isNew ?
        <Card className="shadow">
            <CardHeader>Passkit Settings</CardHeader>
            <CardBody>
                <TextInput
                    id="passkit-offer-id" 
                    label="Offer Id"
                    onInput={value => { promoSetterMethod(promoId, 'passkitOfferId', value); }}
                    value={effectivePromo.passkitOfferId}
                />
            </CardBody>
        </Card>
        : null}
        {isNew === true ?
        <Card className="shadow">
            <CardHeader>Participants</CardHeader>
            <CardBody>
                <FormGroup>
                    <GtSelect
                        className="mb-3"
                        defaultText="Choose Brand"
                        displayField="name"
                        onChange={value => { promoSetterMethod(promoId, 'brandId', value); } }
                        id="brand-select"
                        label="Brand"
                        options={brandList}
                        value={effectivePromo.brandId}
                    />
                    <GtSelect
                        defaultText="Choose Location"
                        displayField="name"
                        onChange={(value) => {  promoSetterMethod(promoId, 'brandPartnerIds', value); }}
                        id="partner-select"
                        label="Location"
                        multiple
                        options={partnerList}
                        value={effectivePromo.brandPartnerIds}
                    />
                </FormGroup>
            </CardBody>
        </Card> : null}
        <Card className="shadow">
            <CardHeader>
                Activities
                <GtButton
                    color="success"
                    className="float-right"
                    disabled={newActivity !== null}
                    icon={PlusCircle}
                    iconSize={12}
                    label="Add"
                    onClick={() => createNewPromoActivity({promoId, activityTypeId: passthroughActivityTypeId})}
                    small
                />
            </CardHeader>
            <CardBody>
                {newActivity !== null ?
                <ActivityPane
                    activityId={newActivity.activityId}
                    activityParams={typeof newActivity.activityParams !== 'undefined' && newActivity !== null ? newActivity.activityParams : null}
                    activitySetterMethod={setNewPromoActivityAttr}
                    activityTypeId={newActivity.activityTypeId}
                    activityTypeList={activityTypeList}
                    enabled={Boolean(newActivity.enabled)}
                    frequency={Number.parseFloat(newActivity.activityFreq) === Number.isNaN() ? 0 : newActivity.activityFreq * 100}
                    globalBackgroundImage={effectivePromo.globalBackgroundImage}
                    isClone={isClone}
                    isNew
                    name={newActivity.name}
                    onDelete={deleteNewPromoActivity}
                    onSave={() => { saveNewPromoActivity(newActivity); }}
                    promoId={newActivity.promoId}
                    generatePreview={generatePreview}
                    savingActivity={savingActivity}
                    setPromoActivityType={setPromoActivityType}
                /> : null}
                {!isNew && newActivity !== null ?
                <hr style={{ borderColor: '#dcdcdc' }} />
                : null}
                <ActivityList
                    activities={activities}
                    activityKeys={activityKeys}
                    activityTypeList={activityTypeList}
                    onDelete={deletePromoActivity}
                    promotion={effectivePromo}
                    saveNewPromoActivity={saveNewPromoActivity}
                    savingActivity={savingActivity}
                    generatePreview={generatePreview}
                    setPromoActivityAttr={setPromoActivityAttr}
                    setPromoActivityType={setPromoActivityType}
                    updatePromoActivity={updatePromoActivity}
                />
            </CardBody>
        </Card>
    </Col>
);
};

PromoMain.propTypes = {
    activities: object,
    activityKeys: array,
    newActivity: object,
    createNewPromoActivity: func,
    brandList: object,
    coinType: string,
    deleteNewPromoAcivity: func,
    deletePromoActivity: func,
    effectivePromo: object,
    getBrandCryptoVal: func,
    isClone: bool,
    isNew: bool,
    partnerList: object,
    passthroughActivityTypeId: number,
    promoId: number,
    promoSetterMethod: func,
    promoStats: object,
    saveNewPromoActivity: func,
    savingActivity: bool,
    generatePreview: func,
    shortUrls: object,
    setNewPromoActivityAttr: func,
    setPromoActivityAttr: func,
    theBrand: object,
};

PromoMain.defaultProps = {
    isClone: false,
    newActivity: null,
};

export default PromoMain;