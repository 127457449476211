import React from 'react';
import { func } from 'prop-types';
import { LogOut } from 'react-feather';
import { GOOGLE_SIGNIN_CLIENT_ID  } from 'config/config';
import {
  DropdownItem,
} from 'reactstrap';
import { logoutUser } from 'actions/user';
import { connect }  from 'react-redux';

const LogoutLink = ({
  logoutUser,
}) => (
    <DropdownItem
      onClick={() => { logoutUser(); }}
    ><LogOut size={12} /> Logout</DropdownItem>
);

LogoutLink.propTypes = {
    logoutUser: func.isRequired,
};

LogoutLink.defaultProps = {
    
};

export default connect(
  ()=>{},
  dispatch => ({
    logoutUser: () => dispatch(logoutUser()),
  }))(LogoutLink);