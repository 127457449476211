import React, { useState } from 'react';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
} from 'reactstrap';
import ApproveAllowance, { approveAllowancePropTypes, approveAllowanceDefaultProps } from './ApproveAllowance';

/**
 * For scenarios where two approvals are required,
 * EG Our L1 DAI contract, or any of our L2 contracts.
 */
const ApproveAllowanceTwoStep = ({
    contractABI,
    onApprove,
    stepConfig,
    ...approveAllowanceProps
}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    
    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === stepConfig.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? stepConfig.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

  const slides = stepConfig.map(({ contractAddress, key, ignoreSameBalance, proxyAddress }) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={key}
      >
        <ApproveAllowance
            {...approveAllowanceProps}
            approvalType={key}
            contractAddress={contractAddress}
            contractABI={contractABI}
            ignoreSameBalance={ignoreSameBalance}
            onApprove={(success) => {
                // tell the carousel to advance to the next item...
                onApprove(success);
                next();
            }}
            proxyAddress={proxyAddress}
        />
      </CarouselItem>
    );
  });
  
    return (
    <Carousel
        activeIndex={activeIndex}
        className="two-step-approval-carousel"
        interval={false}
        next={next}
        previous={previous}
    >
        {slides}
        {/* NOTE: These are explicitly hidden in the CSS, because Reactstrap seems to require them... */}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>);
};


const approveAllowanceTwoStepPropTypes = approveAllowancePropTypes;

const approveAllowanceTwoStepDefaultProps = approveAllowanceDefaultProps;
delete approveAllowanceTwoStepDefaultProps.approvalType;
delete approveAllowanceTwoStepDefaultProps.contractAddress;
delete approveAllowanceTwoStepDefaultProps.contractABI;
delete approveAllowanceTwoStepDefaultProps.proxyAddress;

ApproveAllowanceTwoStep.propTypes = {
    ...approveAllowanceTwoStepPropTypes
};

ApproveAllowanceTwoStep.defaultProps = {
    ...approveAllowanceDefaultProps
};

export {
    approveAllowanceTwoStepPropTypes,
    approveAllowanceTwoStepDefaultProps,
};

export default ApproveAllowanceTwoStep;
