import {
    createFetchModelAction,
    createFetchModelListAction,
    createInsertModelAction,
    createUpdateModelAction,
    createDeleteModelAction,
    createSetNewModelAttrAction,
} from './crud';

import { PROMO_CLONE } from 'constants/actions/promo';

const fetchAction      = createFetchModelAction('PROMO');
const fetchListAction  = createFetchModelListAction('PROMO');
const insertAction     = createInsertModelAction('PROMO');
const updateAction     = createUpdateModelAction('PROMO');
const deleteAction     = createDeleteModelAction('PROMO');
const setNewAttrAction = createSetNewModelAttrAction('PROMO');
const cloneAction = (id, name) => ({
    type: PROMO_CLONE,
    promoId: id,
    promoName: name,
});

export {
    fetchAction,
    fetchListAction,
    insertAction,
    updateAction,
    deleteAction,
    setNewAttrAction,
    cloneAction,
};