import React from "react";
import { number, string, } from 'prop-types';
import Svg from './components/Svg';
import SvgLoader from './components/SvgLoader';

const Loader5 = ({x, y, width, height, color}) => (
    <SvgLoader>
        <Svg x={x} y={y} width={width} height={height} viewBox="0 0 50 50" enableBackground="new 0 0 50 50">
            <style jsx>{`
            rect {
                fill: ${color};
            }
            `}</style>
            <rect x="0" y="0" width="4" height="10" fill="#333">
                <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="translate"
                    values="0 0; 0 20; 0 0"
                    begin="0"
                    dur="0.6s"
                    repeatCount="indefinite" />
            </rect>
            <rect x="10" y="0" width="4" height="10" fill="#333">
                <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="translate"
                    values="0 0; 0 20; 0 0"
                    begin="0.2s"
                    dur="0.6s"
                    repeatCount="indefinite" />
            </rect>
            <rect x="20" y="0" width="4" height="10" fill="#333">
                <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="translate"
                    values="0 0; 0 20; 0 0"
                    begin="0.4s"
                    dur="0.6s"
                    repeatCount="indefinite" />
            </rect>
        </Svg>
    </SvgLoader>
);

Loader5.propTypes = {
    x: number,
    y: number,
    width: number,
    height: number,
    color: string,
};

Loader5.defaultProps = {
    x: 0,
    y: 0,
    width: 40,
    height: 40,
    color: 'black',
};

export default Loader5;