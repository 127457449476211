import React from 'react';
import { bool, func } from 'prop-types';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import invert from 'invert-color';
import * as colors from 'config/colors';

const ColorDropdown = ({
    dropdownOpen,
    onPickColor,
    toggle,
}) => {
    return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret>Color Picker</DropdownToggle>
        <DropdownMenu flip={false}>
        {Object.keys(colors).map((colorName) => {
            const color = colors[colorName];
            return (
            <DropdownItem key={colorName}>
                <div
                    className="p-2"
                    onClick={() => {
                        onPickColor(colorName, color);
                    }}
                    style={{
                        backgroundColor: color,
                        color: invert(color, true),
                        textAlign: 'center'
                    }}
                >{colorName}</div>
            </DropdownItem>
            );
        })}
        </DropdownMenu>
    </Dropdown>);
};

ColorDropdown.propTypes = {
    dropdownOpen: bool,
    onPickColor: func.isRequired,
    toggle: func.isRequired,
};

ColorDropdown.defaultProps = {
    dropdownOpen: false,
};

export default ColorDropdown;