import React from 'react';
import { func, number, object, string } from 'prop-types';
import {
    FormGroup,
} from 'reactstrap';
import { Eye } from 'react-feather';
import ActivityType from './ActivityType';
import { GtButton, TextInput, BucketPathInput } from 'components/Form';
import { loadActivityTypeParam } from 'lib/activity-type-helpers';

const Parallax = ({
    activityId,
    activityParams,
    activitySetterMethod,
    globalBackgroundImage,
    generatePreview,
    ...activityTypeProps
}) => {
    
    const backgroundColor = loadActivityTypeParam(activityParams, 'backgroundColor');
    const color = loadActivityTypeParam(activityParams, 'color');
    const spacerImageUrl = loadActivityTypeParam(activityParams, 'spacerImageUrl');
    const section1ImageUrl = loadActivityTypeParam(activityParams, 'section1ImageUrl');
    const section1Title = loadActivityTypeParam(activityParams, 'section1Title');
    const section1Subtitle = loadActivityTypeParam(activityParams, 'section1Subtitle');
    const section2ImageUrl = loadActivityTypeParam(activityParams, 'section2ImageUrl');
    const section2Title = loadActivityTypeParam(activityParams, 'section2Title');
    const section2Subtitle = loadActivityTypeParam(activityParams, 'section2Subtitle');
    const section3ImageUrl = loadActivityTypeParam(activityParams, 'section3ImageUrl');
    const section3Title = loadActivityTypeParam(activityParams, 'section3Title');
    const section3Subtitle = loadActivityTypeParam(activityParams, 'section3Subtitle');
    const section4ImageUrl = loadActivityTypeParam(activityParams, 'section4ImageUrl');
    
    return (
    <ActivityType
        activityId={parseInt(activityId, 10)}
        activitySetterMethod={activitySetterMethod}
        {...activityTypeProps}
    >
        <div className="pt-2">
            <GtButton
                color="info"
                className="float-"
                disabled={false}
                icon={Eye}
                iconSize={12}
                label="Preview"
                onClick={() => generatePreview({activityId, page: 'module'})}
                small
            />
        </div>
        <FormGroup tag="fieldset">
            <TextInput
                id="background-color"
                help="The background color on the page of the interim activity"
                label="Background Color"
                onInput={value => { activitySetterMethod(activityId, 'activityParams', {backgroundColor: value}); }}
                value={backgroundColor}
            />
            <TextInput
                id="color"
                help="The color on the page of the interim activity"
                label="Color"
                onInput={value => { activitySetterMethod(activityId, 'activityParams', {color: value}); }}
                value={color}
            />
            <BucketPathInput
                id="spacerImageUrl"
                help="The spacer image URL on the page of the interim activity"
                label="Spacer Image URL"
                onInput={value => { activitySetterMethod(activityId, 'activityParams', {spacerImageUrl: value}); }}
                value={spacerImageUrl}
            />
            <BucketPathInput
                id="section1ImageUrl"
                help="The section 1 image URL on the page of the interim activity"
                label="Section 1 Image URL"
                onInput={value => { activitySetterMethod(activityId, 'activityParams', {section1ImageUrl: value}); }}
                value={section1ImageUrl}
            />
            <TextInput
                id="section1Title"
                help="The section 1 title on the page of the interim activity"
                label="Section 1 title"
                onInput={value => { activitySetterMethod(activityId, 'activityParams', {section1Title: value}); }}
                value={section1Title}
            />
            <TextInput
                id="section1Subtitle"
                help="The section 1 sub title on the page of the interim activity"
                label="Section 1 sub title"
                onInput={value => { activitySetterMethod(activityId, 'activityParams', {section1Subtitle: value}); }}
                value={section1Subtitle}
            />
            <BucketPathInput
                id="section2ImageUrl"
                help="The section 2 image URL on the page of the interim activity"
                label="Section 2 Image URL"
                onInput={value => { activitySetterMethod(activityId, 'activityParams', {section2ImageUrl: value}); }}
                value={section2ImageUrl}
            />
            <TextInput
                id="section2Title"
                help="The section 2 title on the page of the interim activity"
                label="Section 2 title"
                onInput={value => { activitySetterMethod(activityId, 'activityParams', {section2Title: value}); }}
                value={section2Title}
            />
            <TextInput
                id="section2Subtitle"
                help="The section 2 sub title on the page of the interim activity"
                label="Section 2 sub title"
                onInput={value => { activitySetterMethod(activityId, 'activityParams', {section2Subtitle: value}); }}
                value={section2Subtitle}
            />
            <BucketPathInput
                id="section3ImageUrl"
                help="The section 3 image URL on the page of the interim activity"
                label="Section 3 Image URL"
                onInput={value => { activitySetterMethod(activityId, 'activityParams', {section3ImageUrl: value}); }}
                value={section3ImageUrl}
            />
            <TextInput
                id="section3Title"
                help="The section 3 title on the page of the interim activity"
                label="Section 3 title"
                onInput={value => { activitySetterMethod(activityId, 'activityParams', {section3Title: value}); }}
                value={section3Title}
            />
            <TextInput
                id="section3Subtitle"
                help="The section 3 sub title on the page of the interim activity"
                label="Section 3 sub title"
                onInput={value => { activitySetterMethod(activityId, 'activityParams', {section3Subtitle: value}); }}
                value={section3Subtitle}
            />
            <BucketPathInput
                id="section4ImageUrl"
                help="The section 4 image URL on the page of the interim activity"
                label="Section 4 Image URL"
                onInput={value => { activitySetterMethod(activityId, 'activityParams', {section4ImageUrl: value}); }}
                value={section4ImageUrl}
            />
        </FormGroup>
    </ActivityType>);
};

Parallax.propTypes = {
    ...ActivityType.defaultProps,
    activityId: number.isRequired,
    activityParams: object,
    activitySetterMethod: func.isRequired,
    globalBackgroundImage: string,
};

Parallax.defaultProps = {
    ...ActivityType.propTypes,
    activityParams: null,
    globalBackgroundImage: '',
};

Parallax.dbName = 'parallax';

export default Parallax;
