/* eslint-disable react/forbid-foreign-prop-types */
import React from 'react';
import GtButton, { gtButtonPropTypes, gtButtonDefaultProps } from '../core/GtButton';
import { Save } from 'react-feather';

const SaveButton = ({
    ...gtButtonProps
}) => (
    <GtButton
        {...gtButtonProps}
        icon={Save}
        label="Save"
    />
);

const saveButtonPropTypes = {
    ...gtButtonPropTypes
};

delete saveButtonPropTypes.color;
delete saveButtonPropTypes.icon;
delete saveButtonPropTypes.label;

SaveButton.propTypes = {
    ...saveButtonPropTypes
};

SaveButton.defaultProps = {
    ...gtButtonDefaultProps
};

export default SaveButton;