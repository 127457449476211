import { takeEvery, takeLatest, all } from 'redux-saga/effects';

import { LOGIN_USER, LOGOUT_USER } from 'constants/actions/user';
import { loginUser, logoutUser } from './atomic/user';

import { fetchDetail, fetchList, syncModelToServer, deleteRemoteModel } from './atomic/crud';
import {
    INIT_PROMO_DETAIL_PAGE,
    INIT_PARTNER_DETAIL_PAGE,
    INIT_BRAND_DETAIL_PAGE,
} from 'constants/actions/pages';
import {
    initBrandListPage,
    initCompensationListPage,
    initPartnerListPage,
    initPromoListPage,
    initBrandDetailPage,
    initPartnerDetailPage,
    initPromoDetailPage,
    initReferrerListPage,
    initReferrerDetailPage,
    initWalletListPage,
    initWalletDetailPage,
    saveClonedPromo,
} from './pages';

import {
    FETCH_PROMO_ACTIVITIES_BY_PROMO_CODE,
} from 'constants/actions/promo-activity';
import {
    fetchPromoActivitiesByPromoCode
} from './atomic/promo-activity';

import {
    FETCH_PROMO_STATS_BY_PROMO_ID,
} from 'constants/actions/promo-stats';
import {
    fetchPromoStatsByPromoId,
} from './atomic/promo-stats';

import {
    FETCH_SHORT_URL,
} from 'constants/actions/short-urls';
import {
    fetchShortUrl
} from './atomic/short-urls';

import {
    fetchCryptoToUsdRate,
    fetchBrandBalanceAndAllowance,
    fetchLocationBalance,
    fetchUsdToCryptoRate,
} from './atomic/blockchain';

import {
    simulateSave,
} from './atomic/ui-comps';

/* XXX Server side clone no longer used
import {
    PROMO_CLONE,
} from 'constants/actions/promo';
import { clonePromo } from './atomic/promo';
*/

import {
    LIST_UNCOMPENSATED_TICKETS,
    REQUEUE_TICKET,
} from 'constants/actions/compensation';

import {
    listUncompensatedTickets,
    requeueTicket,
} from './atomic/compensation';
import {
    REQUEST_PREVIEW
} from 'constants/actions/preview';
import {
    generatePreview
} from './atomic/preview';

export default function *rootSaga() {
    yield all([
        // compensation ----------------------------------
        takeLatest(LIST_UNCOMPENSATED_TICKETS, listUncompensatedTickets),
        // login ----------------------------------
        takeLatest(LOGIN_USER, loginUser),
        takeLatest(LOGOUT_USER, logoutUser),
        // crud -----------------------------------
        takeLatest('FETCH_ACTIVITY_TYPE_LIST', fetchList),
        takeLatest('FETCH_ACTIVITY', fetchDetail),
        takeLatest('FETCH_ACTIVITY_LIST', fetchList),
        takeLatest('CREATE_ACTIVITY', syncModelToServer),
        takeLatest('UPDATE_ACTIVITY', syncModelToServer),
        takeLatest('DELETE_ACTIVITY', deleteRemoteModel),
        takeLatest('FETCH_BRAND', fetchDetail),
        takeLatest('FETCH_BRAND_LIST', fetchList),
        takeLatest('CREATE_BRAND', syncModelToServer),
        takeLatest('UPDATE_BRAND', syncModelToServer),
        takeLatest('DELETE_BRAND', deleteRemoteModel),
        takeEvery('FETCH_BRAND_BALANCE_AND_ALLOWANCE', fetchBrandBalanceAndAllowance),
        takeLatest('FETCH_PARTNER', fetchDetail),
        takeLatest('FETCH_PARTNER_LIST', fetchList),
        takeLatest('CREATE_PARTNER', syncModelToServer),
        takeLatest('UPDATE_PARTNER', syncModelToServer),
        takeLatest('DELETE_PARTNER', deleteRemoteModel),
        takeLatest('FETCH_LOCATION_LIST', fetchList),
        takeLatest('CREATE_LOCATION', syncModelToServer),
        takeLatest('UPDATE_LOCATION', syncModelToServer),
        takeLatest('DELETE_LOCATION', deleteRemoteModel),
        takeEvery('FETCH_LOCATION_BALANCE', fetchLocationBalance),
        takeLatest('FETCH_PROMO', fetchDetail),
        takeLatest('FETCH_PROMO_LIST', fetchList),
        takeLatest('CREATE_PROMO', syncModelToServer),
        takeLatest('UPDATE_PROMO', syncModelToServer),
        takeLatest('DELETE_PROMO', deleteRemoteModel),
        takeLatest('FETCH_REFERRER', fetchDetail),
        takeLatest('FETCH_REFERRER_LIST', fetchList),
        takeLatest('CREATE_REFERRER', syncModelToServer),
        takeLatest('UPDATE_REFERRER', syncModelToServer),
        takeLatest('DELETE_REFERRER', deleteRemoteModel),
        takeLatest('FETCH_REFERRER_PROMO', fetchDetail),
        takeLatest('FETCH_REFERRER_PROMO_LIST', fetchList),
        takeLatest('CREATE_REFERRER_PROMO', syncModelToServer),
        takeLatest('UPDATE_REFERRER_PROMO', syncModelToServer),
        takeLatest('DELETE_REFERRER_PROMO', deleteRemoteModel),
        takeLatest('FETCH_WALLET', fetchDetail),
        takeLatest('FETCH_WALLET_LIST', fetchList),
        takeLatest('CREATE_WALLET', syncModelToServer),
        takeLatest('UPDATE_WALLET', syncModelToServer),
        takeLatest('DELETE_WALLET', deleteRemoteModel),
        // short urls -----------------------------------
        takeEvery(FETCH_SHORT_URL, fetchShortUrl),
        // preview -----------------------------------
        takeEvery(REQUEST_PREVIEW, generatePreview),
        // blockchain -----------------------------------
        takeEvery('FETCH_CRYPTO_TO_USD_RATE', fetchCryptoToUsdRate),
        takeEvery('FETCH_USD_TO_CRYPTO_RATE', fetchUsdToCryptoRate),
        // pages -----------------------------------
        takeLatest('INIT_BRAND_LIST_PAGE', initBrandListPage),
        takeLatest('INIT_COMPENSATION_LIST_PAGE', initCompensationListPage),
        takeLatest('INIT_PARTNER_LIST_PAGE', initPartnerListPage),
        takeLatest('INIT_PROMO_LIST_PAGE', initPromoListPage),
        takeLatest(INIT_BRAND_DETAIL_PAGE, initBrandDetailPage),
        takeLatest(INIT_PARTNER_DETAIL_PAGE, initPartnerDetailPage),
        takeLatest(INIT_PROMO_DETAIL_PAGE, initPromoDetailPage),
        takeLatest('INIT_REFERRER_DETAIL_PAGE', initReferrerDetailPage),
        takeLatest('INIT_REFERRER_LIST_PAGE', initReferrerListPage),
        takeLatest('INIT_WALLET_LIST_PAGE', initWalletListPage),
        takeLatest('INIT_WALLET_DETAIL_PAGE', initWalletDetailPage),
        takeLatest('SAVE_CLONED_PROMO', saveClonedPromo),
        // activity -----------------------------------
        takeLatest('CREATE_PROMO_ACTIVITY', syncModelToServer),
        takeLatest('UPDATE_PROMO_ACTIVITY', syncModelToServer),
        takeLatest('DELETE_PROMO_ACTIVITY', deleteRemoteModel),
        takeLatest(FETCH_PROMO_ACTIVITIES_BY_PROMO_CODE, fetchPromoActivitiesByPromoCode),
        takeLatest(FETCH_PROMO_STATS_BY_PROMO_ID, fetchPromoStatsByPromoId),
        // ui comps -----------------------------------
        takeLatest('UPDATE_UI_COMPS', simulateSave),
        // promotion -----------------------------------
        // XXX Server side clone no longer being used...
        // takeLatest(PROMO_CLONE, clonePromo),
        // queue -----------------------------------
        takeLatest(REQUEUE_TICKET, requeueTicket),
    ]);
}
