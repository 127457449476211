import { call, put } from 'redux-saga/effects';
import { fetchDetail } from '../atomic/crud';

export function *initWalletDetailPage({
    id=null,
}) {
    if(id === null) {
        return;
    }
    // if we have an id, then load up the brand
    if(id !== 'new') {
        // bail if the brand is already loaded
        // TODO - Smarter cache busting logic here..
        /*
        const brandList = yield select(state => state.brand.list);
        if(typeof brandList[id] === 'object' && brandList[id] !== null) {
            return;
        }
        */

        // @note We use call here instead of put on purpose
        //       If we use put we see the action happen in redux,
        //       but lose the ability to serialize the fetch and select
        yield call(fetchDetail, {
            errorAction: 'FETCH_WALLET_ERROR',
            id,
            model: 'WALLET',
            successAction: 'FETCH_WALLET_SUCCESS',
        });
    }
    // otherwise, if this is a new brand, stub it out in redux
    else {
        yield put({
            type: 'CREATE_NEW_WALLET',
        });
    }
}
