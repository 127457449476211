import React, { useEffect } from 'react';
import { connect }  from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import CrudListPage from 'components/Crud/CrudListPage';
import { initPromoListPage } from 'actions/pages/init';

const PromoListPage = ({
    fetchingList,
    list,
    page,
    partner,
    perPage,
    initPromoListPage,
}) => {
    useEffect(() => {
        initPromoListPage();
    }, []);

    return (
        <CrudListPage
            addNewPath="/promotion/new"
            title="Promotions"
            fields={{
                name: 'name',
                brandName: 'brand',
                locationNames: 'locations',
                startDate: 'start date',
                endDate: 'end date'
            }}
            formatter={(field, row) => {
                switch(field) {
                case 'name':
                    return <Link to={`/promotion/${row.id}`}>{row[field]}</Link>;
                case 'brandName':
                    return <Link to={`/brand/${row.brandId}`}>{row[field]}</Link>;
                case 'locationNames':
                    return typeof row.brandPartnerIds === 'string' && row.brandPartnerIds.length > 0 ?
                        row.brandPartnerIds.split(',').map((brandPartnerId) => (
                        typeof partner === 'object' && typeof partner.list === 'object' && typeof partner.list[brandPartnerId] === 'object' &&
                        typeof partner.list[brandPartnerId].name === 'string' ?
                            <div key={brandPartnerId}><Link to={`/partner/${brandPartnerId}`}>{partner.list[brandPartnerId].name}</Link></div> : null
                        )) : null;
                case 'startDate':
                    return <span>{moment(row[field]).format('M/D/YY h:mma')}</span>;
                case 'endDate':
                    return row[field] === null ? ' - ' : <span>{moment(row[field]).format('M/D/YY h:mma')}</span>;
                default:
                    return false;
                }
            }}
            rows={list}
        />
    );
};

const mapStateToProps = ({ partner, promotion }) => ({
    fetchingList: promotion.fetchingList,
    list: promotion.list,
    page: promotion.page,
    partner,
    perPage: promotion.perPage,
});

const mapDispatchToProps = dispatch => ({
    initPromoListPage: () => dispatch(initPromoListPage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PromoListPage);