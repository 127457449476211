/* eslint-disable no-mixed-operators */
import fetch from 'isomorphic-unfetch';
import {getSessionValue, getSessionData, deleteSession} from 'lib/session';

const doFetch = async (url, config={}) => {
    let res = {};
    let body = '';
    try {
        // Run the HTTP request
        res = await fetch(url, config);

        // Attempt to parse the response as JSON
        // Place the parsed data on the response object proper

        body       = await res.text();
        const data = JSON.parse(body);
        res.data   = data;
    }
    // Otherwise look for text in the body and put it into a
    // typical object structure on our end
    catch(e) {
        if(body.length > 0) {
            res.data = {
                message: body,
            };
        } else {
            res.data = {
                message: '',
            };
        }
    }

    return res;
};

/**
 * Wrap fetch such that the Authentication header is passed
 * every time when we have a logged in user.
 */
const wrappedFetch = async (url, config={}) => {
    if(typeof config === 'undefined') {
        config = {};
    }

    if(!('headers' in config)) {
        config.headers = {};
    }
 
    // Look for JWT in session cookie
    const sessionData = getSessionData();
    const jwt         = getSessionValue(sessionData, 'jwt');

    // Set the authorization header if we have a JWT
    if(jwt !== null) {
        config.headers['Authorization'] = `bearer ${jwt}`;
    }

    // Set standard headers:
    config.headers['Content-Type'] = 'application/json';
    config.headers.Accept          = 'application/json';

    let res = await doFetch(url, config);

    // If the session has expired, nuke the session cookie and
    // do a hard redirect to a server-rendered page which will result in purged Redux
    if(res.status === 401) {
        if(res.data.message.indexOf('jwt expired') >= 0 ||
           res.data.message.indexOf('UnauthorizedError:') >= 0
        ) {
            deleteSession();

            res = doFetch(url, config);
        }
        // XXX Support for case where server responds w/ 401, but response contains
        //     "No authorization token was found"
        //     Suppose we would redirect to the error page in that case ????
    }

    return res;
};

/**
 * Standard check for HTTP error response codes.
 * Support to allow arbitrary list of exceptional codes.
 */
const isStatusCodeAnError = (statusCode, exceptions=[]) => {
    const _statusCode = Number.parseInt(statusCode, 10);

    return Number.isNaN(_statusCode) ||
        _statusCode > 399 &&
        _statusCode < 600 &&
        !exceptions.includes(_statusCode);
};

export {
    isStatusCodeAnError,
    wrappedFetch,
};

export default wrappedFetch;