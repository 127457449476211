const haveValue = (value, zeroOk=false, negativeOk=false) => {
    if(typeof value === 'undefined' || value === null) {
        return false;
    }

    if(typeof value === 'number') {
        if(!zeroOk && !negativeOk && value > 0) {
            return true;
        }
    
        if(zeroOk === true && value == 0) {
            return true;
        }
    
        if(negativeOk === true && value < 0) {
            return true;
        }

        return value > 0;
    }
    
    return value.length > 0;
};

export default haveValue;