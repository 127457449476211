import React, { useEffect, useState } from 'react';
import { array, func, object } from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import moment from 'moment';
import {
    Footer,
    Header,
    PageAlert,
    PageContent,
    Page,
    SidebarNav,
} from 'vibe';
import nav from '_nav';
import routes from 'views';
import ContextProviders from 'vibe/components/utilities/ContextProviders';
import PageAlertContext from 'vibe/components/PageAlert/PageAlertContext';
import HeaderNav from './HeaderNav';
import { checkLoggedInUser } from 'lib/session';
import { PUBLIC_PAGES } from 'config/config';

const MOBILE_SIZE = 992;

const DashboardLayout = ({
    activityType,
    addHistory,
    brand,
    clearErrors,
    dbLocation,
    gtHistory,
    history,
    hydrateSessionUser,
    location,
    logoutUser,
    partner,
    promotion,
    promoActivity,
    promoStats,
    redirect,
    referrer,
    referrerPromo,
    theUser,
    uiComps,
    wallet,
}) => {
    let alertContext = null;

    const reducers = {
        activityType,
        brand,
        partner,
        dbLocation,
        promotion,
        promoActivity,
        promoStats,
        referrer,
        referrerPromo,
        uiComps,
        wallet,
    };

    if(process.env.NODE_ENV === 'production') {
        delete reducers.uiComps;
    }

    const [layoutConfig, setLayoutConfig] = useState({
        sidebarCollapsed: false,
        isMobile: false,
    });

    /**
     * Check each reducer for our standard error reporting structure, namely
     * - creatingErrors
     * - deletingErrors
     * - updatingErrors
     * Combine them all into a single string for any that are found.
     */
    const getErrors = () => {
        let result = '';
        Object.entries(reducers).forEach(([reducerName, reducer]) => {
            if(Array.isArray(reducer.creatingErrors) && reducer.creatingErrors.length > 0) {
                result += reducer.creatingErrors.join("\n");
            }
            if(Array.isArray(reducer.deletingErrors) && reducer.deletingErrors.length > 0) {
                result += reducer.deletingErrors.join("\n");
            }
            if(Array.isArray(reducer.updatingErrors) && reducer.updatingErrors.length > 0) {
                result += reducer.updatingErrors.join("\n");
            }
        });
        return result;
    };

    const hasErrors = () => {
        let result = false;
        Object.entries(reducers).forEach(([reducerName, reducer]) => {
            result = result ||  reducer.creatingErrors.length > 0 || reducer.deletingErrors.length > 0 || reducer.updatingErrors.length > 0;
        });
        return result;
    };

    const renderErrors = () => {
        if(alertContext !== null) {
            const errors = getErrors();
            if(alertContext.alert === null && hasErrors()) {
                    alertContext.setAlert(errors, 'danger', () => {
                    clearErrors();
                });
            }
        }
    };

    /* Utility left around for debugging if you need it
    const dumpErrors = () => {
        let result = {};
        Object.entries(reducers).forEach(([reducerName, reducer]) => {
            result[reducerName] = {
                creatingErrors: reducer.creatingErrors,
                updatingErrors: reducer.updatingErrors,
                deletingErrors: reducer.deletingErrors,
            };
        });
        return result;
    };
    */

    const handleResize = () => {
        if(window.innerWidth <= MOBILE_SIZE) {
            setLayoutConfig({
                sidebarCollapsed: false,
                isMobile: true
            });
        } else {
            setLayoutConfig({ isMobile: false });
        }
    };

    const toggleSideCollapse = () => setLayoutConfig({ sidebarCollapsed: !layoutConfig.sidebarCollapsed });

    const isSaving = () => {
        let result = false;
        Object.entries(reducers).forEach(([reducerName, reducer]) => {
            result = result ||  reducer.creating || reducer.deleting || reducer.updating;
        });
        return result;
    };

    // @note Original keydown and click event handlers which fired accessibility handlers has been omitted
    // componentDidMount
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        renderErrors();

        // Populate the gtHistory reducer as the user navigates the site
        history.listen((location, action) => {
            if(location.pathname.split('/').length === 3) {
                addHistory(location.pathname);
            }
        });

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // componentWillReceiveProps
    useEffect(() => {
        renderErrors();
    }, [...Object.values(reducers)]);

    // componentWillReceiveProps
    useEffect(() => {
        if(layoutConfig.isMobile) {
            toggleSideCollapse();
        }
    }, [location]);

    //-------------------------------------------------------------------------
    // Verify user is logged in if they are on a sensitive page..
    // Hard redirect to login page so google auth is loaded properly.
    //-------------------------------------------------------------------------
    const loggedInUser = checkLoggedInUser();
    if(loggedInUser === false && !PUBLIC_PAGES.includes(location.pathname)) {
        redirect('/login');
        return <div>Unauthorized</div>;
    } else if(theUser === null) {
        hydrateSessionUser(loggedInUser.profile);
    }

    // Determine user's initials
    const initials = theUser === null ? '' : theUser.firstName[0] + theUser.lastName[0];
    const year = moment().year();
    const sidebarCollapsedClass = layoutConfig.sidebarCollapsed ? 'side-menu-collapsed' : '';

    return (
        <ContextProviders>
            <PageAlertContext.Consumer>
                {context => {
                    if(alertContext === null) {
                        alertContext = context;
                    }
                    return (
                    <div className={`app ${sidebarCollapsedClass}`}>
                        <PageAlert />
                        <div className="app-body">
                            <SidebarNav
                                isSidebarCollapsed={layoutConfig.sidebarCollapsed}
                                nav={nav}
                                logo="https://images.goldenticket.app/assets/gt-logo.png"
                                logoText="Goldenticket Logo"
                                toggleSidebar={toggleSideCollapse}
                                MOBILE_SIZE={MOBILE_SIZE}
                            />
                            <Page>
                                <Header
                                    toggleSidebar={toggleSideCollapse}
                                    isSidebarCollapsed={layoutConfig.sidebarCollapsed}
                                    routes={routes}
                                    location={location}
                                >
                                    <HeaderNav
                                        brand={brand}
                                        gtHistory={gtHistory}
                                        initials={initials}
                                        logoutUser={logoutUser}
                                        partner={partner}
                                        promotion={promotion}
                                        referrer={referrer}
                                        saving={isSaving()}
                                        wallet={wallet}
                                    />
                                </Header>
                                <PageContent>
                                <Switch>
                                    {routes.map((page, key) => (
                                        <Route path={page.path} component={page.component} key={page.path} />
                                    ))}
                                    <Redirect from="/" to="/promotions" />
                                </Switch>
                                </PageContent>
                            </Page>
                        </div>
                        <Footer>
                            <span>{`Copyright © ${year} goldenticket`}</span>
                            <span></span>
                            <span className="ml-auto hidden-xs">
                                <img src="https://images.goldenticket.app/assets/gt-ticket-icon-tiny.png" alt="goldenticket" />
                            </span>
                        </Footer>
                    </div>
                )}}
            </PageAlertContext.Consumer>
        </ContextProviders>
    );
};

DashboardLayout.propTypes = {
    activityType: object.isRequired,
    brand: object.isRequired,
    clearErrors: func.isRequired,
    dbLocation: object.isRequired,
    gtHistory: array.isRequired,
    history: object.isRequired,
    hydrateSessionUser: func.isRequired,
    location: object.isRequired,
    logoutUser: func.isRequired,
    partner: object.isRequired,
    promotion: object.isRequired,
    promoActivity: object.isRequired,
    promoStats: object.isRequired,
    redirect: func.isRequired,
    referrer: object.isRequired,
    referrerPromo: object.isRequired,
    theUser: object,
    uiComps: object.isRequired,
    wallet: object.isRequired,
};

DashboardLayout.defaultProps = {
    theUser: null,
};

export default DashboardLayout;