import createReducer from './crud';

const newModel = {
    referrerId: 0,
    promoId: 0,
};

const referrerPromoReducer = createReducer(
    'REFERRER_PROMO',
    (state, action) => ({
        'ADD_REFERRER_PROMO': () => {
            return {
                ...state,
                list: {
                    ...state.list,
                    'new': {
                        ...Object.assign({}, newModel),
                        promotionId: action.promotionId,
                        referrerId: action.referrerId,
                    }
                },
            };
        },
        'DELETE_REFERRER_PROMO':  () => {
            let deleteList = {};
            let referrerPromoId = 0;
            for(referrerPromoId in state.list) {
                if(referrerPromoId === 'new') {
                    continue;
                }
                deleteList[referrerPromoId] = Object.assign({}, state.list[referrerPromoId]);
            }
            return {
                ...state,
                list: deleteList,
            };
        },
    })
);

export default referrerPromoReducer;