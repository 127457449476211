import { PAGINATION_PER_PAGE } from 'config/config';

const defaultState = {
    creating: false,
    creatingErrors: [],
    deleting: false,
    deletingErrors: [],
    fetching: false,
    fetchingList: false,
    fetchingErrors: [],
    list: {},
    listErrors: [],
    newInstance: null,
    page: 1,
    perPage: PAGINATION_PER_PAGE,
    updating: false,
    updatingErrors: [],
};

const createReducer = (
    model,
    getAdditionalActions=(state, action) => ({}),
    newModel={},
) => {
    const reducer = (state=defaultState, action) => {
        const additionalActions = getAdditionalActions(state, action);
        const actionProcessor = {
            CLEAR_ERRORS: () => ({
                ...state,
                creatingErrors: state.creating === false ? [] : state.creatingErrors,
                deletingErrors: state.deleting === false ? [] : state.deletingErrors,
                updatingErrors: state.updating === false ? [] : state.updatingErrors,
            }),
            [`FETCH_${model}`]: () => {
                return {
                    ...state,
                    fetching: true,
                    errors: [],
                };
            },
            [`FETCH_${model}_SUCCESS`]: () => {
                return {
                    ...state,
                    fetching: false,
                    list: {
                        ...state.list,
                        [action.item.id]: action.item,
                    },
                };
            },
            [`FETCH_${model}_ERROR`]: () => {
                return {
                    ...state,
                    fetching: false,
                    errors: action.errors,
                };
            },
            [`FETCH_${model}_LIST`]: () => {
                return {
                    ...state,
                    fetchingList: true,
                    list: {},
                    listErrors: [],
                    page: action.page,
                    perPage: action.perPage,
                };
            },
            [`FETCH_${model}_LIST_SUCCESS`]: () => {
                return {
                    ...state,
                    fetchingList: false,
                    list: action.list,
                };
            },
            [`FETCH_${model}_LIST_ERROR`]: () => {
                return {
                    ...state,
                    fetchingList: false,
                    listErrors: action.errors,
                };
            },
            // Remove 'newInstance' object
            [`DELETE_NEW_${model}`]: () => {
                return {
                    ...state,
                    newInstance: null,
                };
            },
            // Create a new model in the 'newInstance' object
            [`CREATE_NEW_${model}`]: () => {
                return {
                    ...state,
                    newInstance: {
                        ...Object.assign({}, newModel),
                        ...action.newModel // additional items for the new model
                    },
                };
            },
            // Update our client side new model
            [`CREATE_${model}`]: () => {
                // @note conditional logic for specific module is not ideal
                const newInstance =
                    model === 'PROMO_ACTIVITY'
                    ? {
                        ...state.newInstance,
                        ...action.newModel,
                        activityParams: {
                            ...state.newInstance.activityParams || {},
                            ...action.newModel.activityParams || {},
                        }
                    } : {
                        ...state.newInstance,
                        ...action.newModel,
                    };

                return {
                    ...state,
                    creating: action.sync === true ? true : state.creating,
                    newInstance,
                    creatingErrors: [],
                };
            },
            // Migrate the newly created model from the 'newList' to the 'list'
            [`CREATE_${model}_SUCCESS`]: () => {
                return {
                    ...state,
                    creating: false,
                    list: {
                        ...state.list,
                        [action.newModel.id]: action.newModel,
                    },
                    newInstance: null,
                };
            },
            [`CREATE_${model}_ERROR`]: () => {
                return {
                    ...state,
                    creating: false,
                    creatingErrors: action.errors,
                };
            },
            [`UPDATE_${model}`]: () => {
                // @note conditional logic for specific module is not ideal
                const updateModel =
                    model === 'PROMO_ACTIVITY'
                    ? {
                        ...state.list[action.id],
                        ...action.updateModel,
                        activityParams: {
                            ...state.list[action.id].activityParams || {},
                            ...action.updateModel.activityParams || {},
                        }
                    } : {
                        ...state.list[action.id],
                        ...action.updateModel,
                    };
                return {
                    ...state,
                    updating: action.sync === true ? true : state.updating,
                    list: {
                        ...state.list,
                        [action.id]: updateModel
                    },
                };
            },
            [`UPDATE_${model}_SUCCESS`]: () => {
                return {
                    ...state,
                    updating: false,
                };
            },
            [`UPDATE_${model}_ERROR`]: () => {
                return {
                    ...state,
                    updating: false,
                    updatingErrors: action.errors,
                };
            },
            [`DELETE_${model}`]: () => {
                const deleteModelList = {};
                let deleteModelListId = 0;
                for(deleteModelListId in state.list) {
                    if(String(deleteModelListId) === String(action.id)) {
                        continue;
                    }
                    deleteModelList[deleteModelListId] = state.list[deleteModelListId];
                }
                return {
                    ...state,
                    deleting: true,
                    deletingErrors: [],
                    list: deleteModelList,
                };
            },
            [`DELETE_${model}_SUCCESS`]: () => {
                return {
                    ...state,
                    deleting: false,
                };
            },
            [`DELETE_${model}_ERROR`]: () => {
                return {
                    ...state,
                    deleting: false,
                    deletingErrors: action.errors,
                };
            }
        };

        // Bail if the action doesn't have a 'type' field
        if(typeof action.type !== 'string') {
            return state;
        }

        // First check standard action processors for the action
        if(typeof actionProcessor[action.type] === 'function') {
            return actionProcessor[action.type](action);
        }
        // Fallback to additional action processors provided by the particular reducer
        else if(typeof additionalActions[action.type] === 'function') {
            return additionalActions[action.type](action);
        }

        return state;
    };

    return reducer;
};

export {
    createReducer,
    defaultState,
};

export default createReducer;