import ActivityType from './ActivityType';
import InterimPage from './InterimPage';
import Parallax from './Parallax';
import Passthrough from './Passthrough';
import Survey from './Survey';
import WebShare from './WebShare';

const interimPageModules = {
    [InterimPage.dbName]: InterimPage,
    [Parallax.dbName]: Parallax,
    [Passthrough.dbName]: Passthrough,
    [Survey.dbName]: Survey,
    [WebShare.dbName]: WebShare,
};

export {
    ActivityType,
    interimPageModules,
    InterimPage,
    Parallax,
    Passthrough,
    Survey,
    WebShare,
};