export const API_SERVER_URL          = 'https://admin.goldenticket.app/api/v1';
export const APP_BASE_URL            = 'https://promo.goldenticket.app';
export const AUTX_CONTRACT_ADDR      = '0x4418A4Ff5AC8097CB6A2C91598150a70a8DFb712';
export const AUTX_WRAPPER_ADDR       = '0x90d044E71Cd1c4cA6cea76874EbF9fa534c254C3'
export const GOERLI_CHAIN_ID         = 324;
export const COOKIE_NAME             = '__gt-admin-session';
export const USDC_CONTRACT_ADDR      = '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4';
export const USDC_WRAPPER_ADDR       = '0xCb217DC6c891F18D1BbD3d4384decd7D7f5796D6';
export const DOMAIN_NAME             = 'admin.goldenticket.app';
export const IMG_SERVER_URL          = 'https://images.goldenticket.app';
export const JWT_PUBLIC_KEY          = "-----BEGIN PUBLIC KEY-----\nMIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCs36FPpN0XLrFcEFBObaUrjiqM\ndZKbmMN6ADCqdgwok5M98DaGDM/PwXjwM1WSCaMbugs+Bmsp2U6dUikNE/KI+yGX\nwkruo61Wu6zoQFrGteGOcPXvSGJnY7J93TS4tTooo7KzTfjzg61qBQPayEfohg5W\n1k63d0IWPH6m9vGlWQIDAQAB\n-----END PUBLIC KEY-----\n";
export const GOOGLE_SIGNIN_CLIENT_ID = '363992274675-009jum67gamjh9jln86l9tb4q2c39hja.apps.googleusercontent.com';
export const MAPS_API_KEY            = 'AIzaSyBeqwEg7IZHBA3rp7RJEx9m3cdOrw0UbMg';
export const PAGINATION_PER_PAGE     = 100;
export const PROXY_ADDR              = '0xE6578Cf85Dbc9B4926d5495Ef8fEb3464aa2E769';
export const PUBLIC_PAGES            = [
    '/login',
];