export const fontColorDark = '#333333';
export const fontColorLight = '#666666';
export const fontColorWhite = '#ffffff';
export const whiteBackground = '#fafafa'; // Same as grey50
export const yellowBackground = '#fffbdd';
export const buttonGrey = '#ebebeb';
export const buttonBlue = '#0746b9';
export const buttonLtBlue = '#bed6ff';
export const offBlack = '#3c4858';
export const black = '#000000';
export const white = '#ffffff';
export const hrColor = '#dcdcdc';
export const colorDanger = '#e74c4b';
export const colorLight = '#fafafa';
export const editorBorder = '#dadada';
export const colorPrimary = '#127cd8';

/*
export const red = '#f21b3f';

export const pink = '#ef7674';

export const purple = '#a23b72';

export const deepPurple = '#673ab7';

export const indigo = '#3f51b5';

export const blue = '#2196f3';

export const lightBlue = '#03a9f4';

export const cyan = '#08bdbd';

export const teal = '#009688';

export const green = '#29bf12';

export const lightGreen = '#8bc34a';

export const lime = '#cddc39';

export const yellow = '#ffeb3b';

export const amber = '#ffc107';

export const orange = '#fb5012';

export const brown = '#795548';

export const grey50 = '#fafafa';
export const grey100 = '#f5f5f5';
export const grey200 = '#eee';
export const grey300 = '#e0e0e0';
export const grey400 = '#bdbdbd';
export const grey = '#9e9e9e';
export const grey600 = '#757575';
export const grey700 = '#616161';
export const grey800 = '#424242';

export const blueGrey = '#607d8b';
*/
export const grey900 = '#212121';

/* Gold colors
 * https://www.color-hex.com/color-palette/2799
 */
export const gold300 = '#ffdc73';
export const gold400 = '#ffcf40';
export const gold = '#ffbf00';
export const gold600 = '#a67c00';
export const gold700 = '#bf9b30';