import React, { memo } from 'react';
import TextInput from '../core/TextInput';
import { validateUrl } from 'lib/validate';
import { ExternalLink } from 'components/Link';

/**
 * Expands on TextInput with expectation that the path
 * will point to the path of an image in the GoldenTicket media bucket.
 */
const UrlInput = ({
    value,
    ...textInputProps
}) => {
    //console.log('rendering UrlInput')
    return (
    <TextInput
        {...textInputProps}
        requirement={validateUrl}
        value={value}
    >
    {validateUrl(value) === true ? <span><span> | </span><ExternalLink href={value} label="Go to URL" title={`Go to ${value}`} /></span> : null}
    </TextInput>);
};

UrlInput.propTypes = {
    ...TextInput.propTypes,
};

UrlInput.defaultProps = {
    ...TextInput.defaultProps,
    label: "URL",
};

// export default UrlInput;
// Ignore changes to callback functions when considering rerender
export default memo(UrlInput, (prevProps, nextProps) => {
    const r = prevProps.children === nextProps.children &&
        prevProps.help === nextProps.help &&
        prevProps.id === nextProps.id &&
        prevProps.label === nextProps.label &&
        prevProps.noBr === nextProps.noBr &&
        prevProps.required === nextProps.required &&
        prevProps.className === nextProps.className &&
        prevProps.value === nextProps.value;

    return r;
});