import React from "react";
import { number, string, } from 'prop-types';
import { offBlack } from 'config/colors';
import Svg from './components/Svg';
import SvgLoader from './components/SvgLoader';

const Loader8 = ({x, y, width, height, color}) => (
    <SvgLoader>
        <Svg x={x} y={y} width={width} height={height} viewBox="0 0 40 40" enableBackground="new 0 0 50 50">
            <style jsx>{`
            rect {
                fill: ${color};
            }
            `}</style>
            <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
                <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
            </rect>
            <rect x="8" y="10" width="4" height="10" fill="#333"  opacity="0.2">
                <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
            </rect>
            <rect x="16" y="10" width="4" height="10" fill="#333"  opacity="0.2">
                <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
            </rect>
        </Svg>
    </SvgLoader>
);

Loader8.propTypes = {
    x: number,
    y: number,
    width: number,
    height: number,
    color: string,
};

Loader8.defaultProps = {
    x: 0,
    y: 0,
    width: 40,
    height: 40,
    color: offBlack,
};

export default Loader8;