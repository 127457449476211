import createReducer from './crud';

const promoStatsReducer = createReducer(
    'PROMO_STATS',
    (state, action) => ({
        FETCH_PROMO_STATS_BY_PROMO_ID: () => {
            return {
                ...state,
                fetchingList: true,
                list: {},
                listErrors: [],
                page: action.page,
                perPage: action.perPage,
            };
        },
        FETCH_PROMO_STATS_BY_PROMO_ID_SUCCESS: () => {
            const promoStats = action.list.promotion;
            const promoId = action.list.promotion.id;
            delete promoStats.id;
            return {
                ...state,
                fetchingList: false,
                list: {
                    ...state.list,
                    [promoId]: {
                        ...promoStats,
                    }
                }
            };
        },
        FETCH_PROMO_STATS_BY_PROMO_ID_ERROR: () => {
            return {
                ...state,
                fetchingList: false,
                listErrors: action.errors,
            };
        },
    })
);

export default promoStatsReducer;
