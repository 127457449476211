const initialState = {
    history: [],
};

export default(state=initialState, action) => {
    switch(action.type) {
        case 'ADD_HISTORY':
            const lastPiece = Number(action.uri.split('/').pop());

            // Bail if we are not editing a particular object
            if(Number.isNaN(lastPiece)) {
                return state;
            }

            const newHistory = [];
            state.history.forEach((historyUri) => {
                if(historyUri !== action.uri) {
                    newHistory.unshift(historyUri);
                }
            });
            newHistory.unshift(action.uri);

            // Limit to the last 5
            newHistory.slice(0, 5);

            if(action.uri)
            return {
                history: [
                    ...newHistory
                ]
            };
            break;
        default:
            return state;
    }
};