import React from 'react';
import ReactDOM from 'react-dom';
import {createBrowserHistory} from "history";
import {createStore, applyMiddleware} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {routerMiddleware} from 'connected-react-router';
// import {logger} from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import createRootReducer from 'reducers';
import rootSaga from 'sagas';
import { AppContainer } from 'react-hot-loader';
// import * as serviceWorker from './registerServiceWorker';
import App from './App';

const history        = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const configureStore = (preloadedState={}) => {
    const store = createStore(
        createRootReducer(history),
        preloadedState,
        composeWithDevTools(
            applyMiddleware(
                sagaMiddleware,
                routerMiddleware(history), // for dispatching history actions
                //logger
            )
        )
    );

    if(module.hot) {
        module.hot.accept('./reducers', () => {
            const nextRootReducer = require('./reducers');
            store.replaceReducer(nextRootReducer);
        });
    }

    return store;
};

const store = configureStore();
sagaMiddleware.run(rootSaga);

const rootEl = document.getElementById('app');

// ReactDOM.render(<App />, document.getElementById('app'));
ReactDOM.render(
    <AppContainer>
    <App store={store} history={history} />
    </AppContainer>,
    rootEl
);

if(module.hot) {
    module.hot.accept('./App', () => {
        const NextApp = require('./App').default;
        ReactDOM.render(
            <AppContainer>
            <NextApp />
            </AppContainer>,
            rootEl
        );
    });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
