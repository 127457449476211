/* eslint-disable react/forbid-foreign-prop-types */
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import GtSelect, { gtSelectPropTypes, gtSelectDefaultProps } from '../core/GtSelect';
import { Edit } from 'react-feather';

/**
 * Show the standard drop down list.
 */
 /* Someday, we'll offer a client-side filter option...
const WalletSelectDisplayMode = ({
    
}) => {
    
};

WalletSelectDisplayMode.propTypes = {
    
};

WalletSelectDisplayMode.defaultProps = {
    
};

const WalletSelectFilterMode = ({
    
}) => {
    
};

WalletSelectFilterMode.propTypes = {
    
};

WalletSelectFilterMode.defaultProps = {
    
};
*/

/**
 * A select component with client-side search and an edit link
 * for convenient wallet selection.
 */
const WalletSelect = ({
    value,
    ...gtSelectProps
}) => {
//console.log('rendering WalletSelect')
    return (
    <div className="d-flex wallet-select">
        <div>
            <GtSelect
                {...gtSelectProps}
                displayFieldOverride={(id, wallet) => {
                    if(typeof wallet.gtAlias === 'string' && wallet.gtAlias !== null && wallet.gtAlias.length > 0) {
                        return 'gtAlias';
                    }
                    return 'address';
                }}
                value={value}
            />
        </div>
        {value === 0 ? null :
        <div className="pointer align-self-end ml-2 pb-2">
            <Link to={`/wallet/${value}`}><Edit size={18} /></Link>
        </div>}
    </div>
);
};

const walletSelectPropTypes = {
    ...gtSelectPropTypes
};

delete walletSelectPropTypes.displayField;
delete walletSelectPropTypes.displayFieldOverride;

const walletSelectDefaultProps = {
    ...gtSelectDefaultProps
};

delete walletSelectDefaultProps.displayFieldOverride;
delete walletSelectDefaultProps.defaultText;

WalletSelect.propTypes = {
    ...walletSelectPropTypes,
};

WalletSelect.defaultProps = {
    ...walletSelectDefaultProps,
    defaultText: "Choose a Wallet",
};

//export default WalletSelect;

export default memo(WalletSelect, (prevProps, nextProps) => {
    const r = prevProps.className === nextProps.className &&
        prevProps.defaultText === nextProps.defaultText &&
        prevProps.id === nextProps.id &&
        prevProps.label === nextProps.label &&
        prevProps.multiple === nextProps.multiple &&
        Object.keys(prevProps.options).join(',') === Object.keys(nextProps.options).join(',') &&
        prevProps.value === nextProps.value;
    return r;
});
