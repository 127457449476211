/*global btoa*/
import React, { useRef } from 'react';
import { object, string } from 'prop-types';
import {
    Card,
    CardBody,
    CardHeader,
} from 'reactstrap';
import { APP_BASE_URL } from 'config/config';
import { offBlack } from 'config/colors';
import { GtExternalLink } from 'components/Link';

/**
 * This hits the frontend app's API route to do the Next.js
 * preview voodo and redirect to the ticket page.
 */
const FrontendPreview = ({
    previewId,
}) => {
    const previewDiv = useRef(null);

    let url = `${APP_BASE_URL}/api/preview?secret=MY_SECRET_TOKEN&id=${previewId}`;

    return (
    <Card
        className="fronted-preview shadow"
        style={{
            position: 'sticky',
            top: '60px',
        }}
    >
        <CardHeader>Preview
            <GtExternalLink
                href={url}
                label="External Preview Link"
                shortUrl=""
                title="Direct link handy for DOM inspection"
            />
        </CardHeader>
        <CardBody className="p-0">
            <div ref={previewDiv} style={{
                border: `1px solid ${offBlack}`,
                borderRadius: '5px',
            }}>
                {previewId === '' ? null :<iframe
                    height={600}
                    src={url}
                    style={{
                        border: 0,
                        overflow: 'hidden',
                    }}
                    title="frontend preview"
                    width="100%"
                />}
            </div>
        </CardBody>
    </Card>);
};

FrontendPreview.propTypes = {
    previewId: string,
};

FrontendPreview.defaultProps = {
    previewId: '',
};

export default FrontendPreview;