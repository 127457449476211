import {
    createFetchModelAction,
    createFetchModelListAction,
    createInsertModelAction,
    createUpdateModelAction,
    createDeleteModelAction,
    createSetNewModelAttrAction,
} from './crud';

const fetchAction      = createFetchModelAction('BRAND');
const fetchListAction  = createFetchModelListAction('BRAND');
const insertAction     = createInsertModelAction('BRAND');
const updateAction     = createUpdateModelAction('BRAND');
const deleteAction     = createDeleteModelAction('BRAND');
const setNewAttrAction = createSetNewModelAttrAction('BRAND');

export {
    fetchAction,
    fetchListAction,
    insertAction,
    updateAction,
    deleteAction,
    setNewAttrAction,
};