import { put, select } from 'redux-saga/effects';
import { PAGINATION_PER_PAGE } from 'config/config';

export function *initReferrerDetailPage({
    id=null,
}) {
    if(id === null) {
        return;
    }

    const promoList = yield select(state => state.partner.list);

    if(Object.keys(promoList).length === 0) {
        yield put({
            type: 'FETCH_PROMO_LIST',
            successAction: 'FETCH_PROMO_LIST_SUCCESS',
            errorAction: 'FETCH_PROMO_LIST_ERROR',
            model: 'PROMO',
            page: 1,
            perPage: PAGINATION_PER_PAGE,
        });
    }

    const walletList = yield select(state => state.wallet.list);
    if(Object.keys(walletList).length === 0) {
        yield put({
            type: 'FETCH_WALLET_LIST',
            errorAction: 'FETCH_WALLET_LIST_ERROR',
            model: 'WALLET',
            page: 1,
            perPage: PAGINATION_PER_PAGE,
            successAction: 'FETCH_WALLET_LIST_SUCCESS',
        });
    }

    // if we have an id, then load up the referrer
    if(id !== 'new') {
        // bail if the referrer is already loaded
        const referrerList = yield select(state => state.referrer.list);
        if(typeof referrerList['id'] === 'object' && referrerList.id !== null) {
            return;
        }

        yield put({
            type: 'FETCH_REFERRER',
            errorAction: 'FETCH_REFERRER_ERROR',
            id,
            model: 'REFERRER',
            successAction: 'FETCH_REFERRER_SUCCESS',
        });

        yield put({
            type: 'FETCH_REFERRER_PROMO_LIST',
            errorAction: 'FETCH_REFERRER_PROMO_LIST_ERROR',
            model: 'REFERRER_PROMO',
            page: id, // XXX Abuse of the page field....
            perPage: PAGINATION_PER_PAGE,
            successAction: 'FETCH_REFERRER_PROMO_LIST_SUCCESS',
        });

        yield put({
            type: 'FETCH_REFERRER_BALANCE_AND_ALLOWANCE',
            id,
        });

        yield put({
            type: 'FETCH_USD_TO_AUTX_RATE',
            usd: 1.0,
        });
    }
    // otherwise, if this is a new referrer, stub it out in redux
    else {
        yield put({
            type: 'CREATE_NEW_REFERRER',
        });
    }
}
