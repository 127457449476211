/* eslint-disable react/forbid-foreign-prop-types */
import React from 'react';
import GtButton, { gtButtonPropTypes, gtButtonDefaultProps } from '../core/GtButton';
import { Trash } from 'react-feather';

const DeleteButton = ({
    ...gtButtonProps
}) => (
    <GtButton
        {...gtButtonProps}
        color="danger"
        icon={Trash}
        label="Delete"
    />
);

const deleteButtonPropTypes = {
    ...gtButtonPropTypes
};

delete deleteButtonPropTypes.color;
delete deleteButtonPropTypes.icon;
delete deleteButtonPropTypes.label;

DeleteButton.propTypes = {
    ...deleteButtonPropTypes,
};

DeleteButton.defaultProps = {
    ...gtButtonDefaultProps
};

export default DeleteButton;