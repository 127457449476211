import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import fetch from 'lib/fetch';
import {
    CLEAR_USER_ERROR,
    CLEAR_USER_MESSAGE,
//    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
//    LOGOUT_USER,
//    SET_USER_ATTR,
//    UPDATE_USER_FAILURE,
//    UPDATE_USER_SUCCESS,
} from 'constants/actions/user';

import Jwt from 'jsonwebtoken';
import {
    setSessionValue,
    getSessionData,
    deleteSession
} from 'lib/session';
import { API_SERVER_URL, JWT_PUBLIC_KEY } from 'config/config';
import { googleLogout } from '@react-oauth/google';

export const clearUserError = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_USER_ERROR,
        });
    };
};

export const clearUserMessage = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_USER_MESSAGE,
        });
    };
};

//-------------------------------------------
// TODO Determine actual argument we need to pass (something other than "login") w/ google auth
//-------------------------------------------
export function *loginUser({
    token,
}) {
    // Ensure there's no lingering session cookie before we try to log the user in
    if(getSessionData() !== null) {
        deleteSession();
    }

    try {
        const res = yield fetch(
            `${API_SERVER_URL}/admin/login/${token}`,
            {
                method: 'POST',
            }
        );

        if(res.status === 200) {
            const data = res.data;

            // It seems rarely, we don't have the JWT from the server, so let's at least put some
            // type of message up there to know that's what users are running into
            if(typeof data.jwt !== 'string') {
                yield put({
                    type: LOGIN_USER_FAILURE,
                    error: 'Login verification did not work as expected, please try the login page.'
                });
            }
            else {
                // XXX The publis key does not come from process.env....
                const token = Jwt.verify(
                    data.jwt, JWT_PUBLIC_KEY, {algorithms: ['RS256']});

                // Save the JWT in the session cookie
                setSessionValue('jwt', data.jwt);
                yield put({
                    type: LOGIN_USER_SUCCESS,
                    user: token.profile,
                });

                // Redirect the user to the promotions page
                yield put(push('/promotions'));
            }

        } else {
            yield put({
                type: LOGIN_USER_FAILURE,
                error: res.statusText,
            });
        }
    } catch(e) {
        yield put({
            type: LOGIN_USER_FAILURE,
            error: e.message || 'Unknown failure logging user in',
        });
    }
}

/**
 * When a user is logged out of the system, we'll need to clear the redux store.
 */
export function *logoutUser() {
    try {
        googleLogout();
        deleteSession();
        yield put(push('/login'));
    } catch(e) {
        console.warn(e);
    }
}