import React from 'react';
import { Link } from 'react-router-dom';
import {
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    UncontrolledDropdown,
} from 'reactstrap';
import { DollarSign, MapPin, PlusSquare, Rss, Tag, Users } from 'react-feather';

// XXX Some duplication with HeaderHistory...
const HeaderNew = () => (
    <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>
            <PlusSquare size={12}/> New
        </DropdownToggle>
        <DropdownMenu right>
            <DropdownItem>
                <Link to="/promotion/new">
                    <span className="mr-1"><Rss size={14} /></span>
                    <span>Promotion</span>
                </Link>
            </DropdownItem>
            <DropdownItem>
                <Link to="/brand/new">
                    <span className="mr-1"><Tag size={14} /></span>
                    <span>Brand</span>
                </Link>
            </DropdownItem>
            <DropdownItem>
                <Link to="/partner/new">
                    <span className="mr-1"><MapPin size={14} /></span>
                    <span>Location</span>
                </Link>
            </DropdownItem>
            <DropdownItem>
                <Link to="/referrer/new">
                    <span className="mr-1"><Users size={14} /></span>
                    <span>Referrer</span>
                </Link>
            </DropdownItem>
            <DropdownItem>
                <Link to="/wallet/new">
                    <span className="mr-1"><DollarSign size={14} /></span>
                    <span>Wallet</span>
                </Link>
            </DropdownItem>
        </DropdownMenu>
    </UncontrolledDropdown>
);

export default HeaderNew;