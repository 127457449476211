import React, { memo } from 'react';
import { bool, func, number, object, oneOfType, string } from 'prop-types';
import {
    Input,
    Label,
} from 'reactstrap';

const getMultipleSelections = (select) => {
    const ids = [];
    const numOptions = select.options.length;
    let option = null;
    for(let i = 0; i < numOptions; i++) {
        option = select.options[i];
        if(option.selected) {
            ids.push(option.value);
        }
    }
    return ids;
};

/**
 * Accepts an object as options, the keys of which are database ids and the values
 * are the items of interest from the database. For example, and options object of brands:
 * {
 *   brandId1: { ...brand1 properties },
 *   brandId2: { ...brand2 properties },
 *   ...
 * }
 * You specify a value field to populate the form options with.
 */
const GtSelect = ({
    className,
    defaultText,  // Text to show in the option of the default value which should never actually be selected
    displayField, // The field to look for on the item object for display in the option
    displayFieldOverride,
    id,
    label,
    multiple,
    onChange,
    options,
    value,
}) => {
//console.log(`rendering ${id} GtSelect`)
    return (
    <div className={className}>
        <Label for={id}>{label}</Label>
        <Input
            id={id}
            invalid={multiple === false ? value === 0 : value.length === 0}
            name={`${id}-select`}
            multiple={multiple}
            onChange={e => {
                onChange(multiple === true ? getMultipleSelections(e.target) : e.target.value);
            }}
            type="select"
            value={value}
        >
            {multiple === false ?
            <option key={0} value={0}>{defaultText}</option> : null}
            {Object.entries(options).map(([id, item]) => {
                const _displayField
                    = displayFieldOverride === null
                    ? displayField
                    : displayFieldOverride(id, item);
                return (<option key={id} value={id}>{item[_displayField]}</option>);
            })}
        </Input>
    </div>
);
};

const gtSelectPropTypes = {
    className: string,
    defaultText: string.isRequired,
    displayFieldOverride: func,
    id: string.isRequired,
    label: string.isRequired,
    multiple: bool,
    options: object.isRequired,
    value: oneOfType([number, string]).isRequired,
};

const gtSelectDefaultProps = {
    className: '',
    displayFieldOverride: null,
    multiple: false,
    value: 0,
};

GtSelect.propTypes = {
    ...gtSelectPropTypes
};

GtSelect.defaultProps = {
    ...gtSelectDefaultProps
};

export {
    gtSelectPropTypes,
    gtSelectDefaultProps,
};

//export default GtSelect;
export default memo(GtSelect, (prevProps, nextProps) => {
    const r = prevProps.className === nextProps.className &&
        prevProps.defaultText === nextProps.defaultText &&
        prevProps.id === nextProps.id &&
        prevProps.label === nextProps.label &&
        prevProps.multiple === nextProps.multiple &&
        Object.keys(prevProps.options).join(',') === Object.keys(nextProps.options).join(',') &&
        prevProps.value === nextProps.value;

    return r;
});