import { put } from 'redux-saga/effects';
import fetch, { isStatusCodeAnError } from 'lib/fetch';
import { API_SERVER_URL } from 'config/config';
import {
    LIST_UNCOMPENSATED_TICKETS_ERROR,
    LIST_UNCOMPENSATED_TICKETS_SUCCESS,
    REQUEUE_TICKET_ERROR,
    REQUEUE_TICKET_SUCCESS,
} from 'constants/actions/compensation';

export function *listUncompensatedTickets() {
    try {
        const res = yield fetch(`${API_SERVER_URL}/compensation/list`);
        if(isStatusCodeAnError(res.status)) {
            yield put({
                type: LIST_UNCOMPENSATED_TICKETS_ERROR,
                error: res.data.message,
            });
        } else {
            yield put({
                type: LIST_UNCOMPENSATED_TICKETS_SUCCESS,
                list: res.data,
            });
        }
    } catch(e) {
        yield put({
            type: LIST_UNCOMPENSATED_TICKETS_ERROR,
            error: e.message || 'Unknown failure listing uncompensated tickets'
        });
    }
}

export function *requeueTicket({
    brandId,
    ticketId,
}) {
    try {
        const res = yield fetch(
            `${API_SERVER_URL}/compensation/requeue/${ticketId}/${brandId}`,
            {method: 'POST'}
        );
        
        if(isStatusCodeAnError(res.status)) {
            yield put({
                type: REQUEUE_TICKET_ERROR,
                error: res.data.message,
            });
        } else {
            yield put({
                ticketId,
                type: REQUEUE_TICKET_SUCCESS,
            });
        }
    } catch(e) {
        yield put({
            type: REQUEUE_TICKET_ERROR,
            error: e.message || 'Unknown failure fixing QR code'
        });
    }
}