import { put } from 'redux-saga/effects';
import { PAGINATION_PER_PAGE } from 'config/config';

export function *initPromoListPage() {
    // Fetch the promotions
    yield put({
        type: 'FETCH_PROMO_LIST',
        successAction: 'FETCH_PROMO_LIST_SUCCESS',
        errorAction: 'FETCH_PROMO_LIST_ERROR',
        model: 'PROMO',
        page: 1,
        perPage: PAGINATION_PER_PAGE,
    });

    // Now fetch brand partners (labeled locations)
    // This is a huge hack, b/c we have no idea if these will corespond to the models we actually need.
    // We'll start to notice this as an issue as the dataset grows...
    yield put({
        type: 'FETCH_PARTNER_LIST',
        errorAction: 'FETCH_PARTNER_LIST_ERROR',
        model: 'PARTNER',
        page: 1,
        perPage: PAGINATION_PER_PAGE,
        successAction: 'FETCH_PARTNER_LIST_SUCCESS',
    });
}
