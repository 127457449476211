import {
    INIT_BRAND_DETAIL_PAGE,
    INIT_PARTNER_DETAIL_PAGE,
    INIT_PROMO_DETAIL_PAGE,
    INIT_WALLET_DETAIL_PAGE,
} from 'constants/actions/pages';

//-------------------------------------------
// list pages
//-------------------------------------------
const initCompensationListPage = () => ({
    type: 'INIT_COMPENSATION_LIST_PAGE',
});

const initBrandListPage = () => ({
    type: 'INIT_BRAND_LIST_PAGE',
});

const initPartnerListPage = () => ({
    type: 'INIT_PARTNER_LIST_PAGE',
});

const initPromoListPage = () => ({
    type: 'INIT_PROMO_LIST_PAGE',
});

const initReferrerListPage = () => ({
    type: 'INIT_REFERRER_LIST_PAGE',
});

const initWalletListPage = () => ({
    type: 'INIT_WALLET_LIST_PAGE',
});

//-------------------------------------------
// detail pages
//-------------------------------------------

const initBrandDetailPage = ({id=null}) => ({
    type: INIT_BRAND_DETAIL_PAGE,
    id,
});

const initPartnerDetailPage = ({id=null}) => ({
    type: INIT_PARTNER_DETAIL_PAGE,
    id,
});

const initPromoDetailPage = ({id=null}) => ({
    type: INIT_PROMO_DETAIL_PAGE,
    id,
});

const initReferrerDetailPage = ({id=null}) => ({
    type: 'INIT_REFERRER_DETAIL_PAGE',
    id,
});

const initWalletDetailPage = ({id=null}) => ({
    type: INIT_WALLET_DETAIL_PAGE,
    id,
});

const saveClonedPromo = () => ({
   type: 'SAVE_CLONED_PROMO', 
});

export {
    initBrandListPage,
    initCompensationListPage,
    initPartnerListPage,
    initPromoListPage,
    initBrandDetailPage,
    initPartnerDetailPage,
    initPromoDetailPage,
    initReferrerListPage,
    initReferrerDetailPage,
    initWalletListPage,
    initWalletDetailPage,
    saveClonedPromo,
};