import createReducer from './crud';

const referrerReducer = createReducer(
    'REFERRER',
    (state, action) => ({}),
    { // new model
        name: '',
        email: '',
        phone: '',
        walletId: '',
    }
);

export default referrerReducer;
