import {
    LIST_UNCOMPENSATED_TICKETS,
    REQUEUE_TICKET,
} from 'constants/actions/compensation';

export const listUncompensatedTickets = () => ({
    type: LIST_UNCOMPENSATED_TICKETS,
});

export const requeueTicket = (ticketId, brandId) => ({
    brandId,
    ticketId,
    type: REQUEUE_TICKET,
});