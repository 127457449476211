/**
 * Coerce a particular activity param field from an activityParams object.
 *
 * @param object activityParams - The activityParams object from redux
 * @params string fieldName - The field to look for on the activityParams object
 * @params mixed defaultValue - The default value (type-specific) for the current fieldName
 */
function loadActivityTypeParam(activityParams, fieldName, defaultValue='') {
    return typeof activityParams === 'object' &&
    activityParams !== null &&
    typeof activityParams[fieldName] !== 'undefined' ?
    activityParams[fieldName]:
    defaultValue;
}

export {
    loadActivityTypeParam,
};