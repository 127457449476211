import React from "react";
import { number, string, } from 'prop-types';
import { offBlack } from 'config/colors';
import Svg from './components/Svg';
import SvgLoader from './components/SvgLoader';

const Loader3 = ({x, y, width, height, color}) => (
    <SvgLoader>
        <Svg x={x} y={y} width={width} height={height} viewBox="0 0 50 50" enableBackground="new 0 0 50 50">
            <path fill={color} d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
                <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="rotate"
                    from="0 25 25"
                    to="360 25 25"
                    dur="0.6s"
                    repeatCount="indefinite"/>
            </path>
        </Svg>
    </SvgLoader>
);

Loader3.propTypes = {
    x: number,
    y: number,
    width: number,
    height: number,
    color: string,
};

Loader3.defaultProps = {
    x: 0,
    y: 0,
    width: 40,
    height: 40,
    color: offBlack,
};

export default Loader3;