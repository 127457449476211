import React from 'react';
import { number, string } from 'prop-types';
import { LocaleValue } from 'components/Formatter';
import { formatDollarValue } from 'lib/formatters';

// XXX Why does this need the cryptoConversionRate
//     and what is the internalValue all about ?
//
// Seems like it can support pre-calculated values OR
// convert on the fly, might be better to break that
// behavior off into distinct components...
const ConvertedPrice = ({
    cryptoValue,
    cryptoConversionRate,
    coinType,
    justifyContent,
    usdValue,
}) => {
    if(cryptoValue === null && cryptoConversionRate === null || cryptoValue === null) {
        return null;
    }

    return (
        <div className={`d-flex ${justifyContent}`}>
            <div className="align-self-center">
               <div>{
                cryptoValue === null && cryptoConversionRate !== null
                ? usdValue * cryptoConversionRate
                : cryptoValue
               }</div>
               <div className="text-uppercase">{coinType}</div>
            </div>
            <div className="d-flex-col align-self-center ml-1 text-left" style={{fontSize: '12px'}}>
                <div>{formatDollarValue(usdValue)}</div>
            </div>
        </div>
    );
};

ConvertedPrice.propTypes = {
    cryptoValue: number,
    cryptoConversionRate: number,
    coinType: string.isRequired,
    justifyContent: string,
    usdValue: number,
};

ConvertedPrice.defaultProps = {
    cryptoValue: null,
    cryptoConversionRate: null,
    justifyContent: 'justify-content-center',
};

export default ConvertedPrice;