/* eslint-disable react/forbid-foreign-prop-types */
import React, { memo } from 'react';
import TextInput, { textInputPropTypes, textInputDefaultProps } from '../core/TextInput';
import { IMG_SERVER_URL } from 'config/config';

const extractSrc = rawUrl => {
    const _url
        = rawUrl.indexOf('IMG_SERVER_URL') === 0
        ? rawUrl.replace('IMG_SERVER_URL', '')
        : rawUrl;

    return `${IMG_SERVER_URL}${_url}`;
};

/**
 * Expands on TextInput with expectation that the path
 * will point to the path of an image in the GoldenTicket media bucket.
 */
const BucketPathInput = ({
    children,
    value,
    ...textInputProps
}) => {
//console.log('rendering bucket path input')
    return (
    <div>
        <TextInput
            {...textInputProps}
            requirement={value => typeof value === 'string' && value.length > 0 && (value[0] === '/' || value.indexOf('IMG_SERVER_URL') === 0 || value === 'none' )}
            value={value}
        >{children}</TextInput>
        {typeof value === 'string' && value.length > 1 && value !== 'none' ?
        <span><img alt="bucket resource preview" className="d-block mt-3" src={extractSrc(value)} /><br/></span>
        : null}
    </div>
);
};

const bucketPathPropTypes = {
    ...textInputPropTypes
};

delete textInputPropTypes.requirement;

BucketPathInput.propTypes = {
    ...bucketPathPropTypes
};

BucketPathInput.defaultProps = {
    ...textInputDefaultProps
};

//export default BucketPathInput;

export default memo(BucketPathInput, (prevProps, nextProps) => {
    const r = prevProps.children === nextProps.children &&
        prevProps.help === nextProps.help &&
        prevProps.id === nextProps.id &&
        prevProps.label === nextProps.label &&
        prevProps.noBr === nextProps.noBr &&
        prevProps.required === nextProps.required &&
        prevProps.className === nextProps.className &&
        prevProps.value === nextProps.value;

    return r;
});