import React from "react";
import { arrayOf, node, oneOfType, } from 'prop-types';

const SvgLoader = ({children}) => (
    <div className="svg-loader">
        {children}
    </div>
);

SvgLoader.propTypes = {
    children: oneOfType([
        arrayOf(node),
        node
    ]).isRequired,
};

export default SvgLoader;