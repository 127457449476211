import createReducer from './crud';

const partnerReducer = createReducer(
    'PARTNER',
    (state, action) => ({}),
    { // new model
        name: '',
        contactName: '',
        contactEmail: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        lat: '',
        long: '',
        walletId: 0,
        logo: '',
        url: '',
    },
);

export default partnerReducer;