import { put } from 'redux-saga/effects';
import fetch, { isStatusCodeAnError } from 'lib/fetch';
import { API_SERVER_URL } from 'config/config';
import {
    FETCH_SHORT_URL_ERROR,
    FETCH_SHORT_URL_SUCCESS,
} from 'constants/actions/short-urls';

/**
 * Fetch all the activities for a given promotion.
 * @note We don't paginate, because it's anticipated this number will always be very low.
 */
export function *fetchShortUrl({
    urlToShorten,
}) {
    const _urlToShorten = window.btoa(urlToShorten);
    try {
        const res = yield fetch(`${API_SERVER_URL}/shorten-url/${_urlToShorten}`);
        if(isStatusCodeAnError(res.status)) {
            yield put({
                type: FETCH_SHORT_URL_ERROR,
                error: res.data.message,
            });
        } else {
           yield put({
               type: FETCH_SHORT_URL_SUCCESS,
               result: res.data.result,
           });
        }
    } catch(e) {
        yield put({
            type: FETCH_SHORT_URL_SUCCESS,
            error: e.message || 'Unknown failure fetching promo activities by promo code',
        });
    }
}
