import {
    FETCH_PROMO_ACTIVITIES_BY_PROMO_CODE,
    CREATE_NEW_PROMO_ACTIVITY,
    DELETE_NEW_PROMO_ACTIVITY,
} from 'constants/actions/promo-activity';
import {
    createInsertModelAction,
    createUpdateModelAction,
    createDeleteModelAction,
    createSetNewModelAttrAction,
} from './crud';

const insertAction     = createInsertModelAction('PROMO_ACTIVITY');
const updateAction     = createUpdateModelAction('PROMO_ACTIVITY');
const deleteAction     = createDeleteModelAction('PROMO_ACTIVITY');
const setNewAttrAction = createSetNewModelAttrAction('PROMO_ACTIVITY');

const fetchPromoActivitiesByPromoCode = ({promoCode}) => ({
    type: FETCH_PROMO_ACTIVITIES_BY_PROMO_CODE,
    promoCode,
});

const createNewPromoActivity = ({promoId, activityTypeId}) => ({
    type: CREATE_NEW_PROMO_ACTIVITY,
    newModel: {
        promoId,
        activityTypeId,
    },
});

const deleteNewPromoActivity = (activityId) => ({
    id: activityId,
    type: DELETE_NEW_PROMO_ACTIVITY,
});

const setPromoActivityType = (activityId, activityTypeId) => ({
    activityId,
    activityTypeId,
    type: 'SET_PROMO_ACTIVITY_TYPE'
});

export {
    insertAction,
    updateAction,
    deleteAction,
    setNewAttrAction,
    fetchPromoActivitiesByPromoCode,
    createNewPromoActivity,
    deleteNewPromoActivity,
    setPromoActivityType,
};