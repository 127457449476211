import React from 'react';

import {
    Container
} from 'reactstrap';

const Accordion = ({
    children
}) => (
    <Container className="gt-accordion">
        {children}
    </Container>
);

Accordion.propTypes = {
    
};

Accordion.defaultProps = {
    
};

export default Accordion;