import React, { memo } from 'react';
import {
    Label,
} from 'reactstrap';
import { string } from 'prop-types';
import { FormGroup } from 'reactstrap';
import { HelpCircle } from 'react-feather';
import { Switch } from 'vibe';

/**
 * GT wrapper for the vibe Switch component
 */
const SwitchInput = ({
    help,
    label,
    ...switchProps
}) => {
//console.log('rendering switch input')
    return (
    <FormGroup check className="gt-switch">
        <Label check>
            <div className="gt-switch-container">
                <Switch
                    {...switchProps}
                />
                <div className="gt-switch-label">{label}</div>
                {help.length > 0 ?
                <span
                    className="ml-2 pointer"
                    title={help}
                >
                    <HelpCircle size={12}/>
                </span> : null}
            </div>
        </Label>
    </FormGroup>
);
};

SwitchInput.propTypes = {
    ...Switch.propTypes,
    help: string,
    label: string.isRequired,
};

SwitchInput.defaultProps = {
    help: '',
};

// export default SwitchInput;
export default memo(SwitchInput, (prevProps, nextProps) => {
    const r = prevProps.color === nextProps.color &&
        prevProps.enabled === nextProps.enabled &&
        prevProps.help === nextProps.help &&
        prevProps.label === nextProps.label;

    return r;
});