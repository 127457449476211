/*global document*/
import React, { useState, useEffect } from 'react';
import { bool, string } from 'prop-types';
import { Copy } from 'react-feather';
import { colorPrimary, gold } from 'config/colors';

const ExternalLink = ({
    asText,
    href,
    label,
    title,
    withoutCopy,
}) => {
    const [clicked, setClicked] = useState(false);

    useEffect(() => {
        if(clicked === true) {
            setTimeout(() => {
                setClicked(false);
            }, 150);
        }
    }, [clicked]);

    const copyToClipboard = (content) => {
        const tmpTextArea = document.createElement('textarea');
        const newContent  = document.createTextNode(content);

        tmpTextArea.appendChild(newContent);
        tmpTextArea.style.width = 0;
        tmpTextArea.style.height = 0;
        tmpTextArea.style.border = 0;
        document.body.appendChild(tmpTextArea);
        tmpTextArea.select();
        document.execCommand('copy');
        document.body.removeChild(tmpTextArea);
    };

    const getCopyTitle = () =>
        label.toLowerCase().indexOf('link') < 0
        ? `Copy ${label} Link`
        : `Copy ${label}`;

    return (
    <span>
        {asText === true ? 
        <span>{label}</span> :
        <a href={href}
           target="_blank"
           rel="noopener noreferrer"
           title={title}
        >{label}</a>}
        {withoutCopy === true ? null :
        <span className="ml-2 pointer" title={getCopyTitle()}>
            <Copy color={clicked ? gold : colorPrimary} onClick={() => { setClicked(true); copyToClipboard(href); }} />
        </span>}
    </span>);
};

ExternalLink.propTypes = {
    asText: bool,
    href: string,
    label: string.isRequired,
    title: string.isRequired,
    withoutCopy: bool,
};

ExternalLink.defaultProps = {
    asText: false,
    href: null,
    withoutCopy: false,
};

export default ExternalLink;