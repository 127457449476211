/*global document*/
import React from 'react';
import { string } from 'prop-types';
import ExternalLink from './ExternalLink';

const GtExternalLink = ({
    className,
    href,
    label,
    shortUrl,
    title,
}) => {

    return (
    <div className={`d-flex justify-content-center ${className}`}>
        <ExternalLink
            href={href}
            label={label}
            title={title}
        />
        {shortUrl !== '' ? <span className="ml-1 mr-1">-</span> : null}
        {shortUrl !== '' ?
        <ExternalLink
            href={shortUrl}
            label="Short Link"
            title={`${label} short link`}
        /> : null}
    </div>);
};

GtExternalLink.propTypes = {
    className: string,
    href: string.isRequired,
    label: string.isRequired,
    shortUrl: string,
    title: string.isRequired,
};

GtExternalLink.defaultProps = {
    className: '',
    shortUrl: '',
};

export default GtExternalLink;