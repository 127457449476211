import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ContextProviders from 'vibe/components/utilities/ContextProviders';
import { PageContent } from '../vibe';
import routes from 'views';

const LoggedOutLayout = () => (
  <ContextProviders>
    <PageContent>
        <Switch>
          {routes.map((page, key) => (
            <Route path={page.path} component={page.component} key={key} />
          ))}
          <Redirect from="/" to="/home" />
        </Switch>
    </PageContent>
  </ContextProviders>
);

export default LoggedOutLayout;