import deepMerge from 'deepmerge';
import Jwt from 'jsonwebtoken';
import { COOKIE_NAME, JWT_PUBLIC_KEY } from 'config/config';
const cookies = require('browser-cookies');

// Internal methods to read and write a cookie.
const loadJsonCookie = (cookieName) => {
    // Get the existing cookie
    let cookie = cookies.get(cookieName);
    if(cookie === null) {
        cookie = {};
    } else {
        cookie = JSON.parse(cookie);
    }

    return cookie;
};

const saveCookie = (cookieName, cookie) => {
    cookies.set(cookieName, JSON.stringify(cookie));
};

/**
 * Set a scalar value on the object stored in the cookie named, cookieName.
 *
 * Note: This should only ever be called on the client side.
 *       If it is called on the server, it's a no-op.
 *
 * @parm name is the name of the cookie
 * @parm key is the key within the cookie data
 * @param value is the value of key
 */
export const setCookieValue = (cookieName, key, value) => {
    const cookie = loadJsonCookie(cookieName);
    
    // Now set the value in memory
    cookie[key] = value;

    // Lastly write the cookie
    saveCookie(cookieName, cookie);
};

/**
 * Overlay an object onto the object stored in the cookie named cookieName.
 *
 * Use this to set a property that's an object, or to set multiple values on the cookie object proper.
 */
export const setCookieValues = (cookieName, valuesObj) => {
    let cookie = loadJsonCookie(cookieName);

    // Now set the values in memory
    cookie = deepMerge(cookie, valuesObj);

    // Lastly write the cookie
    saveCookie(cookieName, cookie);
};

/**
 * Set a field stored in a cookie to null.
 */
export const clearCookieValue = (cookieName, key) => {
   const cookie = loadJsonCookie(cookieName);

   cookie[key] = null;
   
   saveCookie(cookieName, cookie);
};

/**
 * Delete a cookie (client-side).
 */
export const deleteCookie = (cookieName) => {
    return cookies.erase(cookieName);
};

export const deleteSession = () => {
    return deleteCookie(COOKIE_NAME);
};

/**
 * A minimal session store until we build something more serious.
 */
export const getSessionData = () => {
    const cookie = cookies.get(COOKIE_NAME);
    if(cookie !== null) {
        return JSON.parse(cookie);
    }
    return null;
};

export const getSessionValue = (sessionData, key) => (
    sessionData !== null && typeof sessionData[key] !== 'undefined' ? sessionData[key] : null
);

export const getSessionReqValue = (req, key) => {
    const sessionData = getSessionData(req);
    return getSessionValue(sessionData, key);
};

export const setSessionValue = (field, value) => {
    setCookieValue(COOKIE_NAME, field, value);
};

export const setSessionValues = (valuesObj) => {
    setCookieValues(COOKIE_NAME, valuesObj);
};

export const checkLoggedInUser = () => {
    const session = getSessionData();

    if(session === null || typeof session['jwt'] !== 'string') {
        return false;
    }

    try {
        const token = Jwt.verify(
            session.jwt, JWT_PUBLIC_KEY, {algorithms: ['RS256']});

        // TODO Is there anything more we want to check on the unpacked data?
        //      User type maybe?

        return token;
    } catch(e) {
        console.error('Error verifying JWT: ', e);
        return false;
    }
};