import React, { useEffect } from 'react';
import { connect }  from 'react-redux';
import { push } from 'connected-react-router';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    FormGroup,
    Row,
} from 'reactstrap';
import { deleteAction, insertAction, updateAction } from 'actions/wallet';
import {
        TextInput,
        DeleteButton,
        SaveButton,
} from 'components/Form';
import haveValue from 'lib/have-value';
import { initWalletDetailPage } from 'actions/pages/init';

const WalletPage = ({
    errors,
    walletList,

    // wallet 
    deleteWallet,
    newWalletInstance,
    saveNewModel,
    setModelAttr,
    setNewAttr,
    updateWallet,

    // page
    initWalletDetailPage,

    // router
    redirect,
    router,
}) => {
    // Grab the promo id from the URL
    let walletId = router.location.pathname.split('/').pop();

    // Effective model is determined by new vs edit
    const isNew = walletId === 'new';

    if(!isNew) {
        walletId = Number.parseInt(walletId, 10);
    }

    const setterMethod = isNew ? setNewAttr : setModelAttr;
    let effectiveModel = isNew ? newWalletInstance : walletList[walletId];

    // Fetch the model detail on page load
    useEffect(() => {
        initWalletDetailPage(walletId);
    }, [isNew]);

    // Bail if we have no wallet info
    if(typeof effectiveModel === 'undefined' || effectiveModel === null) {
        return null;
    }

    return (
    <div className="detail-page">
        <h3 className="m-b">{isNew ? 'New' : 'Edit'} Wallet</h3>
        <Row>
            <Col md={8}>
                <Card className="shadow">
                    <CardHeader>Content</CardHeader>
                    <CardBody>
                        <FormGroup>
                            <TextInput
                                id="wallet-address"
                                label="Address"
                                onInput={value => { setterMethod(walletId, 'address', value); }}
                                value={effectiveModel.address}
                            />
                            <TextInput
                                id="wallet-alias"
                                help="A user-friendly identifier for use in the admin for addresses that don't have an ENS setup"
                                label="Alias"
                                onInput={ value => { setterMethod(walletId, 'gtAlias', value); }}
                                value={effectiveModel.gtAlias}
                            />
                        </FormGroup>
                    </CardBody>
                </Card>
            </Col>
            <Col md={4}>
                {!isNew ?
                <DeleteButton
                    className="mb-2"
                    onClick={() => {deleteWallet(effectiveModel.id); redirect('/wallets');}}
                />
                : null}
                {isNew === true ?
                <SaveButton
                    className="mb-3"
                    disabled={
                        isNew && effectiveModel !== null && (
                        !haveValue(effectiveModel.address)
                    )}
                    onClick={() => { saveNewModel(effectiveModel) }}
                />
                : null}
                {!isNew === true ?
                <SaveButton
                    className="mb-3"
                    onClick={() => { updateWallet(effectiveModel) }}
                />
                : null}
            </Col>
        </Row>
    </div>);
};


const mapStateToProps = ({ router, wallet }) => ({
    errors: wallet.errors,
    router,
    walletList: wallet.list,
    newWalletInstance: wallet.newInstance,
});

const mapDispatchToProps = dispatch => ({
    // page
    initWalletDetailPage: (id) => dispatch(initWalletDetailPage({id})),

    // wallet
    deleteWallet: (id) => dispatch(deleteAction({id})),
    /* Set existing model attr in redux, push to server if sync=true */
    setModelAttr: (id, field, value, sync=false) => dispatch(updateAction({id, updateModel: {[field]: value}, sync})),
    /* Set attr on new model */
    setNewAttr: (id, field, value, sync=false) => dispatch(insertAction({ newModel: {[field]: value}, sync})),
    /* Push new model to the server */
    saveNewModel: (newModel) => dispatch(insertAction({ newModel, sync: true })),

    updateWallet: (updateModel) => dispatch(updateAction({id: updateModel.id, updateModel, sync: true})),

    // router
    redirect: (path) => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletPage);
