import React from 'react';
import { func, number, object, string } from 'prop-types';
import {
    FormGroup,
} from 'reactstrap';
import { Eye } from 'react-feather';
import ActivityType from './ActivityType';
import { GtButton, TextInput } from 'components/Form';
import { loadActivityTypeParam } from 'lib/activity-type-helpers';

const Survey = ({
    activityId,
    activityParams,
    activitySetterMethod,
    globalBackgroundImage,
    generatePreview,
    ...activityTypeProps
}) => {
    
    const formURL = loadActivityTypeParam(activityParams, 'formURL');

    return (
    <ActivityType
        activityId={parseInt(activityId, 10)}
        activitySetterMethod={activitySetterMethod}
        {...activityTypeProps}
    >
        <div className="pt-2">
            <GtButton
                color="info"
                className="float-"
                disabled={false}
                icon={Eye}
                iconSize={12}
                label="Preview"
                onClick={() => generatePreview({activityId, page: 'module'})}
                small
            />
        </div>
        <FormGroup tag="fieldset">
            <TextInput
                id="form-url"
                help="The URL of your Jotform Form, obtainable from Jotform Form Builder's Publish page. Example: https://form.jotform.com/211272589254055"
                label="Form URL"
                onInput={value => { activitySetterMethod(activityId, 'activityParams', {formURL: value}); }}
                value={formURL}
            />
        </FormGroup>
    </ActivityType>);
};

Survey.propTypes = {
    ...ActivityType.defaultProps,
    activityId: number.isRequired,
    activityParams: object,
    activitySetterMethod: func.isRequired,
    globalBackgroundImage: string,
};

Survey.defaultProps = {
    ...ActivityType.propTypes,
    activityParams: null,
    globalBackgroundImage: '',
};

Survey.dbName = 'survey';

export default Survey;
