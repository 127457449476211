import createReducer from './crud';
import { getBlockchainIdentifiers } from 'lib/blockchain';

const locationReducer = createReducer(
    'LOCATION',
    (state, action) => ({
        //-----------------------------------------------------------
        // XXX This needs to be consolidated into the shared model...
        //-----------------------------------------------------------
        /*
        ADD_NEW_LOCATION: () => {
            return {
                ...state,
                list: {
                    ...state.list,
                    'new': {
                        ...Object.assign({}, newModel),
                        locationId: action.locationTypeId,
                        parentId: action.partnerId,
                        promoId: action.promoId,
                    }
                },
            };
        },
        */
        //-----------------------------------------------------------
        // XXX This needs to be consolidated into the shared model...
        //-----------------------------------------------------------
        /*
        DELETE_NEW_LOCATION: () => {
            let deleteList = {};
            let locationId = 0;
            for(locationId in state.list) {
                if(locationId === 'new') {
                    continue;
                }
                deleteList[locationId] = Object.assign({}, state.list[locationId]);
            }
            return {
                ...state,
                list: deleteList,
            };
        },
        */
        'FETCH_LOCATION_BALANCE': () => {
            const { coinType } = action;

            const {
                balanceCrypto,
                balanceUsd,
            } = getBlockchainIdentifiers(coinType);

            return {
                ...state,
                list: {
                    ...state.list,
                    [action.id]: {
                        ...state.list[action.id],
                        [balanceCrypto]: 0.0,
                        [balanceUsd]: 0.0,
                    }
                },
                fetchingBalance: true,
                fetchingBalanceErrors: [],
            };
        },
        'FETCH_LOCATION_BALANCE_SUCCESS': () => {
            const { coinType } = action;

            const {
                balanceCrypto,
                balanceUsd,
            } = getBlockchainIdentifiers(coinType);

            return {
                ...state,
                list: {
                    ...state.list,
                    [action.id]: {
                        ...state.list[action.id],
                        [balanceCrypto]: Number.parseFloat(action.balanceCrypto),
                        [balanceUsd]: Number.parseFloat(action.balanceUsd),
                    }
                },
                fetchingBalance: false,
            };
        },
        'FETCH_LOCATION_BALANCE_ERROR': () => {
            return {
                ...state,
                fetchingBalance: false,
                fetchingBalanceErrors: action.errors,
            };
        },
    }),
    { // new model
        address: '',
        balanceAutx: 0.0,
        balanceUsdc: 0.0,
        autxBalanceUsd: 0.0,
        usdcBalanceUsd: 0.0,
        city: '',
        creating: false,
        creatingErrors: [],
        cryptCode: '',
        deleting: false,
        deletingErrors: [],
        fetching: false,
        fetchingList: false,
        fetchingBalance: false,
        fetchingBalanceErrors:[],
        lat: null,
        locationCode: '',
        long: null,
        parentId: '',
        state: '',
        updating: false,
        updatingErrors: [],
        walletAddress: '',
        zip: '',
    }
);

export default locationReducer;