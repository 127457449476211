import { put } from 'redux-saga/effects';
import { PAGINATION_PER_PAGE } from 'config/config';
import {
    LIST_UNCOMPENSATED_TICKETS,
} from 'constants/actions/compensation';

export function *initCompensationListPage() {
    yield put({
        type: LIST_UNCOMPENSATED_TICKETS,
        page: 1,
        perPage: PAGINATION_PER_PAGE,
    });
}