import React, { memo, useState } from 'react';
import {  func, oneOf, string } from 'prop-types';
import {
    Label,
} from 'reactstrap';
import { colorLight, editorBorder } from 'config/colors';
import AceEditor from 'react-ace';
import 'brace/mode/css';
import 'brace/mode/html';
import 'brace/theme/solarized_light';
import 'brace/ext/language_tools';
import ColorDropdown from './ColorDropdown';

const stripStyleTags = (code) =>
    code.replace('<style>', '').replace('</style>', '');
    
const addStyleTags = (code) =>
    `<style>${code}</style>`;

/* TODO Consider moving into lib
const createMockEvent = code =>
    ({target: { value: code }});
*/

/**
 * A basic bootstrap wrapper for the AceEditor
 *
 * Documentation:
 *  - (react) https://github.com/securingsincity/react-ace/blob/master/docs/Ace.md
 *  - (ace) https://ace.c9.io/#nav=api
 */
const Editor = ({
    name,
    mode,
    placeholder,
    onChange,
    onPickColor,
    value,
}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selection, setSelection] = useState(null);
    const stringValue = String(value);

//console.log(`Rendering ${mode} Editor`)
    return (
    <span className="d-inline-block">
        <Label>{mode}</Label>
        <div className="editor-toolbar p-2 d-flex align-items-flex-start" style={{
            backgroundColor: colorLight,
            border: `1px solid ${editorBorder}`,
            height: '60px',
        }}>
            <ColorDropdown
                dropdownOpen={dropdownOpen}
                // XXX Does not support replacing selection...
                onPickColor={(colorName, color) => {
                    const lines    = [...selection.doc['$lines']];
                    const row      = selection.cursor.row;
                    const col      = selection.cursor.column;
                    const line     = lines[row];
                    lines[row]     = [line.slice(0, col), `${color} /*${colorName}*/`, line.slice(col)].join('');
                    const newLines = lines.join("\n");

                    onChange(mode === 'css' ? addStyleTags(newLines) : newLines);
                }}
                toggle={() => setDropdownOpen(!dropdownOpen)}
            />
        </div>
        <div style={{
            border: `1px solid ${editorBorder}`,
        }}>
            <AceEditor
                debounceChangePeriod={200}
                editorProps={{ $blockScrolling: true }}
                fontSize={14}
                highlightActiveLine
                mode={mode}
                name={name}
                onChange={value => onChange(mode === 'css' ? addStyleTags(value) : value)}
                onCursorChange={s => setSelection(s)}
                onLoad={(e) => {setSelection(e.selection)}}
                onSelectionChange={s => setSelection(s)}
                placeholder={placeholder}
                theme="solarized_light"
                value={mode === 'css' ? stripStyleTags(stringValue) : stringValue}
                setOptions={{
                    enableBasicAutocompletion: false,
                    enableLiveAutocompletion: true,
                    enableSnippets: false,
                    showLineNumbers: true,
                    tabSize: 4,
                }}
                showGutter
            />
        </div>
    </span>);
};

Editor.propTypes = {
    mode: oneOf(['css', 'html']).isRequired,
    name: string,
    onChange: func,
    onPickColor: func,
    value: string,
};

Editor.defaultProps = {
    onChange: value => {},
    name: '',
    onPickColor: value => {},
    value: '',
};

//export default Editor;
export default memo(Editor, (prevProps, nextProps) => {
    const r = prevProps.mode === nextProps.mode &&
        prevProps.name === nextProps.name &&
        prevProps.value === nextProps.value;

    return r;
});