import React from 'react';
import { array, bool, func, object, string } from 'prop-types';
import {
    DropdownToggle,
    DropdownMenu,
    UncontrolledDropdown,
} from 'reactstrap';
import { offBlack, hrColor } from 'config/colors';
import { Save } from 'react-feather';
import { Avatar } from 'vibe';
import LogoutLink from './LogoutLink';
import HeaderHistory from './HeaderHistory';
import HeaderNew from './HeaderNew';

const HeaderNav = ({
    brand,
    gtHistory,
    initials,
    logoutUser,
    partner,
    promotion,
    referrer,
    saving,
    wallet,
}) => (
    <React.Fragment>
        {/* TODO: bring back when we're cool enough to have global search...
        <NavItem>
        <form className="form-inline">
        <input className="form-control mr-sm-1" type="search" placeholder="Search" aria-label="Search" />
        <Button type="submit" className="d-none d-sm-block">
        <i className="fa fa-search" />
        </Button>
        </form>
        </NavItem>
        */}
        <HeaderHistory
            brand={brand}
            gtHistory={gtHistory}
            partner={partner}
            promotion={promotion}
            referrer={referrer}
            wallet={wallet}
        />
        <HeaderNew />
        <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav>
            <span className="mr-2"><Save color={saving ? offBlack : hrColor} size={16}/></span>
            <Avatar size="small" color="gold" initials={initials} />
            </DropdownToggle>
            <DropdownMenu right>
            <LogoutLink logoutUser={logoutUser} />
            </DropdownMenu>
        </UncontrolledDropdown>
    </React.Fragment>
);

HeaderNav.propTypes = {
    brand: object.isRequired,
    gtHistory: array.isRequired,
    initials: string.isRequired,
    logoutUser: func.isRequired,
    partner: object.isRequired,
    promotion: object.isRequired,
    referrer: object.isRequired,
    wallet: object.isRequired,
    saving: bool,
    // todo: consider wallet 
};

HeaderNav.defaultProps = {
    saving: false,
};

export default HeaderNav;