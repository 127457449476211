import React, { useEffect } from 'react';
import { connect }  from 'react-redux';
import { Link } from 'react-router-dom';
import CrudListPage from 'components/Crud/CrudListPage';
import { initWalletListPage } from 'actions/pages/init';

const WalletListPage = ({
    fetchingList,
    list,
    page,
    perPage,
    initWalletListPage,
}) => {
    useEffect(() => {
        initWalletListPage();
    }, []);

    return (
        <CrudListPage
            addNewPath="/wallet/new"
            title="Wallets"
            fields={{
                address: 'address',
                gtAlias: 'alias',
            }}
            formatter={(field, row) => {
                switch(field) {
                case 'address':
                    return <Link to={`/wallet/${row.id}`}>{row[field]}</Link>;
                case 'gtAlias':
                    return <Link to={`/wallet/${row.id}`}>{row[field]}</Link>;
                default:
                    return false;
                }
            }}
            rows={list}
        />
    );
};

const mapStateToProps = ({ wallet }) => ({
    fetchingList: wallet.fetchingList,
    list: wallet.list,
    page: wallet.page,
    perPage: wallet.perPage,
});

const mapDispatchToProps = dispatch => ({
    initWalletListPage: () => dispatch(initWalletListPage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletListPage);
