import React from "react";
import { number, string, } from 'prop-types';
import { offBlack } from 'config/colors';
import Svg from './components/Svg';
import SvgLoader from './components/SvgLoader';

const Loader6 = ({x, y, width, height, color}) => (
    <SvgLoader>
        <Svg x={x} y={y} width={width} height={height} viewBox="0 0 24 30" enableBackground="new 0 0 50 50">
            <style jsx>{`
            rect {
                fill: ${color};
            }
            `}</style>
            <rect x="0" y="13" width="4" height="5" fill="#333">
                <animate attributeName="height" attributeType="XML" values="5;21;5"  begin="0s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0s" dur="0.6s" repeatCount="indefinite" />
            </rect>
            <rect x="10" y="13" width="4" height="5" fill="#333">
                <animate attributeName="height" attributeType="XML" values="5;21;5"  begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
            </rect>
            <rect x="20" y="13" width="4" height="5" fill="#333">
                <animate attributeName="height" attributeType="XML" values="5;21;5"  begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
            </rect>
        </Svg>
    </SvgLoader>
);

Loader6.propTypes = {
    x: number,
    y: number,
    width: number,
    height: number,
    color: string,
};

Loader6.defaultProps = {
    x: 0,
    y: 0,
    width: 40,
    height: 40,
    color: offBlack,
};

export default Loader6;