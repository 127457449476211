import React, { useEffect } from 'react';
import { connect }  from 'react-redux';
import { push } from 'connected-react-router';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    FormGroup,
    Row,
} from 'reactstrap';
import { fetchBrandBalanceAndAllowance } from 'actions/blockchain';
import { deleteAction, insertAction, updateAction } from 'actions/brand';
import {
        BucketPathInput,
        TextInput,
        DeleteButton,
        SaveButton,
        UrlInput,
        WalletSelect,
} from 'components/Form';
import haveValue from 'lib/have-value';
import { initBrandDetailPage } from 'actions/pages/init';
import { ApproveAllowanceCard } from 'components/Blockchain';

const BrandPage = ({
    errors,
    brandList,
    newBrandInstance,
    usdToAutxRate,
    usdToUsdcRate,

    // blockchain
    fetchBrandBalanceAndAllowance,

    // brand
    deleteBrand,
    saveNewModel,
    setModelAttr,
    setNewAttr,
    updateBrand,

    // page
    initBrandDetailPage,

    // router
    redirect,
    router,

    // wallet
    walletList,
}) => {
    // Grab the promo id from the URL
    let brandId = router.location.pathname.split('/').pop();

    // Effective model is determined by new vs edit
    const isNew = brandId === 'new';

    if(!isNew) {
        brandId = Number.parseInt(brandId, 10);
    }

    const setterMethod = isNew ? setNewAttr : setModelAttr;
    let effectiveModel = isNew ? newBrandInstance : brandList[brandId];

    // Fetch the model detail on page load
    useEffect(() => {
        initBrandDetailPage(brandId);
    }, [isNew]);

    // Bail if we have no brand info
    if(typeof effectiveModel === 'undefined' || effectiveModel === null) {
        return null;
    }

    return (
    <div className="detail-page">
        <h3 className="m-b">{isNew ? 'New' : 'Edit'} Brand</h3>
        <Row>
            <Col md={8}>
                <Card className="shadow">
                    <CardHeader>Content</CardHeader>
                    <CardBody>
                        <FormGroup>
                            <TextInput
                                id="brand-name"
                                label="Name"
                                onInput={value => { setterMethod(brandId, 'name', value); }}
                                value={effectiveModel.name}
                            />
                            <BucketPathInput
                                help="For now, please manually upload images via Cloud Console. Enter the path to the image within the bucket in the input below."
                                id="brand-logo"
                                label="Logo Bucket Path"
                                onInput={value => { setterMethod(brandId, 'logo', value); } }
                                noBr
                                value={effectiveModel.logo}
                            />
                            <UrlInput
                                id="brand-url"
                                onInput={value => { setterMethod(brandId, 'url', value); } }
                                value={effectiveModel.url}
                            />
                            <TextInput
                                id="brand-contact-name"
                                label="Contact Name"
                                onInput={value => { setterMethod(brandId, 'contactName', value); }}
                                value={effectiveModel.contactName}
                            />
                            <TextInput
                                id="brand-contact-email"
                                label="Contact Email"
                                onInput={ value => { setterMethod(brandId, 'contactEmail', value); }}
                                value={effectiveModel.contactEmail}
                            />
                            <WalletSelect
                                id="brand-wallet-address"
                                label="Wallet Selection"
                                onChange={value => { setterMethod(brandId, 'walletId', value); }}
                                options={walletList}
                                value={effectiveModel.walletId}
                            />
                        </FormGroup>
                    </CardBody>
                </Card>
                {!isNew ?
                <Card className="shadow">
                    <CardHeader>Passkit Settings</CardHeader>
                    <CardBody>
                        <TextInput
                            id="passkit-campaign-id" 
                            label="Campaign Id"
                            onInput={value => { setterMethod(brandId, 'passkitCampaignId', value); }}
                            value={effectiveModel.passkitCampaignId}
                        />
                    </CardBody>
                </Card>
                : null}
            </Col>
            <Col md={4}>
                {!isNew ?
                <DeleteButton
                    className="mb-2"
                    onClick={() => {deleteBrand(effectiveModel.parentId); redirect('/brands');}}
                />
                : null}
                {isNew === true ?
                <SaveButton
                    className="mb-3"
                    disabled={
                        isNew && effectiveModel !== null && (
                        !haveValue(effectiveModel.name)         ||
                        !haveValue(effectiveModel.logo)         ||
                        !haveValue(effectiveModel.url)          ||
                        !haveValue(effectiveModel.contactName)  ||
                        !haveValue(effectiveModel.contactEmail) ||
                        !haveValue(effectiveModel.walletId)
                    )}
                    onClick={() => { saveNewModel(effectiveModel) }}
                />
                : null}
                {!isNew === true ?
                <SaveButton
                    className="mb-3"
                    onClick={() => { updateBrand(effectiveModel); }}
                />
                : null}
                {!isNew ? <ApproveAllowanceCard
                    brand={effectiveModel}
                    brandId={brandId}
                    coinType="AUTX"
                    fetchBrandBalanceAndAllowance={fetchBrandBalanceAndAllowance}
                    usdToCryptoRate={usdToAutxRate}
                 /> : null}
                {!isNew ? <ApproveAllowanceCard
                    brand={effectiveModel}
                    brandId={brandId}
                    coinType="USDC"
                    fetchBrandBalanceAndAllowance={fetchBrandBalanceAndAllowance}
                    usdToCryptoRate={usdToUsdcRate}
                 /> : null}
            </Col>
        </Row>
    </div>);
};

const mapStateToProps = ({ blockchain, brand, router, wallet }) => ({
    brandList: brand.list,
    newBrandInstance: brand.newInstance,
    errors: brand.errors,
    router,
    usdToAutxRate: blockchain.usdToAutxRate,
    usdToUsdcRate: blockchain.usdToUsdcRate,
    walletList: wallet.list,
});

const mapDispatchToProps = dispatch => ({
    // page
    initBrandDetailPage: (id) => dispatch(initBrandDetailPage({id})),

    // blockchain
    fetchBrandBalanceAndAllowance: (brandId, coinType) => dispatch(fetchBrandBalanceAndAllowance({brandId, coinType})),

    // brand
    deleteBrand: (id) => dispatch(deleteAction({id})),
    /* Set existing model attr in redux, push to server if sync=true */
    setModelAttr: (id, field, value, sync=false) => dispatch(updateAction({id, updateModel: {[field]: value}, sync})),
    /* Set attr on new model */
    setNewAttr: (id, field, value, sync=false) => dispatch(insertAction({newModel: {[field]: value}, sync})),
    /* Push new model to the server */
    saveNewModel: (newModel) => dispatch(insertAction({ id: 0, newModel, sync: true })),

    /* Save entire brand model */
    updateBrand: (updateModel) => dispatch(updateAction({id: updateModel.id, updateModel, sync: true})),

    // router
    redirect: (path) => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandPage);