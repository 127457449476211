import { put } from 'redux-saga/effects';
import fetch, { isStatusCodeAnError } from 'lib/fetch';
import { API_SERVER_URL } from 'config/config';
import {
    PREVIEW_ERROR,
    PREVIEW_SUCCESS,
} from 'constants/actions/preview';

export function *generatePreview({
    activityId,
    page,
    promo,
    state,
}) {
console.log('TRYING TO RENDER PREVIEW WITH DATA: ', {
    activityId,
    page,
    promo,
    state,
})
    try {
        const res = yield fetch(
            `${API_SERVER_URL}/preview`,
            {
                method: 'POST',
                body: JSON.stringify({
                    activityId,
                    promo,
                    page,
                    state,
                }),
            }
        );
        if(isStatusCodeAnError(res.status)) {
            yield put({
                type: PREVIEW_ERROR,
                error: res.data.message,
            });
        } else {
           yield put({
               type: PREVIEW_SUCCESS,
               html: res.data,
           });
        }
    } catch(e) {
        yield put({
            type: PREVIEW_ERROR,
            error: e.message || 'Unknown failure previewing html',
        });
    }
}