import {
    createFetchModelAction,
    createFetchModelListAction,
    createInsertModelAction,
    createUpdateModelAction,
    createDeleteModelAction,
    createSetNewModelAttrAction,
} from './crud';

const fetchAction      = createFetchModelAction('PARTNER');
const fetchListAction  = createFetchModelListAction('PARTNER');
const insertAction     = createInsertModelAction('PARTNER');
const updateAction     = createUpdateModelAction('PARTNER');
const deleteAction     = createDeleteModelAction('PARTNER');
const setNewAttrAction = createSetNewModelAttrAction('PARTNER');

export {
    fetchAction,
    fetchListAction,
    insertAction,
    updateAction,
    deleteAction,
    setNewAttrAction,
};