import React, { useEffect } from 'react';
import { connect }  from 'react-redux';
import { Link } from 'react-router-dom';
import CrudListPage from 'components/Crud/CrudListPage';
import { IMG_SERVER_URL } from 'config/config';
import { initBrandListPage } from 'actions/pages/init';

const BrandListPage = ({
    fetchingList,
    list,
    page,
    perPage,
    initBrandListPage,
}) => {
    useEffect(() => {
        initBrandListPage();
    }, []);

    return (
        <CrudListPage
            addNewPath="/brand/new"
            title="Brands"
            fields={{
                name: 'name',
                logo: 'logo',
                url: 'website',
            }}
            formatter={(field, row) => {
                switch(field) {
                case 'name':
                    return <Link to={`/brand/${row.id}`}>{row[field]}</Link>;
                case 'logo':
                    return <Link to={`/brand/${row.id}`}><img alt="logo" className="logo" src={`${IMG_SERVER_URL}${row[field]}`} /></Link>;
                case 'url':
                    return <a href={row[field]} target="_blank" rel="noopener noreferrer">{row[field]}</a>;
                default:
                    return false;
                }
            }}
            rows={list}
        />
    );
};

const mapStateToProps = ({ brand }) => ({
    fetchingList: brand.fetchingList,
    list: brand.list,
    page: brand.page,
    perPage: brand.perPage,
});

const mapDispatchToProps = dispatch => ({
    initBrandListPage: () => dispatch(initBrandListPage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandListPage);