import createReducer from './crud';

const promoActivityReducer = createReducer(
    'PROMO_ACTIVITY',
    (state, action) => ({
        FETCH_PROMO_ACTIVITIES_BY_PROMO_CODE: () => {
            return {
                ...state,
                fetchingList: true,
                list: {},
                listErrors: [],
                page: action.page,
                perPage: action.perPage,
            };
        },
        FETCH_PROMO_ACTIVITIES_BY_PROMO_CODE_SUCCESS: () => {
            return {
                ...state,
                fetchingList: false,
                list: action.list,
            };
        },
        FETCH_PROMO_ACTIVITIES_BY_PROMO_CODE_ERROR: () => {
            return {
                ...state,
                fetchingList: false,
                listErrors: action.errors,
            };
        },
        // Note: Likely to change in the future, once promo activities are better fleshed out.
        //       * Assumption - promos will only have one passthru activity for now
        //       * Instead of using existing activities (as there is no selection interface, etc)
        //         we'll copy all but the promo id of the theoretically only activity from the current promo to the new promo

        // TODO We need to get the activity id from the existing promo as part of the action
        PROMO_CLONE: () => {
            const existingActivity = Object.values(state.list)[0]; // XXX Assumes there is only one activity on current promo...
            delete existingActivity.id;
            delete existingActivity.promoId;
            return {
                ...state,
                newInstance: {
                    ...existingActivity,
                    name: typeof existingActivity.name === 'string' && existingActivity.name.length > 0 ? `${existingActivity.name} - cloned` : '',
                },
                list: {},
            };
        },
        SAVE_CLONED_PROMO: () => {
            return {
                ...state,
                creating: true,
            };
        },
        CREATE_NEW_PROMO: () => {
            return {
                ...state,
                list: {},
            };
        },
        SET_PROMO_ACTIVITY_TYPE: () => {
            if(action.activityId === 0) {
                return {
                    ...state,
                    newInstance: {
                        ...state.newInstance,
                        activityTypeId: action.activityTypeId
                    }
                };
            } else {
                return {
                    ...state,
                    list: {
                        ...state.list,
                        [action.activityId]: {
                            ...state.list[action.activityId],
                            activityTypeId: action.activityTypeId
                        }
                    }
                };
            }
        },
    }),
    { // new model
        activityFreq: 1.0,
        activityTypeId: 0,
        enabled: 0,
        termsRequired: 0,
    },
);

export default promoActivityReducer;