import React from 'react';
import { bool, func, string } from 'prop-types';

const Switch = ({
  color,
  enabled,
  onClick,
}) => {
  const _color=`switch-${color}`;
  return (
    <label className={`switch ${_color}`}>
      <input type="checkbox" className="switch-input" checked={enabled} onChange={(e) => { onClick(e.target.checked); }} />
      <span className="switch-label" />
    </label>
  );
};

Switch.propTypes = {
  color: string,
  enabled: bool,
  onClick: func.isRequired,
};

Switch.defaultProps = {
  color: 'primary',
  enabled: false,
};

export default Switch;