import createReducer from './crud';

const walletReducer = createReducer(
    'WALLET',
    (state, action) => ({}),
    { // new model
        address: '',
        gtAlias: '',
    }
);

export default walletReducer;
