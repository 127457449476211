import { pascalCase } from 'change-case';

// Get a coinType-specific set of identifiers for a given coinType,
// to facilitate polymorphic behavior
export const getBlockchainIdentifiers = (coinType) => {
    if(typeof coinType !== 'string') {
        return {
            allowanceCrypto                         : '',
            allowanceUsd                            : '',
            balanceCrypto                           : '',
            balanceUsd                              : '',
            cryptoToUsdRateError                    : '',
            cryptoToUsdRate                         : '',
            cryptoToUsdTimestamp                    : '',
            fetchingCryptoToUsdRate                 : '',
            fetchingCryptoBalanceAndAllowance       : '',
            fetchingCryptoBalanceAndAllowanceErrors : '',
            fetchingUsdToCryptoRate                 : '',
            usdToCryptoRateError                    : '',
            usdToCryptoRate                         : '',
            usdToCryptoRateTimestamp                : '',
        };
    }

    const lowerCoinType  = coinType.toLowerCase();
    const pascalCoinType = pascalCase(coinType);

    return {
        allowanceCrypto                         : `allowance${pascalCoinType}`,
        allowanceUsd                            : `${lowerCoinType}AllowanceUsd`,
        balanceCrypto                           : `balance${pascalCoinType}`,
        balanceUsd                              : `${lowerCoinType}BalanceUsd`,
        cryptoToUsdRateError                    : `${lowerCoinType}ToUsdRateError`,
        cryptoToUsdRate                         : `${lowerCoinType}ToUsdRate`,
        cryptoToUsdTimestamp                    : `${lowerCoinType}ToUsdTimestamp`,
        fetchingCryptoToUsdRate                 : `fetching${pascalCoinType}ToUsdRate`,
        fetchingCryptoBalanceAndAllowance       : `fetching${pascalCoinType}BalanceAndAllowance`,
        fetchingCryptoBalanceAndAllowanceErrors : `fetching${pascalCoinType}BalanceAndAllowanceErrors`,
        fetchingUsdToCryptoRate                 : `fetchingUsdTo${pascalCoinType}Rate`,
        usdToCryptoRateError                    : `usdTo${pascalCoinType}RateError`,
        usdToCryptoRate                         : `usdTo${pascalCoinType}Rate`,
        usdToCryptoRateTimestamp                : `usdTo${pascalCoinType}RateTimestamp`,
    };
};