import React from 'react';
import { bool, func, number, object, string } from 'prop-types';
import { FormGroup } from 'reactstrap';
import { GtSelect } from 'components/Form';
import { interimPageModules } from '../ActivityType';

const ActivityPane = ({
    activityId,
    activityParams,
    activitySetterMethod,
    activityTypeId,
    activityTypeList,
    enabled,
    frequency,
    globalBackgroundImage,
    isClone,
    isNew,
    name,
    onDelete,
    onSave,
    generatePreview,
    savingActivity,
    setPromoActivityType
}) => {

    let activityType = '';
    let _description  = '';
    Object.values(activityTypeList).some(({id, description, name}) => {
        if(id == activityTypeId) {
            activityType = name;
            _description = description;
            return true;
        }
    });

    return (
    <FormGroup tag="fieldset">
        <GtSelect
            defaultText="Choose Activity Type"
            displayField="name"
            onChange={(value) => { setPromoActivityType(activityId, value); }}
            id="activity-type-select"
            label="Activity Type"
            options={activityTypeList}
            value={activityTypeId}
        />
        {Object.entries(interimPageModules).map(
            ([_activityType, ActivityComponent]) =>
                activityType === _activityType
                ? <ActivityComponent
                        activityId={parseInt(activityId, 10)}
                        activityParams={activityParams}
                        activitySetterMethod={activitySetterMethod}
                        description={_description}
                        enabled={enabled}
                        frequency={frequency}
                        generatePreview={generatePreview}
                        globalBackgroundImage={globalBackgroundImage}
                        isClone={isClone}
                        isNew={isNew}
                        key={activityType}
                        onDelete={onDelete}
                        onSave={onSave}
                        savingActivity={savingActivity}
                    />
                : null
        )}
    </FormGroup>);
};

ActivityPane.propTypes = {
    activityId: number,
    activityParams: object,
    activitySetterMethod: func.isRequired,
    activityType: string,
    enabled: bool,
    frequency: number.isRequired,
    globalBackgroundImage: string,
    isClone: bool,
    isNew: bool,
    name: string,
    onDelete: func.isRequired,
    onSave: func,
    generatePreview: func.isRequired,
};

ActivityPane.defaultProps = {
    activityId: 0,
    enabled: false,
    globalBackgroundImage: '',
    isClone: false,
    isNew: false,
    name: '',
};

export default ActivityPane;