import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import user from './user';
import blockchainReducer from './blockchain';
import brandReducer from './brand';
import partnerReducer from './partner';
import referrerReducer from './referrer';
import walletReducer from './wallet';
import compensationReducer from './compensation';
import createReducer from './crud';
import gtHistory from './history';
import promoActivityReducer from './promo-activity';
import promoStatsReducer from './promo-stats';
import locationReducer from './location';
import referrerPromoReducer from './referrer-promo';
import shortUrlReducer from './short-urls';
import promoReducer from './promo';
import previewReducer from './preview';

const createReducers = (history) => {
    const r = {
        activityType: createReducer('ACTIVITY_TYPE'),
        blockchain: blockchainReducer,
        brand: brandReducer,
        compensation: compensationReducer,
        gtHistory: gtHistory,
        partner: partnerReducer,
        dbLocation: locationReducer, // needs attention
        preview: previewReducer,
        promotion: promoReducer,
        promoActivity: promoActivityReducer, // needs attention
        promoStats: promoStatsReducer,
        referrer: referrerReducer,
        referrerPromo: referrerPromoReducer, // XXX May be obsolete
        router: connectRouter(history),
        shortUrls: shortUrlReducer,
        wallet: walletReducer,
        user,
    };

    if(process.env.NODE_ENV !== 'production') {
        r.uiComps = createReducer('UI_COMPS');
    }

    return r;
};

export default history => combineReducers(createReducers(history));
